import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { HomePage } from './pages/Home';
import { FactsPage } from './pages/Facts';
import { ReportsPage } from './pages/Reports';
import { LearnMorePage } from './pages/LearnMore';
import { AboutPage } from './pages/About';
import { DiscriminationIndexPage } from './pages/DiscriminationIndex';

// import { OrganizationsPage } from './pages/Organizations';
// import { StudentsAgainstRacismPage } from './pages/StudentsAgainstRacism';
// import { GovernmentPolicyPage } from './pages/GovernmentPolicy';
// import { RotterdamHistoryPage } from './pages/RotterdamHistory';
// import { ExperiencePage } from './pages/Experience';
// import { LiteraturePage } from './pages/Literature';

import './bootstrap.scss';
import './App.scss';
import 'semantic-ui-css/semantic.min.css';

export const App = () => {
  return (
    <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/facts" element={<FactsPage />} />
        <Route path="/reports" element={<ReportsPage />} />
        <Route path="/learn-more" element={<LearnMorePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/discrimination-index" element={<DiscriminationIndexPage />} />
    </Routes>
  );
};

// <Route path="/organizations" element={<OrganizationsPage />} />
// <Route path="/studentsAgainstRacism" element={<StudentsAgainstRacismPage />} />
// <Route path="/policy" element={<GovernmentPolicyPage />} />
// <Route path="/history" element={<RotterdamHistoryPage />} />
// <Route path="/experience" element={<ExperiencePage />} />
// <Route path="/literature" element={<LiteraturePage />} />
