
import { Layout } from '../Shared/Layout';
import { Home } from '../Home';
import React from 'react';

export const HomePage = () => {

  return (
    <Layout>
      <Home/>
    </Layout>
  );
};
