
import { Layout } from '../Shared/Layout';
import { Facts } from '../Facts';
import React from 'react';

export const FactsPage = () => {

  return (
    <Layout>
      <Facts/>
    </Layout>
  );
};
