
export const facts_translations = {
        page_title: {
          english: "Facts and numbers",
          dutch: "Feiten en cijfers",
        },
        spider_plots_page_title: {
          english:"Discrimination Index",
          dutch:"Discriminatie-index"
        },
        spider_plots_page_title_2: {
          english:"Neighbourhood profiles",
          dutch:"Buurt profielen"
        },
        spider_plots_page_subtitle_2: {
          english:"Check out your neighbourhood",
          dutch:"Kijk eens in je buurt"
        },
        p1: {
          english:"Explore patterns of discrimination and inequality in Rotterdam. Check out how neighborhoods differ from each other in levels of discrimination, migration diversity, economic wellbeing and education. This project is part of the plan <a href='https://rotterdam.notubiz.nl/document/9344928/2'>‘Rotterdam Against Racism’</a> undertaken by the Municipality of Rotterdam. The analysis and the web-site are developed by Erasmus University Rotterdam in collaboration with anti-discrimination agency RADAR. Read more about this project <a href='/organizations?language=english'>here</a>.",
          dutch:"Ontdek patronen van discriminatie en ongelijkheid in Rotterdam en kijk hoe buurten verschillen op basis van discriminatie, migratie diversiteit, economisch welzijn en opleidingsniveau. Deze inzichten en website zijn onderdeel van het plan <a href='https://rotterdam.notubiz.nl/document/9344928/2'>Rotterdam Tegen Racisme</a> wat is ondernomen door gemeente Rotterdam en is gerealiseerd in samenwerking met de Erasmus Universiteit Rotterdam en het anti-discriminatie agentschap RADAR. Voor meer informatie, lees meer <a href='/organizations'>hier</a>."
        },
        map_title: {
          english: "Map",
          dutch: "Kaart"
        },
        map_footnote:{
          english: "Results based on calculations by Erasmus University Rotterdam using non-public microdata from Statistics Netherlands for all indicators with two exceptions. Indicator ‘Percentage Discrimination Experience’ was calculated on the basis of Wijkprofiel Survey, indicator ‘Number of Discrimination Reports’ was calculated on the basis of the data provided by RADAR.",
          dutch: "Resultaten gebaseerd op berekeningen van de Erasmus Universiteit Rotterdam met niet-openbare microdata van het CBS voor alle indicatoren op twee uitzonderingen na. “Percentage discriminatie ervaringen” is berekend gebaseerd op data van de Wijkprofiel survey, indicator “aantal discriminatiemeldingen” is gebaseerd op geleverde data van RADAR."
        },
        map2_title: {
          english: "Discrimination experiences in Rotterdam",
          dutch: "Discriminatie ervaringen in Rotterdam"
        },
        map2_description: {
          english: "",
          dutch: "De figuur hieronder geeft per buurt het percentage weer van het aantal mensen die zich gediscrimineerd voelen, gebaseerd op data van wijkprofiel Rotterdam van de Jaren 2013 – 2021."
        },
        map2_footnote: {
          english: "Source: Wijkprofiel Rotterdam 2013-2021",
          dutch: "Source: Wijkprofiel Rotterdam 2013-2021"
        },
        treemap_title: {
          english: "Ground of Discrimination",
          dutch: "Grond van Discriminatie"
        },
        treemap_description: {
          english: "",
          dutch: "De figuur hieronder geeft de verdeling van de discriminatiegronden weer voor de jaren 2018 - 2020 van discriminatiemeldingen uit Rotterdam die zijn aangegeven bij RADAR."
        },
        treemap_footnote: {
          english: "Source: RADAR discriminatiemeldingen ontvangen in 2018-2020.",
          dutch: "Source: RADAR discriminatiemeldingen ontvangen in 2018-2020."
        },
        treemap_footnote_note: {
          english: "* Race is a category of discrimination used in RADAR data. Race refers to skin colour or visible ethnic profile.",
          dutch: "* Ras is een categorie van discriminatie gebruikt in RADAR data. Ras bedoeld huids kleur of zichtbaar ethnisch profiel."
        },
        bar_title: {
          english: "Ground of Discrimination by Neighborhood Level",
          dutch: "Grond van Discriminatie naar Buurtniveau"
        },
        bar_description: {
          english: "",
          dutch: "De figuur hieronder geeft de verdeling van de discriminatiegronden weer naar buurt voor de jaren 2018 -2020 van discriminatiemeldingen uit Rotterdam die zijn aangegeven bij RADAR."
        },
        bar_footnote: {
          english: "Source: RADAR discriminatiemeldingen ontvangen in 2018-2020",
          dutch: "Source: RADAR discriminatiemeldingen ontvangen in 2018-2020"
        },

        spiders_footnotes_2: {
          english: "<p>These charts show profiles of each neighbourhood in Rotterdam (violet line) in comparison with a (imaginary) average neighbourhood in the city (green line). You can see 10 different aspects describing inhabitants of each neighbourhoods (from the top listed clockwise): discrimination experiences, discrimination reports submitted to RADAR, share of migrants, share of highly educated, share people with basic education, welfare dependency, diversity of countries of birth, diversity of migration motives, inequality of the educational levels, average household income, income inequality. Each indicator is shown on a standardised scale: from min 0 (centre) to max 100 (outer border).</p>",
          dutch: "<p>Deze grafieken tonen profielen van elke buurt in Rotterdam (paarse lijn) in vergelijking met een (denkbeeldige) gemiddelde wijk in de stad (groene lijn). U kunt 10 verschillende indicatoren zien die kenmerken van de inwoners van elke wijk beschrijven (vanaf de top met de klok mee): ervaren discriminatie, discriminatierapporten ingediend bij RADAR, aandeel migranten, aandeel hoogopgeleiden, aandeel mensen met een basisopleiding, uitkeringsafhankelijkheid, diversiteit van geboortelanden, diversiteit van migratiemotieven, ongelijkheid in opleidingsniveaus, gemiddeld huishoudinkomen, inkomensongelijkheid. Elke indicator wordt weergegeven op een gestandaardiseerde schaal: van minimaal 0 (midden) tot maximaal 100 (buitengrens).</p>"
        },

        spiders_footnotes: {
          english: "The Discrimination index provides insight into differences in discrimination experiences per neighbourhood. The higher the scores, the higher is the predicted discrimination in the neighbourhood. The index ranges from 0 to 200, where 100 stands for mean discrimination in Rotterdam. On the horizontal axis you see a prediction of the proportion of people who feel discriminated against, based on key predictors of discrimination and reported discrimination in the neighborhood in question. Read more about the method <a href='/discrimination-index?language=english'>here</a>.",
          dutch: "De Discriminatie-index geeft inzicht in verschillen in ervaren discriminatie tussen buurten. Hoe hoger de scores, des te hoger is de voorspelde discriminatie in een buurt. De index varieert van 0 tot 200, waarbij 100 het gemiddelde voor Rotterdam aangeeft. Op de horizontale as ziet u een voorspelling van het aandeel mensen dat zich gediscrimineerd voelt, op basis van belangrijke voorspellers van discriminatie en discriminatiemeldingen in de betreffende buurt. Lees meer over de methode <a href='/discrimination-index?language=dutch'>hier</a>."
        },

        toggle_labels: [{english: "Traditional", dutch: "Traditioneel"}, {english: "Combinations", dutch: "Combinaties"}],

        collaborators_title: {
          english: "Collaborating partners",
          dutch: "Samenwerkingspartners"
        }
    }

export const legend_translations = {
  general_categories: [{english: "Context", dutch: "Context"}, {english: "Discrimination", dutch: "Ervaren discriminatie"}, {english: "Economic independency", dutch: "Economische zelfstandigheid"},{english: "Education", dutch: "Opleidingsniveau"}, {english: "Housing", dutch: "Huisvesting"}, {english: "Income", dutch: "Inkomen"},{english: "Migration diversity", dutch: "Migratie Diversiteit"}],
  legend_titles:[{english: "Merged neighborhoods for survey", dutch: "Samengevoegde wijken voor de survey"}, {english: "Dropdown categories", dutch: "Keuze-categorieën"}]

}
