
import { Layout } from '../Shared/Layout';
import { Reports } from '../Reports';
import React from 'react';

export const ReportsPage = () => {

  return (
    <Layout>
      <Reports/>
    </Layout>
  );
};
