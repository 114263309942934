import React from 'react';
import { Layout } from '../Shared/Layout';
import { About } from '../About';

export const AboutPage = () => {

  return (
    <Layout>
      <About/>
    </Layout>
  );
};
