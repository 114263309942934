import React, { useState, useContext } from 'react';
import {spider_plots_dropdown_translations} from './dropdownTranslations'
import { URLStateContext } from '../URLStateContext';

export const SpiderPlotsDropdown = ({ dropdownOptions, selected, onSelectedChange }) => {

  const [open, setOpen] = useState(false);
  const {language} = useContext(URLStateContext);

  const mappedDropdownOptions = dropdownOptions.map((option) => {
    const translated_label = spider_plots_dropdown_translations.find(d=> d.value === option.value)?.label[language]

    return (
      <div
      key={option.value}
      className="item"
      onClick={() => onSelectedChange(option)} // set selected as option
      >{translated_label}
      </div>
    );
  });

  const translated_label_selected = spider_plots_dropdown_translations.find(d=> d.value === selected.value)?.label[language]

  return (
    <div className="ui form dropdownWidth">
      <div className="field">
        <label className="label"></label>
        <div
          onClick={() => setOpen(!open)}
          className={`ui selection dropdown ${open ? 'visible active' : ''}`}
        >
          <i className="dropdown icon"></i>
          <div className="text">{translated_label_selected}</div>
          <div
            onClick={() => setOpen(!open)}
            className={`menu ${open ? 'visible transition' : ''}`}
          >
            {mappedDropdownOptions}
          </div>
        </div>
      </div>
    </div>
  );
};
