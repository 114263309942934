export const further_reading_translations = {

    banner_title: {
      english: "Learn more",
      dutch: "Zie verder",
    },

    page_title: {
      english: "Literature",
      dutch: "Literatuur",
    },

    page_subtitles: [
      {
        english: "Reference",
        dutch: "Referentielijst",
      },
    ],


  };
