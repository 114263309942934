import React, { useContext } from 'react';
import { URLStateContext } from '../URLStateContext';
import {student_translations} from "./translations";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./Profile.scss";


export const Profile = () => {

  const {language} = useContext(URLStateContext);
//   let numRows = student_translations.students[language].length;


  return (
    <Container>
        {student_translations.students[language].map(function(d,i){
            return (
                <Row className="row">
                    <Col className="profile column">
                        <h3>{d.name}</h3>
                        <p>{d.description}</p>
                    </Col>
                </Row>
            )
        })}
    </Container>
  );
};
