const filterColumn = (data, selected) => {
    const key = selected.value;
    return data.map((value) => value[key]);
  };

const getMean = (arr) => {
    let mean =
      arr.reduce((acc, curr) => {
        return acc + curr;
      }, 0) / arr.length;
    return mean;
  };

export const getStandardDeviation = (arr) => {
    let mean = getMean(arr);
    // Assigning (value - mean) ^ 2 to every array item
    arr = arr.map((k) => {
      return (k - mean) ** 2;
    });
    // Calculating the sum of updated array
    let sum = arr.reduce((acc, curr) => acc + curr, 0);
    // Returning the Standered deviation
    return Math.sqrt(sum / arr.length);
};

// get color scheme based on data
export const getColorScheme = (mapData,selected) => {
    const column = filterColumn(mapData,selected);
    const mean = getMean(column);
    const sd = getStandardDeviation(column);
    const threshold1 = mean - 2 * sd;
    const threshold2 = mean - sd;
    const threshold3 = mean;
    const threshold4 = mean + sd;
    const threshold5 = mean + 2 * sd;

    return [
      "case",
      ["==", ["get", selected.value], null],
      "#e6e6e6",
      [
        "interpolate",
        ["linear"],
        ["get", selected.value],
        threshold1,
        "#9ebcda",
        threshold2,
        "#8c96c6",
        threshold3,
        "#8c6bb1",
        threshold4,
        "#88419d",
        threshold5,
        "#6e016b",
      ],
    ];
};

export const getColorTexts = (mapData,selected) =>{

    const column = filterColumn(mapData,selected);
    const mean = getMean(column);
    const sd = getStandardDeviation(column);
    const threshold1 = mean - 2 * sd;
    const threshold2 = mean - sd;
    const threshold3 = mean;
    const threshold4 = mean + sd;
    const threshold5 = mean + 2 * sd;
   //  console.log(threshold2)
   //  console.log(selected)
   //  console.log(threshold5)
    if(selected.label[selected.label.length - 1]==="%"){

        if(threshold5>=1){
            return [Math.round(threshold1 * 100),Math.round(threshold2 * 100),Math.round(threshold3 * 100),Math.round(threshold4 * 100),100,"n/a"]
        }else{
            return [Math.round(threshold1 * 100),Math.round(threshold2 * 100),Math.round(threshold3 * 100),Math.round(threshold4 * 100),Math.round(threshold5 * 100)+"+","n/a"]
        }

    }else{

            if(threshold2>0&&threshold2<1){
                if(threshold5>=1){
                    return [Math.round(threshold1 * 100) / 100,Math.round(threshold2 * 100) / 100,Math.round(threshold3 * 100) / 100,Math.round(threshold4 * 100) / 100,1,"n/a"]
                }else{
                    return [Math.round(threshold1 * 100) / 100,Math.round(threshold2 * 100) / 100,Math.round(threshold3 * 100) / 100,Math.round(threshold4 * 100) / 100,Math.round(threshold5 * 100) / 100+"+","n/a"]
                }

            }else{
                return [Math.round(threshold1),Math.round(threshold2),Math.round(threshold3),Math.round(threshold4),Math.round(threshold5)+"+","n/a"]
            }

    }

}

export const getColorValues =() =>{
    return ["#9ebcda","#8c96c6","#8c6bb1","#88419d","#6e016b","#e6e6e6"];
}
//neighborhood.properties.mbc_code
export const getStrokeColor = (selected) => {
    if (selected.value === "pDiscr" || selected.value === "abs_disrep") {
      return [
        "case",
        ["==", ["get", "mbc_code"], 2],
        "#de2d26",
        ["==", ["get", "mbc_code"], 3],
        "#2c7fb8",
        ["==", ["get", "mbc_code"], 4],
        "#31a354",
        ["==", ["get", "mbc_code"], 5],
        "#c51b8a",
        ["==", ["get", "mbc_code"], 6],
        "#fee391",
        ["==", ["get", "mbc_code"], 7],
        "#fdbb84",
        "black",
      ];
    } else {
      return "black";
    }
};

export const getStrokeWidth = (selected) => {
    if (selected.value === "pDiscr" || selected.value === "abs_disrep") {
      return [
        "case",
        ["==", ["get", "mbc_code"], 2],
        2,
        ["==", ["get", "mbc_code"], 3],
        2,
        ["==", ["get", "mbc_code"], 4],
        2,
        ["==", ["get", "mbc_code"], 5],
        2,
        ["==", ["get", "mbc_code"], 6],
        2,
        ["==", ["get", "mbc_code"], 7],
        2,
        0.5,
      ];
    } else {
      return 0.5;
    }
};


export const catToValueMap = [
  {
     "buurtcode":5991444,
     "neighborhood":"s-Gravenland",
     "value":0.371,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991086,
     "neighborhood":"Afrikaanderwijk",
     "value":0.256,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990515,
     "neighborhood":"Agniesebuurt",
     "value":0.312,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5992459,
     "neighborhood":"Bedrijvenpark Noord-West",
     "value":"NA",
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990531,
     "neighborhood":"Bergpolder",
     "value":0.283,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991290,
     "neighborhood":"Beverwaard",
     "value":0.235,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990532,
     "neighborhood":"Blijdorp",
     "value":0.289,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990558,
     "neighborhood":"Blijdorpsepolder",
     "value":0.477,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991081,
     "neighborhood":"Bloemhof",
     "value":0.242,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990321,
     "neighborhood":"Bospolder",
     "value":0.273,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991572,
     "neighborhood":"Carnisse",
     "value":0.242,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991570,
     "neighborhood":"Charlois Zuidrand",
     "value":0.504,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990112,
     "neighborhood":"Cool",
     "value":0.332,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990113,
     "neighborhood":"Cs Kwartier",
     "value":0.307,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990845,
     "neighborhood":"De Esch",
     "value":0.301,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990320,
     "neighborhood":"Delfshaven",
     "value":0.332,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990119,
     "neighborhood":"Dijkzigt",
     "value":0.489,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991702,
     "neighborhood":"Dorp",
     "value":0.244,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5992196,
     "neighborhood":"Eemhaven",
     "value":"NA",
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5992307,
     "neighborhood":"Europoort",
     "value":"NA",
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991087,
     "neighborhood":"Feijenoord",
     "value":0.248,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991289,
     "neighborhood":"Groot IJsselmonde",
     "value":0.234,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991593,
     "neighborhood":"Heijplaat",
     "value":0.232,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991449,
     "neighborhood":"Het Lage Land",
     "value":0.259,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990662,
     "neighborhood":"Hillegersberg Noord",
     "value":0.438,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990661,
     "neighborhood":"Hillegersberg Zuid",
     "value":0.281,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991082,
     "neighborhood":"Hillesluis",
     "value":0.254,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991692,
     "neighborhood":"Hoogvliet Noord",
     "value":0.232,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991699,
     "neighborhood":"Hoogvliet Zuid",
     "value":0.231,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991085,
     "neighborhood":"Katendrecht",
     "value":0.31,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990451,
     "neighborhood":"Kleinpolder",
     "value":0.251,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991017,
     "neighborhood":"Kop van Zuid",
     "value":0.474,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991079,
     "neighborhood":"Kop van Zuid - Entrepot",
     "value":0.324,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990842,
     "neighborhood":"Kralingen Oost",
     "value":0.567,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990841,
     "neighborhood":"Kralingen West",
     "value":0.338,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990843,
     "neighborhood":"Kralingse Bos",
     "value":0.387,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991446,
     "neighborhood":"Kralingseveer",
     "value":0.251,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990457,
     "neighborhood":"Landzicht",
     "value":0.212,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990534,
     "neighborhood":"Liskwartier",
     "value":0.306,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991284,
     "neighborhood":"Lombardijen",
     "value":0.261,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990325,
     "neighborhood":"Middelland",
     "value":0.337,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990665,
     "neighborhood":"Molenlaankwartier",
     "value":0.4,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991468,
     "neighborhood":"Nesselande",
     "value":0.276,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990836,
     "neighborhood":"Nieuw Crooswijk",
     "value":0.336,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991926,
     "neighborhood":"Nieuw Mathenesse",
     "value":0.355,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990118,
     "neighborhood":"Nieuwe Werk",
     "value":0.532,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990324,
     "neighborhood":"Nieuwe Westen",
     "value":0.323,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990452,
     "neighborhood":"Noord Kethel",
     "value":0.263,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991088,
     "neighborhood":"Noordereiland",
     "value":0.299,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991463,
     "neighborhood":"Ommoord",
     "value":0.241,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991467,
     "neighborhood":"Oosterflank",
     "value":0.243,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991574,
     "neighborhood":"Oud Charlois",
     "value":0.261,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990837,
     "neighborhood":"Oud Crooswijk",
     "value":0.252,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991283,
     "neighborhood":"Oud IJsselmonde",
     "value":0.258,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990327,
     "neighborhood":"Oud Mathenesse",
     "value":0.243,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990535,
     "neighborhood":"Oude Noorden",
     "value":0.293,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990111,
     "neighborhood":"Oude Westen",
     "value":0.328,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990456,
     "neighborhood":"Overschie",
     "value":0.27,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991577,
     "neighborhood":"Pendrecht",
     "value":0.238,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991391,
     "neighborhood":"Pernis",
     "value":0.218,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991448,
     "neighborhood":"Prinsenland",
     "value":0.299,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990516,
     "neighborhood":"Provenierswijk",
     "value":0.336,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991703,
     "neighborhood":"Rijnpoort",
     "value":0.307,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5992704,
     "neighborhood":"Rozenburg",
     "value":0.228,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990814,
     "neighborhood":"Rubroek",
     "value":0.297,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990660,
     "neighborhood":"Schiebroek",
     "value":0.298,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990329,
     "neighborhood":"Schiemond",
     "value":0.322,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990454,
     "neighborhood":"Schieveen",
     "value":0.274,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991853,
     "neighborhood":"Spaanse Polder",
     "value":0.311,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990323,
     "neighborhood":"Spangen",
     "value":0.264,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990110,
     "neighborhood":"Stadsdriehoek",
     "value":0.377,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991701,
     "neighborhood":"Strand en Duin",
     "value":0.272,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990847,
     "neighborhood":"Struisenburg",
     "value":0.401,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991571,
     "neighborhood":"Tarwewijk",
     "value":0.252,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990664,
     "neighborhood":"Terbregge",
     "value":0.332,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990322,
     "neighborhood":"Tussendijken",
     "value":0.257,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991080,
     "neighborhood":"Vreewijk",
     "value":0.227,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5992194,
     "neighborhood":"Waalhaven",
     "value":"NA",
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5992198,
     "neighborhood":"Waalhaven Zuid",
     "value":"NA",
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991575,
     "neighborhood":"Wielewaal",
     "value":0.239,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990328,
     "neighborhood":"Witte Dorp",
     "value":0.207,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5990455,
     "neighborhood":"Zestienhoven",
     "value":0.29,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991466,
     "neighborhood":"Zevenkamp",
     "value":0.249,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991578,
     "neighborhood":"Zuiderpark",
     "value":0.268,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991576,
     "neighborhood":"Zuidplein",
     "value":0.252,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991573,
     "neighborhood":"Zuidwijk",
     "value":0.243,
     "category":"gini_inc_st_norm"
  },
  {
     "buurtcode":5991444,
     "neighborhood":"s-Gravenland",
     "value":42449.18,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991086,
     "neighborhood":"Afrikaanderwijk",
     "value":20780.19,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990515,
     "neighborhood":"Agniesebuurt",
     "value":22984.61,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5992459,
     "neighborhood":"Bedrijvenpark Noord-West",
     "value":"NA",
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990531,
     "neighborhood":"Bergpolder",
     "value":26483.25,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991290,
     "neighborhood":"Beverwaard",
     "value":24802.29,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990532,
     "neighborhood":"Blijdorp",
     "value":32166.28,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990558,
     "neighborhood":"Blijdorpsepolder",
     "value":17248.19,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991081,
     "neighborhood":"Bloemhof",
     "value":20566.65,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990321,
     "neighborhood":"Bospolder",
     "value":21453.85,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991572,
     "neighborhood":"Carnisse",
     "value":22804.33,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991570,
     "neighborhood":"Charlois Zuidrand",
     "value":30984.9,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990112,
     "neighborhood":"Cool",
     "value":30145.77,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990113,
     "neighborhood":"Cs Kwartier",
     "value":33825.27,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990845,
     "neighborhood":"De Esch",
     "value":26127.85,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990320,
     "neighborhood":"Delfshaven",
     "value":21521.04,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990119,
     "neighborhood":"Dijkzigt",
     "value":25105.01,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991702,
     "neighborhood":"Dorp",
     "value":29142.92,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5992196,
     "neighborhood":"Eemhaven",
     "value":"NA",
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5992307,
     "neighborhood":"Europoort",
     "value":"NA",
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991087,
     "neighborhood":"Feijenoord",
     "value":20822.95,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991289,
     "neighborhood":"Groot IJsselmonde",
     "value":24457.81,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991593,
     "neighborhood":"Heijplaat",
     "value":26015.69,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991449,
     "neighborhood":"Het Lage Land",
     "value":25840.08,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990662,
     "neighborhood":"Hillegersberg Noord",
     "value":40893.61,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990661,
     "neighborhood":"Hillegersberg Zuid",
     "value":35086.2,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991082,
     "neighborhood":"Hillesluis",
     "value":21493.31,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991692,
     "neighborhood":"Hoogvliet Noord",
     "value":27738.11,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991699,
     "neighborhood":"Hoogvliet Zuid",
     "value":26982.75,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991085,
     "neighborhood":"Katendrecht",
     "value":28804.12,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990451,
     "neighborhood":"Kleinpolder",
     "value":24920.11,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991017,
     "neighborhood":"Kop van Zuid",
     "value":45140.45,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991079,
     "neighborhood":"Kop van Zuid - Entrepot",
     "value":30481.94,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990842,
     "neighborhood":"Kralingen Oost",
     "value":53998.61,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990841,
     "neighborhood":"Kralingen West",
     "value":25084.09,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990843,
     "neighborhood":"Kralingse Bos",
     "value":31330.9,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991446,
     "neighborhood":"Kralingseveer",
     "value":29311.08,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990457,
     "neighborhood":"Landzicht",
     "value":22236.73,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990534,
     "neighborhood":"Liskwartier",
     "value":27722.55,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991284,
     "neighborhood":"Lombardijen",
     "value":24151.03,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990325,
     "neighborhood":"Middelland",
     "value":26260.58,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990665,
     "neighborhood":"Molenlaankwartier",
     "value":49896.07,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991468,
     "neighborhood":"Nesselande",
     "value":39459.02,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990836,
     "neighborhood":"Nieuw Crooswijk",
     "value":26826.74,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991926,
     "neighborhood":"Nieuw Mathenesse",
     "value":21669.39,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990118,
     "neighborhood":"Nieuwe Werk",
     "value":45221.54,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990324,
     "neighborhood":"Nieuwe Westen",
     "value":23780.04,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990452,
     "neighborhood":"Noord Kethel",
     "value":36470.94,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991088,
     "neighborhood":"Noordereiland",
     "value":28810.34,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991463,
     "neighborhood":"Ommoord",
     "value":26958.58,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991467,
     "neighborhood":"Oosterflank",
     "value":25439.53,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991574,
     "neighborhood":"Oud Charlois",
     "value":23235.59,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990837,
     "neighborhood":"Oud Crooswijk",
     "value":20702.76,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991283,
     "neighborhood":"Oud IJsselmonde",
     "value":33307.68,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990327,
     "neighborhood":"Oud Mathenesse",
     "value":22993.02,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990535,
     "neighborhood":"Oude Noorden",
     "value":23609.32,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990111,
     "neighborhood":"Oude Westen",
     "value":23977.31,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990456,
     "neighborhood":"Overschie",
     "value":30795.46,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991577,
     "neighborhood":"Pendrecht",
     "value":23084.99,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991391,
     "neighborhood":"Pernis",
     "value":30535.64,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991448,
     "neighborhood":"Prinsenland",
     "value":29442.65,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990516,
     "neighborhood":"Provenierswijk",
     "value":26418.64,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991703,
     "neighborhood":"Rijnpoort",
     "value":31570.65,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5992704,
     "neighborhood":"Rozenburg",
     "value":29653.67,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990814,
     "neighborhood":"Rubroek",
     "value":23719.99,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990660,
     "neighborhood":"Schiebroek",
     "value":27984.78,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990329,
     "neighborhood":"Schiemond",
     "value":30968.93,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990454,
     "neighborhood":"Schieveen",
     "value":38079.54,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991853,
     "neighborhood":"Spaanse Polder",
     "value":32586.62,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990323,
     "neighborhood":"Spangen",
     "value":23247.55,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990110,
     "neighborhood":"Stadsdriehoek",
     "value":31453.73,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991701,
     "neighborhood":"Strand en Duin",
     "value":44319.37,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990847,
     "neighborhood":"Struisenburg",
     "value":26650.98,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991571,
     "neighborhood":"Tarwewijk",
     "value":21522.48,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990664,
     "neighborhood":"Terbregge",
     "value":42725.68,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990322,
     "neighborhood":"Tussendijken",
     "value":20621.53,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991080,
     "neighborhood":"Vreewijk",
     "value":22255.6,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5992194,
     "neighborhood":"Waalhaven",
     "value":"NA",
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5992198,
     "neighborhood":"Waalhaven Zuid",
     "value":"NA",
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991575,
     "neighborhood":"Wielewaal",
     "value":20392.94,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990328,
     "neighborhood":"Witte Dorp",
     "value":21792.46,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5990455,
     "neighborhood":"Zestienhoven",
     "value":42204.86,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991466,
     "neighborhood":"Zevenkamp",
     "value":26578.99,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991578,
     "neighborhood":"Zuiderpark",
     "value":19596.67,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991576,
     "neighborhood":"Zuidplein",
     "value":25335.93,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991573,
     "neighborhood":"Zuidwijk",
     "value":22570.92,
     "category":"avg_inc_norm"
  },
  {
     "buurtcode":5991444,
     "neighborhood":"s-Gravenland",
     "value":0.55,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991086,
     "neighborhood":"Afrikaanderwijk",
     "value":0.13,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990515,
     "neighborhood":"Agniesebuurt",
     "value":0.18,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5992459,
     "neighborhood":"Bedrijvenpark Noord-West",
     "value":"NA",
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990531,
     "neighborhood":"Bergpolder",
     "value":0.3,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991290,
     "neighborhood":"Beverwaard",
     "value":0.39,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990532,
     "neighborhood":"Blijdorp",
     "value":0.51,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990558,
     "neighborhood":"Blijdorpsepolder",
     "value":0.16,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991081,
     "neighborhood":"Bloemhof",
     "value":0.18,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990321,
     "neighborhood":"Bospolder",
     "value":0.17,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991572,
     "neighborhood":"Carnisse",
     "value":0.35,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991570,
     "neighborhood":"Charlois Zuidrand",
     "value":0.36,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990112,
     "neighborhood":"Cool",
     "value":0.21,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990113,
     "neighborhood":"Cs Kwartier",
     "value":0.43,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990845,
     "neighborhood":"De Esch",
     "value":0.21,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990320,
     "neighborhood":"Delfshaven",
     "value":0.17,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990119,
     "neighborhood":"Dijkzigt",
     "value":0.14,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991702,
     "neighborhood":"Dorp",
     "value":0.41,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5992196,
     "neighborhood":"Eemhaven",
     "value":"NA",
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5992307,
     "neighborhood":"Europoort",
     "value":"NA",
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991087,
     "neighborhood":"Feijenoord",
     "value":0.11,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991289,
     "neighborhood":"Groot IJsselmonde",
     "value":0.34,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991593,
     "neighborhood":"Heijplaat",
     "value":0.32,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991449,
     "neighborhood":"Het Lage Land",
     "value":0.38,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990662,
     "neighborhood":"Hillegersberg Noord",
     "value":0.47,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990661,
     "neighborhood":"Hillegersberg Zuid",
     "value":0.63,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991082,
     "neighborhood":"Hillesluis",
     "value":0.24,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991692,
     "neighborhood":"Hoogvliet Noord",
     "value":0.52,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991699,
     "neighborhood":"Hoogvliet Zuid",
     "value":0.39,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991085,
     "neighborhood":"Katendrecht",
     "value":0.35,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990451,
     "neighborhood":"Kleinpolder",
     "value":0.32,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991017,
     "neighborhood":"Kop van Zuid",
     "value":0.31,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991079,
     "neighborhood":"Kop van Zuid - Entrepot",
     "value":0.3,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990842,
     "neighborhood":"Kralingen Oost",
     "value":0.45,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990841,
     "neighborhood":"Kralingen West",
     "value":0.26,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990843,
     "neighborhood":"Kralingse Bos",
     "value":0.38,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991446,
     "neighborhood":"Kralingseveer",
     "value":0.69,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990457,
     "neighborhood":"Landzicht",
     "value":0.31,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990534,
     "neighborhood":"Liskwartier",
     "value":0.36,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991284,
     "neighborhood":"Lombardijen",
     "value":0.29,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990325,
     "neighborhood":"Middelland",
     "value":0.29,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990665,
     "neighborhood":"Molenlaankwartier",
     "value":0.69,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991468,
     "neighborhood":"Nesselande",
     "value":0.74,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990836,
     "neighborhood":"Nieuw Crooswijk",
     "value":0.2,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991926,
     "neighborhood":"Nieuw Mathenesse",
     "value":0.07,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990118,
     "neighborhood":"Nieuwe Werk",
     "value":0.44,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990324,
     "neighborhood":"Nieuwe Westen",
     "value":0.24,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990452,
     "neighborhood":"Noord Kethel",
     "value":0.74,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991088,
     "neighborhood":"Noordereiland",
     "value":0.31,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991463,
     "neighborhood":"Ommoord",
     "value":0.38,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991467,
     "neighborhood":"Oosterflank",
     "value":0.33,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991574,
     "neighborhood":"Oud Charlois",
     "value":0.33,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990837,
     "neighborhood":"Oud Crooswijk",
     "value":0.08,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991283,
     "neighborhood":"Oud IJsselmonde",
     "value":0.69,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990327,
     "neighborhood":"Oud Mathenesse",
     "value":0.36,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990535,
     "neighborhood":"Oude Noorden",
     "value":0.2,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990111,
     "neighborhood":"Oude Westen",
     "value":0.18,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990456,
     "neighborhood":"Overschie",
     "value":0.64,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991577,
     "neighborhood":"Pendrecht",
     "value":0.31,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991391,
     "neighborhood":"Pernis",
     "value":0.71,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991448,
     "neighborhood":"Prinsenland",
     "value":0.37,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990516,
     "neighborhood":"Provenierswijk",
     "value":0.26,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991703,
     "neighborhood":"Rijnpoort",
     "value":0.58,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5992704,
     "neighborhood":"Rozenburg",
     "value":0.55,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990814,
     "neighborhood":"Rubroek",
     "value":0.17,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990660,
     "neighborhood":"Schiebroek",
     "value":0.39,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990329,
     "neighborhood":"Schiemond",
     "value":0.42,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990454,
     "neighborhood":"Schieveen",
     "value":0.77,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991853,
     "neighborhood":"Spaanse Polder",
     "value":0.38,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990323,
     "neighborhood":"Spangen",
     "value":0.27,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990110,
     "neighborhood":"Stadsdriehoek",
     "value":0.3,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991701,
     "neighborhood":"Strand en Duin",
     "value":0.91,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990847,
     "neighborhood":"Struisenburg",
     "value":0.28,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991571,
     "neighborhood":"Tarwewijk",
     "value":0.23,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990664,
     "neighborhood":"Terbregge",
     "value":0.72,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990322,
     "neighborhood":"Tussendijken",
     "value":0.12,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991080,
     "neighborhood":"Vreewijk",
     "value":0.14,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5992194,
     "neighborhood":"Waalhaven",
     "value":"NA",
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5992198,
     "neighborhood":"Waalhaven Zuid",
     "value":"NA",
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991575,
     "neighborhood":"Wielewaal",
     "value":0.08,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990328,
     "neighborhood":"Witte Dorp",
     "value":0.26,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5990455,
     "neighborhood":"Zestienhoven",
     "value":0.72,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991466,
     "neighborhood":"Zevenkamp",
     "value":0.42,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991578,
     "neighborhood":"Zuiderpark",
     "value":0.14,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991576,
     "neighborhood":"Zuidplein",
     "value":0.32,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991573,
     "neighborhood":"Zuidwijk",
     "value":0.22,
     "category":"sh_housow1_norm"
  },
  {
     "buurtcode":5991444,
     "neighborhood":"s-Gravenland",
     "value":0.1,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991086,
     "neighborhood":"Afrikaanderwijk",
     "value":0.45,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990515,
     "neighborhood":"Agniesebuurt",
     "value":0.35,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5992459,
     "neighborhood":"Bedrijvenpark Noord-West",
     "value":"NA",
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990531,
     "neighborhood":"Bergpolder",
     "value":0.2,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991290,
     "neighborhood":"Beverwaard",
     "value":0.31,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990532,
     "neighborhood":"Blijdorp",
     "value":0.1,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990558,
     "neighborhood":"Blijdorpsepolder",
     "value":"NA",
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991081,
     "neighborhood":"Bloemhof",
     "value":0.41,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990321,
     "neighborhood":"Bospolder",
     "value":0.41,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991572,
     "neighborhood":"Carnisse",
     "value":0.24,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991570,
     "neighborhood":"Charlois Zuidrand",
     "value":"NA",
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990112,
     "neighborhood":"Cool",
     "value":0.17,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990113,
     "neighborhood":"Cs Kwartier",
     "value":0.09,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990845,
     "neighborhood":"De Esch",
     "value":0.32,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990320,
     "neighborhood":"Delfshaven",
     "value":0.35,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990119,
     "neighborhood":"Dijkzigt",
     "value":0.45,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991702,
     "neighborhood":"Dorp",
     "value":0.21,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5992196,
     "neighborhood":"Eemhaven",
     "value":"NA",
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5992307,
     "neighborhood":"Europoort",
     "value":"NA",
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991087,
     "neighborhood":"Feijenoord",
     "value":0.5,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991289,
     "neighborhood":"Groot IJsselmonde",
     "value":0.3,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991593,
     "neighborhood":"Heijplaat",
     "value":0.32,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991449,
     "neighborhood":"Het Lage Land",
     "value":0.26,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990662,
     "neighborhood":"Hillegersberg Noord",
     "value":0.19,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990661,
     "neighborhood":"Hillegersberg Zuid",
     "value":0.06,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991082,
     "neighborhood":"Hillesluis",
     "value":0.35,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991692,
     "neighborhood":"Hoogvliet Noord",
     "value":0.19,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991699,
     "neighborhood":"Hoogvliet Zuid",
     "value":0.23,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991085,
     "neighborhood":"Katendrecht",
     "value":0.31,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990451,
     "neighborhood":"Kleinpolder",
     "value":0.34,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991017,
     "neighborhood":"Kop van Zuid",
     "value":0.2,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991079,
     "neighborhood":"Kop van Zuid - Entrepot",
     "value":0.28,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990842,
     "neighborhood":"Kralingen Oost",
     "value":0.1,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990841,
     "neighborhood":"Kralingen West",
     "value":0.28,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990843,
     "neighborhood":"Kralingse Bos",
     "value":"NA",
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991446,
     "neighborhood":"Kralingseveer",
     "value":0.1,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990457,
     "neighborhood":"Landzicht",
     "value":0.32,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990534,
     "neighborhood":"Liskwartier",
     "value":0.24,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991284,
     "neighborhood":"Lombardijen",
     "value":0.33,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990325,
     "neighborhood":"Middelland",
     "value":0.24,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990665,
     "neighborhood":"Molenlaankwartier",
     "value":0.08,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991468,
     "neighborhood":"Nesselande",
     "value":0.07,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990836,
     "neighborhood":"Nieuw Crooswijk",
     "value":0.32,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991926,
     "neighborhood":"Nieuw Mathenesse",
     "value":0.18,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990118,
     "neighborhood":"Nieuwe Werk",
     "value":0.15,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990324,
     "neighborhood":"Nieuwe Westen",
     "value":0.31,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990452,
     "neighborhood":"Noord Kethel",
     "value":"NA",
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991088,
     "neighborhood":"Noordereiland",
     "value":0.26,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991463,
     "neighborhood":"Ommoord",
     "value":0.26,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991467,
     "neighborhood":"Oosterflank",
     "value":0.33,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991574,
     "neighborhood":"Oud Charlois",
     "value":0.29,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990837,
     "neighborhood":"Oud Crooswijk",
     "value":0.47,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991283,
     "neighborhood":"Oud IJsselmonde",
     "value":0.07,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990327,
     "neighborhood":"Oud Mathenesse",
     "value":0.27,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990535,
     "neighborhood":"Oude Noorden",
     "value":0.35,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990111,
     "neighborhood":"Oude Westen",
     "value":0.36,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990456,
     "neighborhood":"Overschie",
     "value":0.15,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991577,
     "neighborhood":"Pendrecht",
     "value":0.34,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991391,
     "neighborhood":"Pernis",
     "value":0.1,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991448,
     "neighborhood":"Prinsenland",
     "value":0.27,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990516,
     "neighborhood":"Provenierswijk",
     "value":0.28,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991703,
     "neighborhood":"Rijnpoort",
     "value":"NA",
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5992704,
     "neighborhood":"Rozenburg",
     "value":0.17,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990814,
     "neighborhood":"Rubroek",
     "value":0.34,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990660,
     "neighborhood":"Schiebroek",
     "value":0.31,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990329,
     "neighborhood":"Schiemond",
     "value":0.29,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990454,
     "neighborhood":"Schieveen",
     "value":"NA",
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991853,
     "neighborhood":"Spaanse Polder",
     "value":"NA",
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990323,
     "neighborhood":"Spangen",
     "value":0.34,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990110,
     "neighborhood":"Stadsdriehoek",
     "value":0.16,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991701,
     "neighborhood":"Strand en Duin",
     "value":"NA",
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990847,
     "neighborhood":"Struisenburg",
     "value":0.21,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991571,
     "neighborhood":"Tarwewijk",
     "value":0.33,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990664,
     "neighborhood":"Terbregge",
     "value":0.07,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990322,
     "neighborhood":"Tussendijken",
     "value":0.43,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991080,
     "neighborhood":"Vreewijk",
     "value":0.41,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5992194,
     "neighborhood":"Waalhaven",
     "value":"NA",
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5992198,
     "neighborhood":"Waalhaven Zuid",
     "value":"NA",
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991575,
     "neighborhood":"Wielewaal",
     "value":0.38,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990328,
     "neighborhood":"Witte Dorp",
     "value":0.42,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5990455,
     "neighborhood":"Zestienhoven",
     "value":0.06,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991466,
     "neighborhood":"Zevenkamp",
     "value":0.26,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991578,
     "neighborhood":"Zuiderpark",
     "value":0.2,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991576,
     "neighborhood":"Zuidplein",
     "value":0.17,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991573,
     "neighborhood":"Zuidwijk",
     "value":0.39,
     "category":"sh_housow3_norm"
  },
  {
     "buurtcode":5991444,
     "neighborhood":"s-Gravenland",
     "value":0.74,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991086,
     "neighborhood":"Afrikaanderwijk",
     "value":0.67,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990515,
     "neighborhood":"Agniesebuurt",
     "value":0.7,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5992459,
     "neighborhood":"Bedrijvenpark Noord-West",
     "value":"NA",
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990531,
     "neighborhood":"Bergpolder",
     "value":0.75,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991290,
     "neighborhood":"Beverwaard",
     "value":0.56,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990532,
     "neighborhood":"Blijdorp",
     "value":0.73,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990558,
     "neighborhood":"Blijdorpsepolder",
     "value":0.6,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991081,
     "neighborhood":"Bloemhof",
     "value":0.64,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990321,
     "neighborhood":"Bospolder",
     "value":0.7,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991572,
     "neighborhood":"Carnisse",
     "value":0.66,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991570,
     "neighborhood":"Charlois Zuidrand",
     "value":0.75,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990112,
     "neighborhood":"Cool",
     "value":0.75,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990113,
     "neighborhood":"Cs Kwartier",
     "value":0.72,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990845,
     "neighborhood":"De Esch",
     "value":0.73,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990320,
     "neighborhood":"Delfshaven",
     "value":0.76,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990119,
     "neighborhood":"Dijkzigt",
     "value":0.64,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991702,
     "neighborhood":"Dorp",
     "value":0.59,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5992196,
     "neighborhood":"Eemhaven",
     "value":"NA",
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5992307,
     "neighborhood":"Europoort",
     "value":"NA",
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991087,
     "neighborhood":"Feijenoord",
     "value":0.66,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991289,
     "neighborhood":"Groot IJsselmonde",
     "value":0.61,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991593,
     "neighborhood":"Heijplaat",
     "value":0.55,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991449,
     "neighborhood":"Het Lage Land",
     "value":0.63,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990662,
     "neighborhood":"Hillegersberg Noord",
     "value":0.75,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990661,
     "neighborhood":"Hillegersberg Zuid",
     "value":0.73,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991082,
     "neighborhood":"Hillesluis",
     "value":0.67,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991692,
     "neighborhood":"Hoogvliet Noord",
     "value":0.61,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991699,
     "neighborhood":"Hoogvliet Zuid",
     "value":0.54,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991085,
     "neighborhood":"Katendrecht",
     "value":0.75,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990451,
     "neighborhood":"Kleinpolder",
     "value":0.66,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991017,
     "neighborhood":"Kop van Zuid",
     "value":0.71,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991079,
     "neighborhood":"Kop van Zuid - Entrepot",
     "value":0.74,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990842,
     "neighborhood":"Kralingen Oost",
     "value":0.64,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990841,
     "neighborhood":"Kralingen West",
     "value":0.77,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990843,
     "neighborhood":"Kralingse Bos",
     "value":0.61,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991446,
     "neighborhood":"Kralingseveer",
     "value":0.71,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990457,
     "neighborhood":"Landzicht",
     "value":0.53,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990534,
     "neighborhood":"Liskwartier",
     "value":0.77,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991284,
     "neighborhood":"Lombardijen",
     "value":0.62,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990325,
     "neighborhood":"Middelland",
     "value":0.77,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990665,
     "neighborhood":"Molenlaankwartier",
     "value":0.71,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991468,
     "neighborhood":"Nesselande",
     "value":0.71,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990836,
     "neighborhood":"Nieuw Crooswijk",
     "value":0.76,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991926,
     "neighborhood":"Nieuw Mathenesse",
     "value":0.69,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990118,
     "neighborhood":"Nieuwe Werk",
     "value":0.65,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990324,
     "neighborhood":"Nieuwe Westen",
     "value":0.77,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990452,
     "neighborhood":"Noord Kethel",
     "value":0.63,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991088,
     "neighborhood":"Noordereiland",
     "value":0.76,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991463,
     "neighborhood":"Ommoord",
     "value":0.64,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991467,
     "neighborhood":"Oosterflank",
     "value":0.67,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991574,
     "neighborhood":"Oud Charlois",
     "value":0.67,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990837,
     "neighborhood":"Oud Crooswijk",
     "value":0.68,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991283,
     "neighborhood":"Oud IJsselmonde",
     "value":0.65,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990327,
     "neighborhood":"Oud Mathenesse",
     "value":0.62,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990535,
     "neighborhood":"Oude Noorden",
     "value":0.74,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990111,
     "neighborhood":"Oude Westen",
     "value":0.77,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990456,
     "neighborhood":"Overschie",
     "value":0.66,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991577,
     "neighborhood":"Pendrecht",
     "value":0.63,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991391,
     "neighborhood":"Pernis",
     "value":0.5,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991448,
     "neighborhood":"Prinsenland",
     "value":0.69,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990516,
     "neighborhood":"Provenierswijk",
     "value":0.76,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991703,
     "neighborhood":"Rijnpoort",
     "value":0.68,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5992704,
     "neighborhood":"Rozenburg",
     "value":0.48,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990814,
     "neighborhood":"Rubroek",
     "value":0.76,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990660,
     "neighborhood":"Schiebroek",
     "value":0.71,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990329,
     "neighborhood":"Schiemond",
     "value":0.75,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990454,
     "neighborhood":"Schieveen",
     "value":0.65,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991853,
     "neighborhood":"Spaanse Polder",
     "value":0.7,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990323,
     "neighborhood":"Spangen",
     "value":0.7,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990110,
     "neighborhood":"Stadsdriehoek",
     "value":0.74,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991701,
     "neighborhood":"Strand en Duin",
     "value":0.64,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990847,
     "neighborhood":"Struisenburg",
     "value":0.74,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991571,
     "neighborhood":"Tarwewijk",
     "value":0.63,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990664,
     "neighborhood":"Terbregge",
     "value":0.72,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990322,
     "neighborhood":"Tussendijken",
     "value":0.71,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991080,
     "neighborhood":"Vreewijk",
     "value":0.6,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5992194,
     "neighborhood":"Waalhaven",
     "value":"NA",
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5992198,
     "neighborhood":"Waalhaven Zuid",
     "value":"NA",
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991575,
     "neighborhood":"Wielewaal",
     "value":0.64,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990328,
     "neighborhood":"Witte Dorp",
     "value":0.62,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5990455,
     "neighborhood":"Zestienhoven",
     "value":0.72,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991466,
     "neighborhood":"Zevenkamp",
     "value":0.62,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991578,
     "neighborhood":"Zuiderpark",
     "value":0.68,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991576,
     "neighborhood":"Zuidplein",
     "value":0.64,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991573,
     "neighborhood":"Zuidwijk",
     "value":0.61,
     "category":"simp_educ_norm"
  },
  {
     "buurtcode":5991444,
     "neighborhood":"s-Gravenland",
     "value":0.67,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991086,
     "neighborhood":"Afrikaanderwijk",
     "value":0.55,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990515,
     "neighborhood":"Agniesebuurt",
     "value":0.7,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5992459,
     "neighborhood":"Bedrijvenpark Noord-West",
     "value":"NA",
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990531,
     "neighborhood":"Bergpolder",
     "value":0.75,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991290,
     "neighborhood":"Beverwaard",
     "value":0.63,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990532,
     "neighborhood":"Blijdorp",
     "value":0.71,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990558,
     "neighborhood":"Blijdorpsepolder",
     "value":"NA",
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991081,
     "neighborhood":"Bloemhof",
     "value":0.6,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990321,
     "neighborhood":"Bospolder",
     "value":0.62,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991572,
     "neighborhood":"Carnisse",
     "value":0.66,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991570,
     "neighborhood":"Charlois Zuidrand",
     "value":0.69,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990112,
     "neighborhood":"Cool",
     "value":0.73,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990113,
     "neighborhood":"Cs Kwartier",
     "value":0.69,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990845,
     "neighborhood":"De Esch",
     "value":0.74,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990320,
     "neighborhood":"Delfshaven",
     "value":0.73,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990119,
     "neighborhood":"Dijkzigt",
     "value":0.71,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991702,
     "neighborhood":"Dorp",
     "value":0.68,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5992196,
     "neighborhood":"Eemhaven",
     "value":"NA",
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5992307,
     "neighborhood":"Europoort",
     "value":"NA",
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991087,
     "neighborhood":"Feijenoord",
     "value":0.56,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991289,
     "neighborhood":"Groot IJsselmonde",
     "value":0.65,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991593,
     "neighborhood":"Heijplaat",
     "value":0.54,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991449,
     "neighborhood":"Het Lage Land",
     "value":0.71,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990662,
     "neighborhood":"Hillegersberg Noord",
     "value":0.66,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990661,
     "neighborhood":"Hillegersberg Zuid",
     "value":0.68,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991082,
     "neighborhood":"Hillesluis",
     "value":0.57,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991692,
     "neighborhood":"Hoogvliet Noord",
     "value":0.62,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991699,
     "neighborhood":"Hoogvliet Zuid",
     "value":0.66,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991085,
     "neighborhood":"Katendrecht",
     "value":0.69,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990451,
     "neighborhood":"Kleinpolder",
     "value":0.59,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991017,
     "neighborhood":"Kop van Zuid",
     "value":0.72,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991079,
     "neighborhood":"Kop van Zuid - Entrepot",
     "value":0.58,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990842,
     "neighborhood":"Kralingen Oost",
     "value":0.73,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990841,
     "neighborhood":"Kralingen West",
     "value":0.72,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990843,
     "neighborhood":"Kralingse Bos",
     "value":"NA",
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991446,
     "neighborhood":"Kralingseveer",
     "value":0.71,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990457,
     "neighborhood":"Landzicht",
     "value":"NA",
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990534,
     "neighborhood":"Liskwartier",
     "value":0.69,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991284,
     "neighborhood":"Lombardijen",
     "value":0.64,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990325,
     "neighborhood":"Middelland",
     "value":0.73,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990665,
     "neighborhood":"Molenlaankwartier",
     "value":0.6,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991468,
     "neighborhood":"Nesselande",
     "value":0.66,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990836,
     "neighborhood":"Nieuw Crooswijk",
     "value":0.69,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991926,
     "neighborhood":"Nieuw Mathenesse",
     "value":0.59,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990118,
     "neighborhood":"Nieuwe Werk",
     "value":0.72,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990324,
     "neighborhood":"Nieuwe Westen",
     "value":0.67,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990452,
     "neighborhood":"Noord Kethel",
     "value":"NA",
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991088,
     "neighborhood":"Noordereiland",
     "value":0.72,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991463,
     "neighborhood":"Ommoord",
     "value":0.65,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991467,
     "neighborhood":"Oosterflank",
     "value":0.68,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991574,
     "neighborhood":"Oud Charlois",
     "value":0.63,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990837,
     "neighborhood":"Oud Crooswijk",
     "value":0.59,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991283,
     "neighborhood":"Oud IJsselmonde",
     "value":0.66,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990327,
     "neighborhood":"Oud Mathenesse",
     "value":0.68,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990535,
     "neighborhood":"Oude Noorden",
     "value":0.66,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990111,
     "neighborhood":"Oude Westen",
     "value":0.71,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990456,
     "neighborhood":"Overschie",
     "value":0.67,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991577,
     "neighborhood":"Pendrecht",
     "value":0.62,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991391,
     "neighborhood":"Pernis",
     "value":0.64,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991448,
     "neighborhood":"Prinsenland",
     "value":0.68,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990516,
     "neighborhood":"Provenierswijk",
     "value":0.73,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991703,
     "neighborhood":"Rijnpoort",
     "value":"NA",
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5992704,
     "neighborhood":"Rozenburg",
     "value":0.64,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990814,
     "neighborhood":"Rubroek",
     "value":0.75,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990660,
     "neighborhood":"Schiebroek",
     "value":0.66,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990329,
     "neighborhood":"Schiemond",
     "value":0.57,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990454,
     "neighborhood":"Schieveen",
     "value":"NA",
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991853,
     "neighborhood":"Spaanse Polder",
     "value":"NA",
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990323,
     "neighborhood":"Spangen",
     "value":0.56,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990110,
     "neighborhood":"Stadsdriehoek",
     "value":0.75,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991701,
     "neighborhood":"Strand en Duin",
     "value":0.6,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990847,
     "neighborhood":"Struisenburg",
     "value":0.66,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991571,
     "neighborhood":"Tarwewijk",
     "value":0.63,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990664,
     "neighborhood":"Terbregge",
     "value":0.57,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990322,
     "neighborhood":"Tussendijken",
     "value":0.64,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991080,
     "neighborhood":"Vreewijk",
     "value":0.66,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5992194,
     "neighborhood":"Waalhaven",
     "value":"NA",
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5992198,
     "neighborhood":"Waalhaven Zuid",
     "value":"NA",
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991575,
     "neighborhood":"Wielewaal",
     "value":0.66,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990328,
     "neighborhood":"Witte Dorp",
     "value":0.27,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5990455,
     "neighborhood":"Zestienhoven",
     "value":0.7,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991466,
     "neighborhood":"Zevenkamp",
     "value":0.66,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991578,
     "neighborhood":"Zuiderpark",
     "value":0.48,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991576,
     "neighborhood":"Zuidplein",
     "value":0.67,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991573,
     "neighborhood":"Zuidwijk",
     "value":0.64,
     "category":"simp_motive_norm"
  },
  {
     "buurtcode":5991444,
     "neighborhood":"s-Gravenland",
     "value":0.31,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991086,
     "neighborhood":"Afrikaanderwijk",
     "value":0.69,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990515,
     "neighborhood":"Agniesebuurt",
     "value":0.61,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5992459,
     "neighborhood":"Bedrijvenpark Noord-West",
     "value":"NA",
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990531,
     "neighborhood":"Bergpolder",
     "value":0.44,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991290,
     "neighborhood":"Beverwaard",
     "value":0.56,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990532,
     "neighborhood":"Blijdorp",
     "value":0.34,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990558,
     "neighborhood":"Blijdorpsepolder",
     "value":0.57,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991081,
     "neighborhood":"Bloemhof",
     "value":0.67,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990321,
     "neighborhood":"Bospolder",
     "value":0.68,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991572,
     "neighborhood":"Carnisse",
     "value":0.71,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991570,
     "neighborhood":"Charlois Zuidrand",
     "value":0.23,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990112,
     "neighborhood":"Cool",
     "value":0.61,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990113,
     "neighborhood":"Cs Kwartier",
     "value":0.64,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990845,
     "neighborhood":"De Esch",
     "value":0.51,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990320,
     "neighborhood":"Delfshaven",
     "value":0.59,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990119,
     "neighborhood":"Dijkzigt",
     "value":0.52,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991702,
     "neighborhood":"Dorp",
     "value":0.16,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5992196,
     "neighborhood":"Eemhaven",
     "value":"NA",
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5992307,
     "neighborhood":"Europoort",
     "value":"NA",
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991087,
     "neighborhood":"Feijenoord",
     "value":0.67,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991289,
     "neighborhood":"Groot IJsselmonde",
     "value":0.44,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991593,
     "neighborhood":"Heijplaat",
     "value":0.36,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991449,
     "neighborhood":"Het Lage Land",
     "value":0.43,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990662,
     "neighborhood":"Hillegersberg Noord",
     "value":0.29,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990661,
     "neighborhood":"Hillegersberg Zuid",
     "value":0.25,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991082,
     "neighborhood":"Hillesluis",
     "value":0.69,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991692,
     "neighborhood":"Hoogvliet Noord",
     "value":0.47,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991699,
     "neighborhood":"Hoogvliet Zuid",
     "value":0.33,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991085,
     "neighborhood":"Katendrecht",
     "value":0.5,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990451,
     "neighborhood":"Kleinpolder",
     "value":0.47,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991017,
     "neighborhood":"Kop van Zuid",
     "value":0.57,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991079,
     "neighborhood":"Kop van Zuid - Entrepot",
     "value":0.58,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990842,
     "neighborhood":"Kralingen Oost",
     "value":0.49,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990841,
     "neighborhood":"Kralingen West",
     "value":0.54,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990843,
     "neighborhood":"Kralingse Bos",
     "value":0.06,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991446,
     "neighborhood":"Kralingseveer",
     "value":0.24,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990457,
     "neighborhood":"Landzicht",
     "value":0.06,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990534,
     "neighborhood":"Liskwartier",
     "value":0.45,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991284,
     "neighborhood":"Lombardijen",
     "value":0.55,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990325,
     "neighborhood":"Middelland",
     "value":0.58,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990665,
     "neighborhood":"Molenlaankwartier",
     "value":0.23,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991468,
     "neighborhood":"Nesselande",
     "value":0.26,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990836,
     "neighborhood":"Nieuw Crooswijk",
     "value":0.45,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991926,
     "neighborhood":"Nieuw Mathenesse",
     "value":0.95,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990118,
     "neighborhood":"Nieuwe Werk",
     "value":0.46,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990324,
     "neighborhood":"Nieuwe Westen",
     "value":0.61,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990452,
     "neighborhood":"Noord Kethel",
     "value":0.09,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991088,
     "neighborhood":"Noordereiland",
     "value":0.45,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991463,
     "neighborhood":"Ommoord",
     "value":0.31,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991467,
     "neighborhood":"Oosterflank",
     "value":0.47,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991574,
     "neighborhood":"Oud Charlois",
     "value":0.6,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990837,
     "neighborhood":"Oud Crooswijk",
     "value":0.58,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991283,
     "neighborhood":"Oud IJsselmonde",
     "value":0.38,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990327,
     "neighborhood":"Oud Mathenesse",
     "value":0.72,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990535,
     "neighborhood":"Oude Noorden",
     "value":0.58,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990111,
     "neighborhood":"Oude Westen",
     "value":0.66,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990456,
     "neighborhood":"Overschie",
     "value":0.28,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991577,
     "neighborhood":"Pendrecht",
     "value":0.63,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991391,
     "neighborhood":"Pernis",
     "value":0.2,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991448,
     "neighborhood":"Prinsenland",
     "value":0.37,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990516,
     "neighborhood":"Provenierswijk",
     "value":0.55,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991703,
     "neighborhood":"Rijnpoort",
     "value":0.06,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5992704,
     "neighborhood":"Rozenburg",
     "value":0.22,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990814,
     "neighborhood":"Rubroek",
     "value":0.57,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990660,
     "neighborhood":"Schiebroek",
     "value":0.37,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990329,
     "neighborhood":"Schiemond",
     "value":0.57,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990454,
     "neighborhood":"Schieveen",
     "value":0.13,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991853,
     "neighborhood":"Spaanse Polder",
     "value":0.26,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990323,
     "neighborhood":"Spangen",
     "value":0.65,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990110,
     "neighborhood":"Stadsdriehoek",
     "value":0.56,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991701,
     "neighborhood":"Strand en Duin",
     "value":0.1,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990847,
     "neighborhood":"Struisenburg",
     "value":0.7,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991571,
     "neighborhood":"Tarwewijk",
     "value":0.73,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990664,
     "neighborhood":"Terbregge",
     "value":0.25,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990322,
     "neighborhood":"Tussendijken",
     "value":0.69,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991080,
     "neighborhood":"Vreewijk",
     "value":0.46,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5992194,
     "neighborhood":"Waalhaven",
     "value":"NA",
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5992198,
     "neighborhood":"Waalhaven Zuid",
     "value":"NA",
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991575,
     "neighborhood":"Wielewaal",
     "value":0.34,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990328,
     "neighborhood":"Witte Dorp",
     "value":0.55,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5990455,
     "neighborhood":"Zestienhoven",
     "value":0.2,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991466,
     "neighborhood":"Zevenkamp",
     "value":0.41,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991578,
     "neighborhood":"Zuiderpark",
     "value":0.33,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991576,
     "neighborhood":"Zuidplein",
     "value":0.55,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991573,
     "neighborhood":"Zuidwijk",
     "value":0.56,
     "category":"simp_conbirth_norm"
  },
  {
     "buurtcode":5991444,
     "neighborhood":"s-Gravenland",
     "value":0.11,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991086,
     "neighborhood":"Afrikaanderwijk",
     "value":0.23,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990515,
     "neighborhood":"Agniesebuurt",
     "value":0.21,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5992459,
     "neighborhood":"Bedrijvenpark Noord-West",
     "value":"NA",
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990531,
     "neighborhood":"Bergpolder",
     "value":0.11,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991290,
     "neighborhood":"Beverwaard",
     "value":0.22,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990532,
     "neighborhood":"Blijdorp",
     "value":0.15,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990558,
     "neighborhood":"Blijdorpsepolder",
     "value":0.15,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991081,
     "neighborhood":"Bloemhof",
     "value":0.27,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990321,
     "neighborhood":"Bospolder",
     "value":0.21,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991572,
     "neighborhood":"Carnisse",
     "value":0.23,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991570,
     "neighborhood":"Charlois Zuidrand",
     "value":0.11,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990112,
     "neighborhood":"Cool",
     "value":0.23,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990113,
     "neighborhood":"Cs Kwartier",
     "value":0.21,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990845,
     "neighborhood":"De Esch",
     "value":0.11,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990320,
     "neighborhood":"Delfshaven",
     "value":0.14,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990119,
     "neighborhood":"Dijkzigt",
     "value":0.1,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991702,
     "neighborhood":"Dorp",
     "value":0.09,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5992196,
     "neighborhood":"Eemhaven",
     "value":"NA",
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5992307,
     "neighborhood":"Europoort",
     "value":"NA",
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991087,
     "neighborhood":"Feijenoord",
     "value":0.24,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991289,
     "neighborhood":"Groot IJsselmonde",
     "value":0.16,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991593,
     "neighborhood":"Heijplaat",
     "value":0.09,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991449,
     "neighborhood":"Het Lage Land",
     "value":0.13,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990662,
     "neighborhood":"Hillegersberg Noord",
     "value":0.07,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990661,
     "neighborhood":"Hillegersberg Zuid",
     "value":0.12,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991082,
     "neighborhood":"Hillesluis",
     "value":0.26,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991692,
     "neighborhood":"Hoogvliet Noord",
     "value":0.14,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991699,
     "neighborhood":"Hoogvliet Zuid",
     "value":0.12,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991085,
     "neighborhood":"Katendrecht",
     "value":0.15,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990451,
     "neighborhood":"Kleinpolder",
     "value":0.15,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991017,
     "neighborhood":"Kop van Zuid",
     "value":0.1,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991079,
     "neighborhood":"Kop van Zuid - Entrepot",
     "value":0.16,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990842,
     "neighborhood":"Kralingen Oost",
     "value":0.08,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990841,
     "neighborhood":"Kralingen West",
     "value":0.17,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990843,
     "neighborhood":"Kralingse Bos",
     "value":0.08,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991446,
     "neighborhood":"Kralingseveer",
     "value":0.11,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990457,
     "neighborhood":"Landzicht",
     "value":"NA",
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990534,
     "neighborhood":"Liskwartier",
     "value":0.15,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991284,
     "neighborhood":"Lombardijen",
     "value":0.16,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990325,
     "neighborhood":"Middelland",
     "value":0.12,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990665,
     "neighborhood":"Molenlaankwartier",
     "value":0.06,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991468,
     "neighborhood":"Nesselande",
     "value":0.11,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990836,
     "neighborhood":"Nieuw Crooswijk",
     "value":0.14,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991926,
     "neighborhood":"Nieuw Mathenesse",
     "value":"NA",
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990118,
     "neighborhood":"Nieuwe Werk",
     "value":0.1,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990324,
     "neighborhood":"Nieuwe Westen",
     "value":0.21,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990452,
     "neighborhood":"Noord Kethel",
     "value":0.08,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991088,
     "neighborhood":"Noordereiland",
     "value":0.15,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991463,
     "neighborhood":"Ommoord",
     "value":0.09,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991467,
     "neighborhood":"Oosterflank",
     "value":0.11,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991574,
     "neighborhood":"Oud Charlois",
     "value":0.24,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990837,
     "neighborhood":"Oud Crooswijk",
     "value":0.22,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991283,
     "neighborhood":"Oud IJsselmonde",
     "value":0.12,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990327,
     "neighborhood":"Oud Mathenesse",
     "value":0.21,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990535,
     "neighborhood":"Oude Noorden",
     "value":0.19,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990111,
     "neighborhood":"Oude Westen",
     "value":0.22,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990456,
     "neighborhood":"Overschie",
     "value":0.11,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991577,
     "neighborhood":"Pendrecht",
     "value":0.16,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991391,
     "neighborhood":"Pernis",
     "value":0.05,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991448,
     "neighborhood":"Prinsenland",
     "value":0.12,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990516,
     "neighborhood":"Provenierswijk",
     "value":0.15,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991703,
     "neighborhood":"Rijnpoort",
     "value":0.09,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5992704,
     "neighborhood":"Rozenburg",
     "value":0.09,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990814,
     "neighborhood":"Rubroek",
     "value":0.17,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990660,
     "neighborhood":"Schiebroek",
     "value":0.11,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990329,
     "neighborhood":"Schiemond",
     "value":0.12,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990454,
     "neighborhood":"Schieveen",
     "value":0.08,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991853,
     "neighborhood":"Spaanse Polder",
     "value":"NA",
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990323,
     "neighborhood":"Spangen",
     "value":0.17,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990110,
     "neighborhood":"Stadsdriehoek",
     "value":0.16,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991701,
     "neighborhood":"Strand en Duin",
     "value":0.01,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990847,
     "neighborhood":"Struisenburg",
     "value":0.08,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991571,
     "neighborhood":"Tarwewijk",
     "value":0.25,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990664,
     "neighborhood":"Terbregge",
     "value":0.08,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990322,
     "neighborhood":"Tussendijken",
     "value":0.2,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991080,
     "neighborhood":"Vreewijk",
     "value":0.15,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5992194,
     "neighborhood":"Waalhaven",
     "value":"NA",
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5992198,
     "neighborhood":"Waalhaven Zuid",
     "value":"NA",
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991575,
     "neighborhood":"Wielewaal",
     "value":0.24,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990328,
     "neighborhood":"Witte Dorp",
     "value":0.21,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5990455,
     "neighborhood":"Zestienhoven",
     "value":0.08,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991466,
     "neighborhood":"Zevenkamp",
     "value":0.14,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991578,
     "neighborhood":"Zuiderpark",
     "value":0.12,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991576,
     "neighborhood":"Zuidplein",
     "value":0.11,
     "category":"pDiscr_norm"
  },
  {
     "buurtcode":5991573,
     "neighborhood":"Zuidwijk",
     "value":0.2,
     "category":"pDiscr_norm"
  },

   {"buurtcode":5991444,"neighborhood":"s-Gravenland","value":0.55,"category":"cat_sh_housow1"},
   {"buurtcode":5991086,"neighborhood":"Afrikaanderwijk","value":0.13,"category":"cat_sh_housow1"},
   {"buurtcode":5990515,"neighborhood":"Agniesebuurt","value":0.18,"category":"cat_sh_housow1"},
   {"buurtcode":5992459,"neighborhood":"Bedrijvenpark Noord-West","value":"NA","category":"cat_sh_housow1"},
   {"buurtcode":5990531,"neighborhood":"Bergpolder","value":0.3,"category":"cat_sh_housow1"},
   {"buurtcode":5991290,"neighborhood":"Beverwaard","value":0.39,"category":"cat_sh_housow1"},
   {"buurtcode":5990532,"neighborhood":"Blijdorp","value":0.51,"category":"cat_sh_housow1"},
   {"buurtcode":5990558,"neighborhood":"Blijdorpsepolder","value":0.16,"category":"cat_sh_housow1"},
   {"buurtcode":5991081,"neighborhood":"Bloemhof","value":0.18,"category":"cat_sh_housow1"},
   {"buurtcode":5990321,"neighborhood":"Bospolder","value":0.17,"category":"cat_sh_housow1"},
   {"buurtcode":5991572,"neighborhood":"Carnisse","value":0.35,"category":"cat_sh_housow1"},
   {"buurtcode":5991570,"neighborhood":"Charlois Zuidrand","value":0.36,"category":"cat_sh_housow1"},
   {"buurtcode":5990112,"neighborhood":"Cool","value":0.21,"category":"cat_sh_housow1"},
   {"buurtcode":5990113,"neighborhood":"Cs Kwartier","value":0.43,"category":"cat_sh_housow1"},
   {"buurtcode":5990845,"neighborhood":"De Esch","value":0.21,"category":"cat_sh_housow1"},
   {"buurtcode":5990320,"neighborhood":"Delfshaven","value":0.17,"category":"cat_sh_housow1"},
   {"buurtcode":5990119,"neighborhood":"Dijkzigt","value":0.14,"category":"cat_sh_housow1"},
   {"buurtcode":5991702,"neighborhood":"Dorp","value":0.41,"category":"cat_sh_housow1"},
   {"buurtcode":5992196,"neighborhood":"Eemhaven","value":"NA","category":"cat_sh_housow1"},
   {"buurtcode":5992307,"neighborhood":"Europoort","value":"NA","category":"cat_sh_housow1"},
   {"buurtcode":5991087,"neighborhood":"Feijenoord","value":0.11,"category":"cat_sh_housow1"},
   {"buurtcode":5991289,"neighborhood":"Groot IJsselmonde","value":0.34,"category":"cat_sh_housow1"},
   {"buurtcode":5991593,"neighborhood":"Heijplaat","value":0.32,"category":"cat_sh_housow1"},
   {"buurtcode":5991449,"neighborhood":"Het Lage Land","value":0.38,"category":"cat_sh_housow1"},
   {"buurtcode":5990662,"neighborhood":"Hillegersberg Noord","value":0.47,"category":"cat_sh_housow1"},
   {"buurtcode":5990661,"neighborhood":"Hillegersberg Zuid","value":0.63,"category":"cat_sh_housow1"},
   {"buurtcode":5991082,"neighborhood":"Hillesluis","value":0.24,"category":"cat_sh_housow1"},
   {"buurtcode":5991692,"neighborhood":"Hoogvliet Noord","value":0.52,"category":"cat_sh_housow1"},
   {"buurtcode":5991699,"neighborhood":"Hoogvliet Zuid","value":0.39,"category":"cat_sh_housow1"},
   {"buurtcode":5991085,"neighborhood":"Katendrecht","value":0.35,"category":"cat_sh_housow1"},
   {"buurtcode":5990451,"neighborhood":"Kleinpolder","value":0.32,"category":"cat_sh_housow1"},
   {"buurtcode":5991017,"neighborhood":"Kop van Zuid","value":0.31,"category":"cat_sh_housow1"},
   {"buurtcode":5991079,"neighborhood":"Kop van Zuid - Entrepot","value":0.3,"category":"cat_sh_housow1"},
   {"buurtcode":5990842,"neighborhood":"Kralingen Oost","value":0.45,"category":"cat_sh_housow1"},
   {"buurtcode":5990841,"neighborhood":"Kralingen West","value":0.26,"category":"cat_sh_housow1"},
   {"buurtcode":5990843,"neighborhood":"Kralingse Bos","value":0.38,"category":"cat_sh_housow1"},
   {"buurtcode":5991446,"neighborhood":"Kralingseveer","value":0.69,"category":"cat_sh_housow1"},
   {"buurtcode":5990457,"neighborhood":"Landzicht","value":0.31,"category":"cat_sh_housow1"},
   {"buurtcode":5990534,"neighborhood":"Liskwartier","value":0.36,"category":"cat_sh_housow1"},
   {"buurtcode":5991284,"neighborhood":"Lombardijen","value":0.29,"category":"cat_sh_housow1"},
   {"buurtcode":5990325,"neighborhood":"Middelland","value":0.29,"category":"cat_sh_housow1"},
   {"buurtcode":5990665,"neighborhood":"Molenlaankwartier","value":0.69,"category":"cat_sh_housow1"},
   {"buurtcode":5991468,"neighborhood":"Nesselande","value":0.74,"category":"cat_sh_housow1"},
   {"buurtcode":5990836,"neighborhood":"Nieuw Crooswijk","value":0.2,"category":"cat_sh_housow1"},
   {"buurtcode":5991926,"neighborhood":"Nieuw Mathenesse","value":0.07,"category":"cat_sh_housow1"},
   {"buurtcode":5990118,"neighborhood":"Nieuwe Werk","value":0.44,"category":"cat_sh_housow1"},
   {"buurtcode":5990324,"neighborhood":"Nieuwe Westen","value":0.24,"category":"cat_sh_housow1"},
   {"buurtcode":5990452,"neighborhood":"Noord Kethel","value":0.74,"category":"cat_sh_housow1"},
   {"buurtcode":5991088,"neighborhood":"Noordereiland","value":0.31,"category":"cat_sh_housow1"},
   {"buurtcode":5991463,"neighborhood":"Ommoord","value":0.38,"category":"cat_sh_housow1"},
   {"buurtcode":5991467,"neighborhood":"Oosterflank","value":0.33,"category":"cat_sh_housow1"},
   {"buurtcode":5991574,"neighborhood":"Oud Charlois","value":0.33,"category":"cat_sh_housow1"},
   {"buurtcode":5990837,"neighborhood":"Oud Crooswijk","value":0.08,"category":"cat_sh_housow1"},
   {"buurtcode":5991283,"neighborhood":"Oud IJsselmonde","value":0.69,"category":"cat_sh_housow1"},
   {"buurtcode":5990327,"neighborhood":"Oud Mathenesse","value":0.36,"category":"cat_sh_housow1"},
   {"buurtcode":5990535,"neighborhood":"Oude Noorden","value":0.2,"category":"cat_sh_housow1"},
   {"buurtcode":5990111,"neighborhood":"Oude Westen","value":0.18,"category":"cat_sh_housow1"},
   {"buurtcode":5990456,"neighborhood":"Overschie","value":0.64,"category":"cat_sh_housow1"},
   {"buurtcode":5991577,"neighborhood":"Pendrecht","value":0.31,"category":"cat_sh_housow1"},
   {"buurtcode":5991391,"neighborhood":"Pernis","value":0.71,"category":"cat_sh_housow1"},
   {"buurtcode":5991448,"neighborhood":"Prinsenland","value":0.37,"category":"cat_sh_housow1"},
   {"buurtcode":5990516,"neighborhood":"Provenierswijk","value":0.26,"category":"cat_sh_housow1"},
   {"buurtcode":5991703,"neighborhood":"Rijnpoort","value":0.58,"category":"cat_sh_housow1"},
   {"buurtcode":5992704,"neighborhood":"Rozenburg","value":0.55,"category":"cat_sh_housow1"},
   {"buurtcode":5990814,"neighborhood":"Rubroek","value":0.17,"category":"cat_sh_housow1"},
   {"buurtcode":5990660,"neighborhood":"Schiebroek","value":0.39,"category":"cat_sh_housow1"},
   {"buurtcode":5990329,"neighborhood":"Schiemond","value":0.42,"category":"cat_sh_housow1"},
   {"buurtcode":5990454,"neighborhood":"Schieveen","value":0.77,"category":"cat_sh_housow1"},
   {"buurtcode":5991853,"neighborhood":"Spaanse Polder","value":0.38,"category":"cat_sh_housow1"},
   {"buurtcode":5990323,"neighborhood":"Spangen","value":0.27,"category":"cat_sh_housow1"},
   {"buurtcode":5990110,"neighborhood":"Stadsdriehoek","value":0.3,"category":"cat_sh_housow1"},
   {"buurtcode":5991701,"neighborhood":"Strand en Duin","value":0.91,"category":"cat_sh_housow1"},
   {"buurtcode":5990847,"neighborhood":"Struisenburg","value":0.28,"category":"cat_sh_housow1"},
   {"buurtcode":5991571,"neighborhood":"Tarwewijk","value":0.23,"category":"cat_sh_housow1"},
   {"buurtcode":5990664,"neighborhood":"Terbregge","value":0.72,"category":"cat_sh_housow1"},
   {"buurtcode":5990322,"neighborhood":"Tussendijken","value":0.12,"category":"cat_sh_housow1"},
   {"buurtcode":5991080,"neighborhood":"Vreewijk","value":0.14,"category":"cat_sh_housow1"},
   {"buurtcode":5992194,"neighborhood":"Waalhaven","value":"NA","category":"cat_sh_housow1"},
   {"buurtcode":5992198,"neighborhood":"Waalhaven Zuid","value":"NA","category":"cat_sh_housow1"},
   {"buurtcode":5991575,"neighborhood":"Wielewaal","value":0.08,"category":"cat_sh_housow1"},
   {"buurtcode":5990328,"neighborhood":"Witte Dorp","value":0.26,"category":"cat_sh_housow1"},
   {"buurtcode":5990455,"neighborhood":"Zestienhoven","value":0.72,"category":"cat_sh_housow1"},
   {"buurtcode":5991466,"neighborhood":"Zevenkamp","value":0.42,"category":"cat_sh_housow1"},
   {"buurtcode":5991578,"neighborhood":"Zuiderpark","value":0.14,"category":"cat_sh_housow1"},
   {"buurtcode":5991576,"neighborhood":"Zuidplein","value":0.32,"category":"cat_sh_housow1"},
   {"buurtcode":5991573,"neighborhood":"Zuidwijk","value":0.22,"category":"cat_sh_housow1"},

   {"buurtcode":5991444,"neighborhood":"s-Gravenland","value":0.06,"category":"cat_sh_educ1"},
   {"buurtcode":5991086,"neighborhood":"Afrikaanderwijk","value":0.27,"category":"cat_sh_educ1"},
   {"buurtcode":5990515,"neighborhood":"Agniesebuurt","value":0.16,"category":"cat_sh_educ1"},
   {"buurtcode":5992459,"neighborhood":"Bedrijvenpark Noord-West","value":"NA","category":"cat_sh_educ1"},
   {"buurtcode":5990531,"neighborhood":"Bergpolder","value":0.09,"category":"cat_sh_educ1"},
   {"buurtcode":5991290,"neighborhood":"Beverwaard","value":0.19,"category":"cat_sh_educ1"},
   {"buurtcode":5990532,"neighborhood":"Blijdorp","value":0.06,"category":"cat_sh_educ1"},
   {"buurtcode":5990558,"neighborhood":"Blijdorpsepolder","value":0.29,"category":"cat_sh_educ1"},
   {"buurtcode":5991081,"neighborhood":"Bloemhof","value":0.3,"category":"cat_sh_educ1"},
   {"buurtcode":5990321,"neighborhood":"Bospolder","value":0.3,"category":"cat_sh_educ1"},
   {"buurtcode":5991572,"neighborhood":"Carnisse","value":0.16,"category":"cat_sh_educ1"},
   {"buurtcode":5991570,"neighborhood":"Charlois Zuidrand","value":0.32,"category":"cat_sh_educ1"},
   {"buurtcode":5990112,"neighborhood":"Cool","value":0.08,"category":"cat_sh_educ1"},
   {"buurtcode":5990113,"neighborhood":"Cs Kwartier","value":0.02,"category":"cat_sh_educ1"},
   {"buurtcode":5990845,"neighborhood":"De Esch","value":0.11,"category":"cat_sh_educ1"},
   {"buurtcode":5990320,"neighborhood":"Delfshaven","value":0.17,"category":"cat_sh_educ1"},
   {"buurtcode":5990119,"neighborhood":"Dijkzigt","value":0.02,"category":"cat_sh_educ1"},
   {"buurtcode":5991702,"neighborhood":"Dorp","value":0.11,"category":"cat_sh_educ1"},
   {"buurtcode":5992196,"neighborhood":"Eemhaven","value":"NA","category":"cat_sh_educ1"},
   {"buurtcode":5992307,"neighborhood":"Europoort","value":"NA","category":"cat_sh_educ1"},
   {"buurtcode":5991087,"neighborhood":"Feijenoord","value":0.31,"category":"cat_sh_educ1"},
   {"buurtcode":5991289,"neighborhood":"Groot IJsselmonde","value":0.2,"category":"cat_sh_educ1"},
   {"buurtcode":5991593,"neighborhood":"Heijplaat","value":0.14,"category":"cat_sh_educ1"},
   {"buurtcode":5991449,"neighborhood":"Het Lage Land","value":0.08,"category":"cat_sh_educ1"},
   {"buurtcode":5990662,"neighborhood":"Hillegersberg Noord","value":0.1,"category":"cat_sh_educ1"},
   {"buurtcode":5990661,"neighborhood":"Hillegersberg Zuid","value":0.05,"category":"cat_sh_educ1"},
   {"buurtcode":5991082,"neighborhood":"Hillesluis","value":0.29,"category":"cat_sh_educ1"},
   {"buurtcode":5991692,"neighborhood":"Hoogvliet Noord","value":0.16,"category":"cat_sh_educ1"},
   {"buurtcode":5991699,"neighborhood":"Hoogvliet Zuid","value":0.14,"category":"cat_sh_educ1"},
   {"buurtcode":5991085,"neighborhood":"Katendrecht","value":0.13,"category":"cat_sh_educ1"},
   {"buurtcode":5990451,"neighborhood":"Kleinpolder","value":0.19,"category":"cat_sh_educ1"},
   {"buurtcode":5991017,"neighborhood":"Kop van Zuid","value":0.01,"category":"cat_sh_educ1"},
   {"buurtcode":5991079,"neighborhood":"Kop van Zuid - Entrepot","value":0.15,"category":"cat_sh_educ1"},
   {"buurtcode":5990842,"neighborhood":"Kralingen Oost","value":0.02,"category":"cat_sh_educ1"},
   {"buurtcode":5990841,"neighborhood":"Kralingen West","value":0.17,"category":"cat_sh_educ1"},
   {"buurtcode":5990843,"neighborhood":"Kralingse Bos","value":0.28,"category":"cat_sh_educ1"},
   {"buurtcode":5991446,"neighborhood":"Kralingseveer","value":0.21,"category":"cat_sh_educ1"},
   {"buurtcode":5990457,"neighborhood":"Landzicht","value":0.55,"category":"cat_sh_educ1"},
   {"buurtcode":5990534,"neighborhood":"Liskwartier","value":0.16,"category":"cat_sh_educ1"},
   {"buurtcode":5991284,"neighborhood":"Lombardijen","value":0.2,"category":"cat_sh_educ1"},
   {"buurtcode":5990325,"neighborhood":"Middelland","value":0.14,"category":"cat_sh_educ1"},
   {"buurtcode":5990665,"neighborhood":"Molenlaankwartier","value":0.05,"category":"cat_sh_educ1"},
   {"buurtcode":5991468,"neighborhood":"Nesselande","value":0.05,"category":"cat_sh_educ1"},
   {"buurtcode":5990836,"neighborhood":"Nieuw Crooswijk","value":0.14,"category":"cat_sh_educ1"},
   {"buurtcode":5991926,"neighborhood":"Nieuw Mathenesse","value":"NA","category":"cat_sh_educ1"},
   {"buurtcode":5990118,"neighborhood":"Nieuwe Werk","value":"NA","category":"cat_sh_educ1"},
   {"buurtcode":5990324,"neighborhood":"Nieuwe Westen","value":0.2,"category":"cat_sh_educ1"},
   {"buurtcode":5990452,"neighborhood":"Noord Kethel","value":"NA","category":"cat_sh_educ1"},
   {"buurtcode":5991088,"neighborhood":"Noordereiland","value":0.3,"category":"cat_sh_educ1"},
   {"buurtcode":5991463,"neighborhood":"Ommoord","value":0.13,"category":"cat_sh_educ1"},
   {"buurtcode":5991467,"neighborhood":"Oosterflank","value":0.17,"category":"cat_sh_educ1"},
   {"buurtcode":5991574,"neighborhood":"Oud Charlois","value":0.16,"category":"cat_sh_educ1"},
   {"buurtcode":5990837,"neighborhood":"Oud Crooswijk","value":0.26,"category":"cat_sh_educ1"},
   {"buurtcode":5991283,"neighborhood":"Oud IJsselmonde","value":0.08,"category":"cat_sh_educ1"},
   {"buurtcode":5990327,"neighborhood":"Oud Mathenesse","value":0.18,"category":"cat_sh_educ1"},
   {"buurtcode":5990535,"neighborhood":"Oude Noorden","value":0.19,"category":"cat_sh_educ1"},
   {"buurtcode":5990111,"neighborhood":"Oude Westen","value":0.18,"category":"cat_sh_educ1"},
   {"buurtcode":5990456,"neighborhood":"Overschie","value":0.11,"category":"cat_sh_educ1"},
   {"buurtcode":5991577,"neighborhood":"Pendrecht","value":0.21,"category":"cat_sh_educ1"},
   {"buurtcode":5991391,"neighborhood":"Pernis","value":0.1,"category":"cat_sh_educ1"},
   {"buurtcode":5991448,"neighborhood":"Prinsenland","value":0.12,"category":"cat_sh_educ1"},
   {"buurtcode":5990516,"neighborhood":"Provenierswijk","value":0.11,"category":"cat_sh_educ1"},
   {"buurtcode":5991703,"neighborhood":"Rijnpoort","value":"NA","category":"cat_sh_educ1"},
   {"buurtcode":5992704,"neighborhood":"Rozenburg","value":0.12,"category":"cat_sh_educ1"},
   {"buurtcode":5990814,"neighborhood":"Rubroek","value":0.19,"category":"cat_sh_educ1"},
   {"buurtcode":5990660,"neighborhood":"Schiebroek","value":0.12,"category":"cat_sh_educ1"},
   {"buurtcode":5990329,"neighborhood":"Schiemond","value":0.17,"category":"cat_sh_educ1"},
   {"buurtcode":5990454,"neighborhood":"Schieveen","value":0.09,"category":"cat_sh_educ1"},
   {"buurtcode":5991853,"neighborhood":"Spaanse Polder","value":"NA","category":"cat_sh_educ1"},
   {"buurtcode":5990323,"neighborhood":"Spangen","value":0.28,"category":"cat_sh_educ1"},
   {"buurtcode":5990110,"neighborhood":"Stadsdriehoek","value":0.06,"category":"cat_sh_educ1"},
   {"buurtcode":5991701,"neighborhood":"Strand en Duin","value":0.07,"category":"cat_sh_educ1"},
   {"buurtcode":5990847,"neighborhood":"Struisenburg","value":0.07,"category":"cat_sh_educ1"},
   {"buurtcode":5991571,"neighborhood":"Tarwewijk","value":0.23,"category":"cat_sh_educ1"},
   {"buurtcode":5990664,"neighborhood":"Terbregge","value":0.06,"category":"cat_sh_educ1"},
   {"buurtcode":5990322,"neighborhood":"Tussendijken","value":0.31,"category":"cat_sh_educ1"},
   {"buurtcode":5991080,"neighborhood":"Vreewijk","value":0.24,"category":"cat_sh_educ1"},
   {"buurtcode":5992194,"neighborhood":"Waalhaven","value":"NA","category":"cat_sh_educ1"},
   {"buurtcode":5992198,"neighborhood":"Waalhaven Zuid","value":"NA","category":"cat_sh_educ1"},
   {"buurtcode":5991575,"neighborhood":"Wielewaal","value":0.21,"category":"cat_sh_educ1"},
   {"buurtcode":5990328,"neighborhood":"Witte Dorp","value":0.34,"category":"cat_sh_educ1"},
   {"buurtcode":5990455,"neighborhood":"Zestienhoven","value":0.05,"category":"cat_sh_educ1"},
   {"buurtcode":5991466,"neighborhood":"Zevenkamp","value":0.13,"category":"cat_sh_educ1"},
   {"buurtcode":5991578,"neighborhood":"Zuiderpark","value":0.23,"category":"cat_sh_educ1"},
   {"buurtcode":5991576,"neighborhood":"Zuidplein","value":0.13,"category":"cat_sh_educ1"},
   {"buurtcode":5991573,"neighborhood":"Zuidwijk","value":0.2,"category":"cat_sh_educ1"},

   {"buurtcode":5991444,"neighborhood":"s-Gravenland","value":0.29,"category":"cat_sh_educ6"},
   {"buurtcode":5991086,"neighborhood":"Afrikaanderwijk","value":0.05,"category":"cat_sh_educ6"},
   {"buurtcode":5990515,"neighborhood":"Agniesebuurt","value":0.14,"category":"cat_sh_educ6"},
   {"buurtcode":5992459,"neighborhood":"Bedrijvenpark Noord-West","value":"NA","category":"cat_sh_educ6"},
   {"buurtcode":5990531,"neighborhood":"Bergpolder","value":0.3,"category":"cat_sh_educ6"},
   {"buurtcode":5991290,"neighborhood":"Beverwaard","value":0.03,"category":"cat_sh_educ6"},
   {"buurtcode":5990532,"neighborhood":"Blijdorp","value":0.36,"category":"cat_sh_educ6"},
   {"buurtcode":5990558,"neighborhood":"Blijdorpsepolder","value":"NA","category":"cat_sh_educ6"},
   {"buurtcode":5991081,"neighborhood":"Bloemhof","value":0.03,"category":"cat_sh_educ6"},
   {"buurtcode":5990321,"neighborhood":"Bospolder","value":0.09,"category":"cat_sh_educ6"},
   {"buurtcode":5991572,"neighborhood":"Carnisse","value":0.05,"category":"cat_sh_educ6"},
   {"buurtcode":5991570,"neighborhood":"Charlois Zuidrand","value":0.08,"category":"cat_sh_educ6"},
   {"buurtcode":5990112,"neighborhood":"Cool","value":0.31,"category":"cat_sh_educ6"},
   {"buurtcode":5990113,"neighborhood":"Cs Kwartier","value":0.34,"category":"cat_sh_educ6"},
   {"buurtcode":5990845,"neighborhood":"De Esch","value":0.16,"category":"cat_sh_educ6"},
   {"buurtcode":5990320,"neighborhood":"Delfshaven","value":0.18,"category":"cat_sh_educ6"},
   {"buurtcode":5990119,"neighborhood":"Dijkzigt","value":0.23,"category":"cat_sh_educ6"},
   {"buurtcode":5991702,"neighborhood":"Dorp","value":0.04,"category":"cat_sh_educ6"},
   {"buurtcode":5992196,"neighborhood":"Eemhaven","value":"NA","category":"cat_sh_educ6"},
   {"buurtcode":5992307,"neighborhood":"Europoort","value":"NA","category":"cat_sh_educ6"},
   {"buurtcode":5991087,"neighborhood":"Feijenoord","value":0.07,"category":"cat_sh_educ6"},
   {"buurtcode":5991289,"neighborhood":"Groot IJsselmonde","value":0.05,"category":"cat_sh_educ6"},
   {"buurtcode":5991593,"neighborhood":"Heijplaat","value":0.05,"category":"cat_sh_educ6"},
   {"buurtcode":5991449,"neighborhood":"Het Lage Land","value":0.1,"category":"cat_sh_educ6"},
   {"buurtcode":5990662,"neighborhood":"Hillegersberg Noord","value":0.27,"category":"cat_sh_educ6"},
   {"buurtcode":5990661,"neighborhood":"Hillegersberg Zuid","value":0.28,"category":"cat_sh_educ6"},
   {"buurtcode":5991082,"neighborhood":"Hillesluis","value":0.05,"category":"cat_sh_educ6"},
   {"buurtcode":5991692,"neighborhood":"Hoogvliet Noord","value":0.03,"category":"cat_sh_educ6"},
   {"buurtcode":5991699,"neighborhood":"Hoogvliet Zuid","value":0.03,"category":"cat_sh_educ6"},
   {"buurtcode":5991085,"neighborhood":"Katendrecht","value":0.19,"category":"cat_sh_educ6"},
   {"buurtcode":5990451,"neighborhood":"Kleinpolder","value":0.08,"category":"cat_sh_educ6"},
   {"buurtcode":5991017,"neighborhood":"Kop van Zuid","value":0.37,"category":"cat_sh_educ6"},
   {"buurtcode":5991079,"neighborhood":"Kop van Zuid - Entrepot","value":0.2,"category":"cat_sh_educ6"},
   {"buurtcode":5990842,"neighborhood":"Kralingen Oost","value":0.53,"category":"cat_sh_educ6"},
   {"buurtcode":5990841,"neighborhood":"Kralingen West","value":0.25,"category":"cat_sh_educ6"},
   {"buurtcode":5990843,"neighborhood":"Kralingse Bos","value":"NA","category":"cat_sh_educ6"},
   {"buurtcode":5991446,"neighborhood":"Kralingseveer","value":0.07,"category":"cat_sh_educ6"},
   {"buurtcode":5990457,"neighborhood":"Landzicht","value":"NA","category":"cat_sh_educ6"},
   {"buurtcode":5990534,"neighborhood":"Liskwartier","value":0.25,"category":"cat_sh_educ6"},
   {"buurtcode":5991284,"neighborhood":"Lombardijen","value":0.05,"category":"cat_sh_educ6"},
   {"buurtcode":5990325,"neighborhood":"Middelland","value":0.24,"category":"cat_sh_educ6"},
   {"buurtcode":5990665,"neighborhood":"Molenlaankwartier","value":0.35,"category":"cat_sh_educ6"},
   {"buurtcode":5991468,"neighborhood":"Nesselande","value":0.2,"category":"cat_sh_educ6"},
   {"buurtcode":5990836,"neighborhood":"Nieuw Crooswijk","value":0.22,"category":"cat_sh_educ6"},
   {"buurtcode":5991926,"neighborhood":"Nieuw Mathenesse","value":0.39,"category":"cat_sh_educ6"},
   {"buurtcode":5990118,"neighborhood":"Nieuwe Werk","value":0.48,"category":"cat_sh_educ6"},
   {"buurtcode":5990324,"neighborhood":"Nieuwe Westen","value":0.19,"category":"cat_sh_educ6"},
   {"buurtcode":5990452,"neighborhood":"Noord Kethel","value":0.19,"category":"cat_sh_educ6"},
   {"buurtcode":5991088,"neighborhood":"Noordereiland","value":0.18,"category":"cat_sh_educ6"},
   {"buurtcode":5991463,"neighborhood":"Ommoord","value":0.07,"category":"cat_sh_educ6"},
   {"buurtcode":5991467,"neighborhood":"Oosterflank","value":0.08,"category":"cat_sh_educ6"},
   {"buurtcode":5991574,"neighborhood":"Oud Charlois","value":0.07,"category":"cat_sh_educ6"},
   {"buurtcode":5990837,"neighborhood":"Oud Crooswijk","value":0.07,"category":"cat_sh_educ6"},
   {"buurtcode":5991283,"neighborhood":"Oud IJsselmonde","value":0.09,"category":"cat_sh_educ6"},
   {"buurtcode":5990327,"neighborhood":"Oud Mathenesse","value":0.08,"category":"cat_sh_educ6"},
   {"buurtcode":5990535,"neighborhood":"Oude Noorden","value":0.17,"category":"cat_sh_educ6"},
   {"buurtcode":5990111,"neighborhood":"Oude Westen","value":0.26,"category":"cat_sh_educ6"},
   {"buurtcode":5990456,"neighborhood":"Overschie","value":0.11,"category":"cat_sh_educ6"},
   {"buurtcode":5991577,"neighborhood":"Pendrecht","value":0.04,"category":"cat_sh_educ6"},
   {"buurtcode":5991391,"neighborhood":"Pernis","value":0.03,"category":"cat_sh_educ6"},
   {"buurtcode":5991448,"neighborhood":"Prinsenland","value":0.13,"category":"cat_sh_educ6"},
   {"buurtcode":5990516,"neighborhood":"Provenierswijk","value":0.25,"category":"cat_sh_educ6"},
   {"buurtcode":5991703,"neighborhood":"Rijnpoort","value":"NA","category":"cat_sh_educ6"},
   {"buurtcode":5992704,"neighborhood":"Rozenburg","value":0.02,"category":"cat_sh_educ6"},
   {"buurtcode":5990814,"neighborhood":"Rubroek","value":0.2,"category":"cat_sh_educ6"},
   {"buurtcode":5990660,"neighborhood":"Schiebroek","value":0.16,"category":"cat_sh_educ6"},
   {"buurtcode":5990329,"neighborhood":"Schiemond","value":0.2,"category":"cat_sh_educ6"},
   {"buurtcode":5990454,"neighborhood":"Schieveen","value":0.12,"category":"cat_sh_educ6"},
   {"buurtcode":5991853,"neighborhood":"Spaanse Polder","value":"NA","category":"cat_sh_educ6"},
   {"buurtcode":5990323,"neighborhood":"Spangen","value":0.09,"category":"cat_sh_educ6"},
   {"buurtcode":5990110,"neighborhood":"Stadsdriehoek","value":0.27,"category":"cat_sh_educ6"},
   {"buurtcode":5991701,"neighborhood":"Strand en Duin","value":0.09,"category":"cat_sh_educ6"},
   {"buurtcode":5990847,"neighborhood":"Struisenburg","value":0.34,"category":"cat_sh_educ6"},
   {"buurtcode":5991571,"neighborhood":"Tarwewijk","value":0.06,"category":"cat_sh_educ6"},
   {"buurtcode":5990664,"neighborhood":"Terbregge","value":0.22,"category":"cat_sh_educ6"},
   {"buurtcode":5990322,"neighborhood":"Tussendijken","value":0.1,"category":"cat_sh_educ6"},
   {"buurtcode":5991080,"neighborhood":"Vreewijk","value":0.04,"category":"cat_sh_educ6"},
   {"buurtcode":5992194,"neighborhood":"Waalhaven","value":"NA","category":"cat_sh_educ6"},
   {"buurtcode":5992198,"neighborhood":"Waalhaven Zuid","value":"NA","category":"cat_sh_educ6"},
   {"buurtcode":5991575,"neighborhood":"Wielewaal","value":0.04,"category":"cat_sh_educ6"},
   {"buurtcode":5990328,"neighborhood":"Witte Dorp","value":"NA","category":"cat_sh_educ6"},
   {"buurtcode":5990455,"neighborhood":"Zestienhoven","value":0.37,"category":"cat_sh_educ6"},
   {"buurtcode":5991466,"neighborhood":"Zevenkamp","value":0.07,"category":"cat_sh_educ6"},
   {"buurtcode":5991578,"neighborhood":"Zuiderpark","value":0.21,"category":"cat_sh_educ6"},
   {"buurtcode":5991576,"neighborhood":"Zuidplein","value":0.05,"category":"cat_sh_educ6"},
   {"buurtcode":5991573,"neighborhood":"Zuidwijk","value":0.07,"category":"cat_sh_educ6"},

   {"buurtcode":5991444,"neighborhood":"s-Gravenland","value":17,"category":"cat_abs_disrep"},
   {"buurtcode":5991086,"neighborhood":"Afrikaanderwijk","value":20,"category":"cat_abs_disrep"},
   {"buurtcode":5990515,"neighborhood":"Agniesebuurt","value":15,"category":"cat_abs_disrep"},
   {"buurtcode":5992459,"neighborhood":"Bedrijvenpark Noord-West","value":"NA","category":"cat_abs_disrep"},
   {"buurtcode":5990531,"neighborhood":"Bergpolder","value":15,"category":"cat_abs_disrep"},
   {"buurtcode":5991290,"neighborhood":"Beverwaard","value":14,"category":"cat_abs_disrep"},
   {"buurtcode":5990532,"neighborhood":"Blijdorp","value":15,"category":"cat_abs_disrep"},
   {"buurtcode":5990558,"neighborhood":"Blijdorpsepolder","value":15,"category":"cat_abs_disrep"},
   {"buurtcode":5991081,"neighborhood":"Bloemhof","value":20,"category":"cat_abs_disrep"},
   {"buurtcode":5990321,"neighborhood":"Bospolder","value":16,"category":"cat_abs_disrep"},
   {"buurtcode":5991572,"neighborhood":"Carnisse","value":12,"category":"cat_abs_disrep"},
   {"buurtcode":5991570,"neighborhood":"Charlois Zuidrand","value":12,"category":"cat_abs_disrep"},
   {"buurtcode":5990112,"neighborhood":"Cool","value":15,"category":"cat_abs_disrep"},
   {"buurtcode":5990113,"neighborhood":"Cs Kwartier","value":15,"category":"cat_abs_disrep"},
   {"buurtcode":5990845,"neighborhood":"De Esch","value":7,"category":"cat_abs_disrep"},
   {"buurtcode":5990320,"neighborhood":"Delfshaven","value":16,"category":"cat_abs_disrep"},
   {"buurtcode":5990119,"neighborhood":"Dijkzigt","value":16,"category":"cat_abs_disrep"},
   {"buurtcode":5991702,"neighborhood":"Dorp","value":3,"category":"cat_abs_disrep"},
   {"buurtcode":5992196,"neighborhood":"Eemhaven","value":"NA","category":"cat_abs_disrep"},
   {"buurtcode":5992307,"neighborhood":"Europoort","value":"NA","category":"cat_abs_disrep"},
   {"buurtcode":5991087,"neighborhood":"Feijenoord","value":20,"category":"cat_abs_disrep"},
   {"buurtcode":5991289,"neighborhood":"Groot IJsselmonde","value":14,"category":"cat_abs_disrep"},
   {"buurtcode":5991593,"neighborhood":"Heijplaat","value":12,"category":"cat_abs_disrep"},
   {"buurtcode":5991449,"neighborhood":"Het Lage Land","value":17,"category":"cat_abs_disrep"},
   {"buurtcode":5990662,"neighborhood":"Hillegersberg Noord","value":5,"category":"cat_abs_disrep"},
   {"buurtcode":5990661,"neighborhood":"Hillegersberg Zuid","value":5,"category":"cat_abs_disrep"},
   {"buurtcode":5991082,"neighborhood":"Hillesluis","value":20,"category":"cat_abs_disrep"},
   {"buurtcode":5991692,"neighborhood":"Hoogvliet Noord","value":5,"category":"cat_abs_disrep"},
   {"buurtcode":5991699,"neighborhood":"Hoogvliet Zuid","value":5,"category":"cat_abs_disrep"},
   {"buurtcode":5991085,"neighborhood":"Katendrecht","value":20,"category":"cat_abs_disrep"},
   {"buurtcode":5990451,"neighborhood":"Kleinpolder","value":3,"category":"cat_abs_disrep"},
   {"buurtcode":5991017,"neighborhood":"Kop van Zuid","value":20,"category":"cat_abs_disrep"},
   {"buurtcode":5991079,"neighborhood":"Kop van Zuid - Entrepot","value":20,"category":"cat_abs_disrep"},
   {"buurtcode":5990842,"neighborhood":"Kralingen Oost","value":7,"category":"cat_abs_disrep"},
   {"buurtcode":5990841,"neighborhood":"Kralingen West","value":7,"category":"cat_abs_disrep"},
   {"buurtcode":5990843,"neighborhood":"Kralingse Bos","value":7,"category":"cat_abs_disrep"},
   {"buurtcode":5991446,"neighborhood":"Kralingseveer","value":7,"category":"cat_abs_disrep"},
   {"buurtcode":5990457,"neighborhood":"Landzicht","value":3,"category":"cat_abs_disrep"},
   {"buurtcode":5990534,"neighborhood":"Liskwartier","value":15,"category":"cat_abs_disrep"},
   {"buurtcode":5991284,"neighborhood":"Lombardijen","value":14,"category":"cat_abs_disrep"},
   {"buurtcode":5990325,"neighborhood":"Middelland","value":16,"category":"cat_abs_disrep"},
   {"buurtcode":5990665,"neighborhood":"Molenlaankwartier","value":5,"category":"cat_abs_disrep"},
   {"buurtcode":5991468,"neighborhood":"Nesselande","value":17,"category":"cat_abs_disrep"},
   {"buurtcode":5990836,"neighborhood":"Nieuw Crooswijk","value":7,"category":"cat_abs_disrep"},
   {"buurtcode":5991926,"neighborhood":"Nieuw Mathenesse","value":16,"category":"cat_abs_disrep"},
   {"buurtcode":5990118,"neighborhood":"Nieuwe Werk","value":16,"category":"cat_abs_disrep"},
   {"buurtcode":5990324,"neighborhood":"Nieuwe Westen","value":16,"category":"cat_abs_disrep"},
   {"buurtcode":5990452,"neighborhood":"Noord Kethel","value":3,"category":"cat_abs_disrep"},
   {"buurtcode":5991088,"neighborhood":"Noordereiland","value":20,"category":"cat_abs_disrep"},
   {"buurtcode":5991463,"neighborhood":"Ommoord","value":17,"category":"cat_abs_disrep"},
   {"buurtcode":5991467,"neighborhood":"Oosterflank","value":17,"category":"cat_abs_disrep"},
   {"buurtcode":5991574,"neighborhood":"Oud Charlois","value":12,"category":"cat_abs_disrep"},
   {"buurtcode":5990837,"neighborhood":"Oud Crooswijk","value":7,"category":"cat_abs_disrep"},
   {"buurtcode":5991283,"neighborhood":"Oud IJsselmonde","value":14,"category":"cat_abs_disrep"},
   {"buurtcode":5990327,"neighborhood":"Oud Mathenesse","value":16,"category":"cat_abs_disrep"},
   {"buurtcode":5990535,"neighborhood":"Oude Noorden","value":15,"category":"cat_abs_disrep"},
   {"buurtcode":5990111,"neighborhood":"Oude Westen","value":15,"category":"cat_abs_disrep"},
   {"buurtcode":5990456,"neighborhood":"Overschie","value":3,"category":"cat_abs_disrep"},
   {"buurtcode":5991577,"neighborhood":"Pendrecht","value":12,"category":"cat_abs_disrep"},
   {"buurtcode":5991391,"neighborhood":"Pernis","value":"NA","category":"cat_abs_disrep"},
   {"buurtcode":5991448,"neighborhood":"Prinsenland","value":17,"category":"cat_abs_disrep"},
   {"buurtcode":5990516,"neighborhood":"Provenierswijk","value":15,"category":"cat_abs_disrep"},
   {"buurtcode":5991703,"neighborhood":"Rijnpoort","value":3,"category":"cat_abs_disrep"},
   {"buurtcode":5992704,"neighborhood":"Rozenburg","value":4,"category":"cat_abs_disrep"},
   {"buurtcode":5990814,"neighborhood":"Rubroek","value":7,"category":"cat_abs_disrep"},
   {"buurtcode":5990660,"neighborhood":"Schiebroek","value":5,"category":"cat_abs_disrep"},
   {"buurtcode":5990329,"neighborhood":"Schiemond","value":16,"category":"cat_abs_disrep"},
   {"buurtcode":5990454,"neighborhood":"Schieveen","value":3,"category":"cat_abs_disrep"},
   {"buurtcode":5991853,"neighborhood":"Spaanse Polder","value":3,"category":"cat_abs_disrep"},
   {"buurtcode":5990323,"neighborhood":"Spangen","value":16,"category":"cat_abs_disrep"},
   {"buurtcode":5990110,"neighborhood":"Stadsdriehoek","value":15,"category":"cat_abs_disrep"},
   {"buurtcode":5991701,"neighborhood":"Strand en Duin","value":3,"category":"cat_abs_disrep"},
   {"buurtcode":5990847,"neighborhood":"Struisenburg","value":7,"category":"cat_abs_disrep"},
   {"buurtcode":5991571,"neighborhood":"Tarwewijk","value":"NA","category":"cat_abs_disrep"},
   {"buurtcode":5990664,"neighborhood":"Terbregge","value":5,"category":"cat_abs_disrep"},
   {"buurtcode":5990322,"neighborhood":"Tussendijken","value":16,"category":"cat_abs_disrep"},
   {"buurtcode":5991080,"neighborhood":"Vreewijk","value":20,"category":"cat_abs_disrep"},
   {"buurtcode":5992194,"neighborhood":"Waalhaven","value":"NA","category":"cat_abs_disrep"},
   {"buurtcode":5992198,"neighborhood":"Waalhaven Zuid","value":"NA","category":"cat_abs_disrep"},
   {"buurtcode":5991575,"neighborhood":"Wielewaal","value":12,"category":"cat_abs_disrep"},
   {"buurtcode":5990328,"neighborhood":"Witte Dorp","value":16,"category":"cat_abs_disrep"},
   {"buurtcode":5990455,"neighborhood":"Zestienhoven","value":3,"category":"cat_abs_disrep"},
   {"buurtcode":5991466,"neighborhood":"Zevenkamp","value":17,"category":"cat_abs_disrep"},
   {"buurtcode":5991578,"neighborhood":"Zuiderpark","value":12,"category":"cat_abs_disrep"},
   {"buurtcode":5991576,"neighborhood":"Zuidplein","value":12,"category":"cat_abs_disrep"},
   {"buurtcode":5991573,"neighborhood":"Zuidwijk","value":12,"category":"cat_abs_disrep"},

   {"buurtcode":5991444,"neighborhood":"s-Gravenland","value":5,"category":"cat_avg_welfare"},
   {"buurtcode":5991086,"neighborhood":"Afrikaanderwijk","value":22,"category":"cat_avg_welfare"},
   {"buurtcode":5990515,"neighborhood":"Agniesebuurt","value":17,"category":"cat_avg_welfare"},
   {"buurtcode":5992459,"neighborhood":"Bedrijvenpark Noord-West","value":"NA","category":"cat_avg_welfare"},
   {"buurtcode":5990531,"neighborhood":"Bergpolder","value":10,"category":"cat_avg_welfare"},
   {"buurtcode":5991290,"neighborhood":"Beverwaard","value":16,"category":"cat_avg_welfare"},
   {"buurtcode":5990532,"neighborhood":"Blijdorp","value":5,"category":"cat_avg_welfare"},
   {"buurtcode":5990558,"neighborhood":"Blijdorpsepolder","value":35,"category":"cat_avg_welfare"},
   {"buurtcode":5991081,"neighborhood":"Bloemhof","value":21,"category":"cat_avg_welfare"},
   {"buurtcode":5990321,"neighborhood":"Bospolder","value":22,"category":"cat_avg_welfare"},
   {"buurtcode":5991572,"neighborhood":"Carnisse","value":14,"category":"cat_avg_welfare"},
   {"buurtcode":5991570,"neighborhood":"Charlois Zuidrand","value":7,"category":"cat_avg_welfare"},
   {"buurtcode":5990112,"neighborhood":"Cool","value":7,"category":"cat_avg_welfare"},
   {"buurtcode":5990113,"neighborhood":"Cs Kwartier","value":4,"category":"cat_avg_welfare"},
   {"buurtcode":5990845,"neighborhood":"De Esch","value":13,"category":"cat_avg_welfare"},
   {"buurtcode":5990320,"neighborhood":"Delfshaven","value":15,"category":"cat_avg_welfare"},
   {"buurtcode":5990119,"neighborhood":"Dijkzigt","value":4,"category":"cat_avg_welfare"},
   {"buurtcode":5991702,"neighborhood":"Dorp","value":9,"category":"cat_avg_welfare"},
   {"buurtcode":5992196,"neighborhood":"Eemhaven","value":"NA","category":"cat_avg_welfare"},
   {"buurtcode":5992307,"neighborhood":"Europoort","value":"NA","category":"cat_avg_welfare"},
   {"buurtcode":5991087,"neighborhood":"Feijenoord","value":26,"category":"cat_avg_welfare"},
   {"buurtcode":5991289,"neighborhood":"Groot IJsselmonde","value":14,"category":"cat_avg_welfare"},
   {"buurtcode":5991593,"neighborhood":"Heijplaat","value":14,"category":"cat_avg_welfare"},
   {"buurtcode":5991449,"neighborhood":"Het Lage Land","value":11,"category":"cat_avg_welfare"},
   {"buurtcode":5990662,"neighborhood":"Hillegersberg Noord","value":10,"category":"cat_avg_welfare"},
   {"buurtcode":5990661,"neighborhood":"Hillegersberg Zuid","value":5,"category":"cat_avg_welfare"},
   {"buurtcode":5991082,"neighborhood":"Hillesluis","value":19,"category":"cat_avg_welfare"},
   {"buurtcode":5991692,"neighborhood":"Hoogvliet Noord","value":12,"category":"cat_avg_welfare"},
   {"buurtcode":5991699,"neighborhood":"Hoogvliet Zuid","value":11,"category":"cat_avg_welfare"},
   {"buurtcode":5991085,"neighborhood":"Katendrecht","value":14,"category":"cat_avg_welfare"},
   {"buurtcode":5990451,"neighborhood":"Kleinpolder","value":16,"category":"cat_avg_welfare"},
   {"buurtcode":5991017,"neighborhood":"Kop van Zuid","value":3,"category":"cat_avg_welfare"},
   {"buurtcode":5991079,"neighborhood":"Kop van Zuid - Entrepot","value":14,"category":"cat_avg_welfare"},
   {"buurtcode":5990842,"neighborhood":"Kralingen Oost","value":3,"category":"cat_avg_welfare"},
   {"buurtcode":5990841,"neighborhood":"Kralingen West","value":13,"category":"cat_avg_welfare"},
   {"buurtcode":5990843,"neighborhood":"Kralingse Bos","value":8,"category":"cat_avg_welfare"},
   {"buurtcode":5991446,"neighborhood":"Kralingseveer","value":9,"category":"cat_avg_welfare"},
   {"buurtcode":5990457,"neighborhood":"Landzicht","value":22,"category":"cat_avg_welfare"},
   {"buurtcode":5990534,"neighborhood":"Liskwartier","value":12,"category":"cat_avg_welfare"},
   {"buurtcode":5991284,"neighborhood":"Lombardijen","value":16,"category":"cat_avg_welfare"},
   {"buurtcode":5990325,"neighborhood":"Middelland","value":11,"category":"cat_avg_welfare"},
   {"buurtcode":5990665,"neighborhood":"Molenlaankwartier","value":4,"category":"cat_avg_welfare"},
   {"buurtcode":5991468,"neighborhood":"Nesselande","value":5,"category":"cat_avg_welfare"},
   {"buurtcode":5990836,"neighborhood":"Nieuw Crooswijk","value":15,"category":"cat_avg_welfare"},
   {"buurtcode":5991926,"neighborhood":"Nieuw Mathenesse","value":4,"category":"cat_avg_welfare"},
   {"buurtcode":5990118,"neighborhood":"Nieuwe Werk","value":2,"category":"cat_avg_welfare"},
   {"buurtcode":5990324,"neighborhood":"Nieuwe Westen","value":16,"category":"cat_avg_welfare"},
   {"buurtcode":5990452,"neighborhood":"Noord Kethel","value":4,"category":"cat_avg_welfare"},
   {"buurtcode":5991088,"neighborhood":"Noordereiland","value":13,"category":"cat_avg_welfare"},
   {"buurtcode":5991463,"neighborhood":"Ommoord","value":11,"category":"cat_avg_welfare"},
   {"buurtcode":5991467,"neighborhood":"Oosterflank","value":16,"category":"cat_avg_welfare"},
   {"buurtcode":5991574,"neighborhood":"Oud Charlois","value":16,"category":"cat_avg_welfare"},
   {"buurtcode":5990837,"neighborhood":"Oud Crooswijk","value":23,"category":"cat_avg_welfare"},
   {"buurtcode":5991283,"neighborhood":"Oud IJsselmonde","value":7,"category":"cat_avg_welfare"},
   {"buurtcode":5990327,"neighborhood":"Oud Mathenesse","value":14,"category":"cat_avg_welfare"},
   {"buurtcode":5990535,"neighborhood":"Oude Noorden","value":18,"category":"cat_avg_welfare"},
   {"buurtcode":5990111,"neighborhood":"Oude Westen","value":17,"category":"cat_avg_welfare"},
   {"buurtcode":5990456,"neighborhood":"Overschie","value":9,"category":"cat_avg_welfare"},
   {"buurtcode":5991577,"neighborhood":"Pendrecht","value":19,"category":"cat_avg_welfare"},
   {"buurtcode":5991391,"neighborhood":"Pernis","value":6,"category":"cat_avg_welfare"},
   {"buurtcode":5991448,"neighborhood":"Prinsenland","value":13,"category":"cat_avg_welfare"},
   {"buurtcode":5990516,"neighborhood":"Provenierswijk","value":12,"category":"cat_avg_welfare"},
   {"buurtcode":5991703,"neighborhood":"Rijnpoort","value":2,"category":"cat_avg_welfare"},
   {"buurtcode":5992704,"neighborhood":"Rozenburg","value":8,"category":"cat_avg_welfare"},
   {"buurtcode":5990814,"neighborhood":"Rubroek","value":15,"category":"cat_avg_welfare"},
   {"buurtcode":5990660,"neighborhood":"Schiebroek","value":15,"category":"cat_avg_welfare"},
   {"buurtcode":5990329,"neighborhood":"Schiemond","value":14,"category":"cat_avg_welfare"},
   {"buurtcode":5990454,"neighborhood":"Schieveen","value":4,"category":"cat_avg_welfare"},
   {"buurtcode":5991853,"neighborhood":"Spaanse Polder","value":3,"category":"cat_avg_welfare"},
   {"buurtcode":5990323,"neighborhood":"Spangen","value":17,"category":"cat_avg_welfare"},
   {"buurtcode":5990110,"neighborhood":"Stadsdriehoek","value":7,"category":"cat_avg_welfare"},
   {"buurtcode":5991701,"neighborhood":"Strand en Duin","value":2,"category":"cat_avg_welfare"},
   {"buurtcode":5990847,"neighborhood":"Struisenburg","value":6,"category":"cat_avg_welfare"},
   {"buurtcode":5991571,"neighborhood":"Tarwewijk","value":17,"category":"cat_avg_welfare"},
   {"buurtcode":5990664,"neighborhood":"Terbregge","value":6,"category":"cat_avg_welfare"},
   {"buurtcode":5990322,"neighborhood":"Tussendijken","value":22,"category":"cat_avg_welfare"},
   {"buurtcode":5991080,"neighborhood":"Vreewijk","value":20,"category":"cat_avg_welfare"},
   {"buurtcode":5992194,"neighborhood":"Waalhaven","value":"NA","category":"cat_avg_welfare"},
   {"buurtcode":5992198,"neighborhood":"Waalhaven Zuid","value":"NA","category":"cat_avg_welfare"},
   {"buurtcode":5991575,"neighborhood":"Wielewaal","value":23,"category":"cat_avg_welfare"},
   {"buurtcode":5990328,"neighborhood":"Witte Dorp","value":21,"category":"cat_avg_welfare"},
   {"buurtcode":5990455,"neighborhood":"Zestienhoven","value":5,"category":"cat_avg_welfare"},
   {"buurtcode":5991466,"neighborhood":"Zevenkamp","value":14,"category":"cat_avg_welfare"},
   {"buurtcode":5991578,"neighborhood":"Zuiderpark","value":19,"category":"cat_avg_welfare"},
   {"buurtcode":5991576,"neighborhood":"Zuidplein","value":8,"category":"cat_avg_welfare"},
   {"buurtcode":5991573,"neighborhood":"Zuidwijk","value":20,"category":"cat_avg_welfare"},

   {"buurtcode":5991444,"neighborhood":"s-Gravenland","value":0.89,"category":"cat_sh_welf1"},
   {"buurtcode":5991086,"neighborhood":"Afrikaanderwijk","value":0.62,"category":"cat_sh_welf1"},
   {"buurtcode":5990515,"neighborhood":"Agniesebuurt","value":0.73,"category":"cat_sh_welf1"},
   {"buurtcode":5992459,"neighborhood":"Bedrijvenpark Noord-West","value":"NA","category":"cat_sh_welf1"},
   {"buurtcode":5990531,"neighborhood":"Bergpolder","value":0.83,"category":"cat_sh_welf1"},
   {"buurtcode":5991290,"neighborhood":"Beverwaard","value":0.7,"category":"cat_sh_welf1"},
   {"buurtcode":5990532,"neighborhood":"Blijdorp","value":0.91,"category":"cat_sh_welf1"},
   {"buurtcode":5990558,"neighborhood":"Blijdorpsepolder","value":0.54,"category":"cat_sh_welf1"},
   {"buurtcode":5991081,"neighborhood":"Bloemhof","value":0.65,"category":"cat_sh_welf1"},
   {"buurtcode":5990321,"neighborhood":"Bospolder","value":0.64,"category":"cat_sh_welf1"},
   {"buurtcode":5991572,"neighborhood":"Carnisse","value":0.75,"category":"cat_sh_welf1"},
   {"buurtcode":5991570,"neighborhood":"Charlois Zuidrand","value":0.83,"category":"cat_sh_welf1"},
   {"buurtcode":5990112,"neighborhood":"Cool","value":0.88,"category":"cat_sh_welf1"},
   {"buurtcode":5990113,"neighborhood":"Cs Kwartier","value":0.93,"category":"cat_sh_welf1"},
   {"buurtcode":5990845,"neighborhood":"De Esch","value":0.79,"category":"cat_sh_welf1"},
   {"buurtcode":5990320,"neighborhood":"Delfshaven","value":0.76,"category":"cat_sh_welf1"},
   {"buurtcode":5990119,"neighborhood":"Dijkzigt","value":0.91,"category":"cat_sh_welf1"},
   {"buurtcode":5991702,"neighborhood":"Dorp","value":0.83,"category":"cat_sh_welf1"},
   {"buurtcode":5992196,"neighborhood":"Eemhaven","value":"NA","category":"cat_sh_welf1"},
   {"buurtcode":5992307,"neighborhood":"Europoort","value":"NA","category":"cat_sh_welf1"},
   {"buurtcode":5991087,"neighborhood":"Feijenoord","value":0.58,"category":"cat_sh_welf1"},
   {"buurtcode":5991289,"neighborhood":"Groot IJsselmonde","value":0.75,"category":"cat_sh_welf1"},
   {"buurtcode":5991593,"neighborhood":"Heijplaat","value":0.75,"category":"cat_sh_welf1"},
   {"buurtcode":5991449,"neighborhood":"Het Lage Land","value":0.82,"category":"cat_sh_welf1"},
   {"buurtcode":5990662,"neighborhood":"Hillegersberg Noord","value":0.83,"category":"cat_sh_welf1"},
   {"buurtcode":5990661,"neighborhood":"Hillegersberg Zuid","value":0.9,"category":"cat_sh_welf1"},
   {"buurtcode":5991082,"neighborhood":"Hillesluis","value":0.67,"category":"cat_sh_welf1"},
   {"buurtcode":5991692,"neighborhood":"Hoogvliet Noord","value":0.77,"category":"cat_sh_welf1"},
   {"buurtcode":5991699,"neighborhood":"Hoogvliet Zuid","value":0.81,"category":"cat_sh_welf1"},
   {"buurtcode":5991085,"neighborhood":"Katendrecht","value":0.76,"category":"cat_sh_welf1"},
   {"buurtcode":5990451,"neighborhood":"Kleinpolder","value":0.74,"category":"cat_sh_welf1"},
   {"buurtcode":5991017,"neighborhood":"Kop van Zuid","value":0.93,"category":"cat_sh_welf1"},
   {"buurtcode":5991079,"neighborhood":"Kop van Zuid - Entrepot","value":0.75,"category":"cat_sh_welf1"},
   {"buurtcode":5990842,"neighborhood":"Kralingen Oost","value":0.93,"category":"cat_sh_welf1"},
   {"buurtcode":5990841,"neighborhood":"Kralingen West","value":0.78,"category":"cat_sh_welf1"},
   {"buurtcode":5990843,"neighborhood":"Kralingse Bos","value":0.83,"category":"cat_sh_welf1"},
   {"buurtcode":5991446,"neighborhood":"Kralingseveer","value":0.83,"category":"cat_sh_welf1"},
   {"buurtcode":5990457,"neighborhood":"Landzicht","value":0.66,"category":"cat_sh_welf1"},
   {"buurtcode":5990534,"neighborhood":"Liskwartier","value":0.8,"category":"cat_sh_welf1"},
   {"buurtcode":5991284,"neighborhood":"Lombardijen","value":0.72,"category":"cat_sh_welf1"},
   {"buurtcode":5990325,"neighborhood":"Middelland","value":0.81,"category":"cat_sh_welf1"},
   {"buurtcode":5990665,"neighborhood":"Molenlaankwartier","value":0.92,"category":"cat_sh_welf1"},
   {"buurtcode":5991468,"neighborhood":"Nesselande","value":0.89,"category":"cat_sh_welf1"},
   {"buurtcode":5990836,"neighborhood":"Nieuw Crooswijk","value":0.75,"category":"cat_sh_welf1"},
   {"buurtcode":5991926,"neighborhood":"Nieuw Mathenesse","value":0.91,"category":"cat_sh_welf1"},
   {"buurtcode":5990118,"neighborhood":"Nieuwe Werk","value":0.95,"category":"cat_sh_welf1"},
   {"buurtcode":5990324,"neighborhood":"Nieuwe Westen","value":0.74,"category":"cat_sh_welf1"},
   {"buurtcode":5990452,"neighborhood":"Noord Kethel","value":0.85,"category":"cat_sh_welf1"},
   {"buurtcode":5991088,"neighborhood":"Noordereiland","value":0.79,"category":"cat_sh_welf1"},
   {"buurtcode":5991463,"neighborhood":"Ommoord","value":0.81,"category":"cat_sh_welf1"},
   {"buurtcode":5991467,"neighborhood":"Oosterflank","value":0.73,"category":"cat_sh_welf1"},
   {"buurtcode":5991574,"neighborhood":"Oud Charlois","value":0.73,"category":"cat_sh_welf1"},
   {"buurtcode":5990837,"neighborhood":"Oud Crooswijk","value":0.63,"category":"cat_sh_welf1"},
   {"buurtcode":5991283,"neighborhood":"Oud IJsselmonde","value":0.86,"category":"cat_sh_welf1"},
   {"buurtcode":5990327,"neighborhood":"Oud Mathenesse","value":0.77,"category":"cat_sh_welf1"},
   {"buurtcode":5990535,"neighborhood":"Oude Noorden","value":0.71,"category":"cat_sh_welf1"},
   {"buurtcode":5990111,"neighborhood":"Oude Westen","value":0.72,"category":"cat_sh_welf1"},
   {"buurtcode":5990456,"neighborhood":"Overschie","value":0.84,"category":"cat_sh_welf1"},
   {"buurtcode":5991577,"neighborhood":"Pendrecht","value":0.68,"category":"cat_sh_welf1"},
   {"buurtcode":5991391,"neighborhood":"Pernis","value":0.87,"category":"cat_sh_welf1"},
   {"buurtcode":5991448,"neighborhood":"Prinsenland","value":0.79,"category":"cat_sh_welf1"},
   {"buurtcode":5990516,"neighborhood":"Provenierswijk","value":0.8,"category":"cat_sh_welf1"},
   {"buurtcode":5991703,"neighborhood":"Rijnpoort","value":0.85,"category":"cat_sh_welf1"},
   {"buurtcode":5992704,"neighborhood":"Rozenburg","value":0.84,"category":"cat_sh_welf1"},
   {"buurtcode":5990814,"neighborhood":"Rubroek","value":0.76,"category":"cat_sh_welf1"},
   {"buurtcode":5990660,"neighborhood":"Schiebroek","value":0.75,"category":"cat_sh_welf1"},
   {"buurtcode":5990329,"neighborhood":"Schiemond","value":0.75,"category":"cat_sh_welf1"},
   {"buurtcode":5990454,"neighborhood":"Schieveen","value":0.92,"category":"cat_sh_welf1"},
   {"buurtcode":5991853,"neighborhood":"Spaanse Polder","value":0.85,"category":"cat_sh_welf1"},
   {"buurtcode":5990323,"neighborhood":"Spangen","value":0.69,"category":"cat_sh_welf1"},
   {"buurtcode":5990110,"neighborhood":"Stadsdriehoek","value":0.88,"category":"cat_sh_welf1"},
   {"buurtcode":5991701,"neighborhood":"Strand en Duin","value":0.94,"category":"cat_sh_welf1"},
   {"buurtcode":5990847,"neighborhood":"Struisenburg","value":0.9,"category":"cat_sh_welf1"},
   {"buurtcode":5991571,"neighborhood":"Tarwewijk","value":0.71,"category":"cat_sh_welf1"},
   {"buurtcode":5990664,"neighborhood":"Terbregge","value":0.88,"category":"cat_sh_welf1"},
   {"buurtcode":5990322,"neighborhood":"Tussendijken","value":0.65,"category":"cat_sh_welf1"},
   {"buurtcode":5991080,"neighborhood":"Vreewijk","value":0.67,"category":"cat_sh_welf1"},
   {"buurtcode":5992194,"neighborhood":"Waalhaven","value":"NA","category":"cat_sh_welf1"},
   {"buurtcode":5992198,"neighborhood":"Waalhaven Zuid","value":"NA","category":"cat_sh_welf1"},
   {"buurtcode":5991575,"neighborhood":"Wielewaal","value":0.65,"category":"cat_sh_welf1"},
   {"buurtcode":5990328,"neighborhood":"Witte Dorp","value":0.66,"category":"cat_sh_welf1"},
   {"buurtcode":5990455,"neighborhood":"Zestienhoven","value":0.9,"category":"cat_sh_welf1"},
   {"buurtcode":5991466,"neighborhood":"Zevenkamp","value":0.75,"category":"cat_sh_welf1"},
   {"buurtcode":5991578,"neighborhood":"Zuiderpark","value":0.75,"category":"cat_sh_welf1"},
   {"buurtcode":5991576,"neighborhood":"Zuidplein","value":0.85,"category":"cat_sh_welf1"},
   {"buurtcode":5991573,"neighborhood":"Zuidwijk","value":0.68,"category":"cat_sh_welf1"},

   {"buurtcode":5991444,"neighborhood":"s-Gravenland","value":0.04,"category":"cat_sh_welf2"},
   {"buurtcode":5991086,"neighborhood":"Afrikaanderwijk","value":0.07,"category":"cat_sh_welf2"},
   {"buurtcode":5990515,"neighborhood":"Agniesebuurt","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5992459,"neighborhood":"Bedrijvenpark Noord-West","value":"NA","category":"cat_sh_welf2"},
   {"buurtcode":5990531,"neighborhood":"Bergpolder","value":0.04,"category":"cat_sh_welf2"},
   {"buurtcode":5991290,"neighborhood":"Beverwaard","value":0.08,"category":"cat_sh_welf2"},
   {"buurtcode":5990532,"neighborhood":"Blijdorp","value":0.03,"category":"cat_sh_welf2"},
   {"buurtcode":5990558,"neighborhood":"Blijdorpsepolder","value":"NA","category":"cat_sh_welf2"},
   {"buurtcode":5991081,"neighborhood":"Bloemhof","value":0.08,"category":"cat_sh_welf2"},
   {"buurtcode":5990321,"neighborhood":"Bospolder","value":0.07,"category":"cat_sh_welf2"},
   {"buurtcode":5991572,"neighborhood":"Carnisse","value":0.08,"category":"cat_sh_welf2"},
   {"buurtcode":5991570,"neighborhood":"Charlois Zuidrand","value":0.09,"category":"cat_sh_welf2"},
   {"buurtcode":5990112,"neighborhood":"Cool","value":0.03,"category":"cat_sh_welf2"},
   {"buurtcode":5990113,"neighborhood":"Cs Kwartier","value":0.03,"category":"cat_sh_welf2"},
   {"buurtcode":5990845,"neighborhood":"De Esch","value":0.04,"category":"cat_sh_welf2"},
   {"buurtcode":5990320,"neighborhood":"Delfshaven","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5990119,"neighborhood":"Dijkzigt","value":0.04,"category":"cat_sh_welf2"},
   {"buurtcode":5991702,"neighborhood":"Dorp","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5992196,"neighborhood":"Eemhaven","value":"NA","category":"cat_sh_welf2"},
   {"buurtcode":5992307,"neighborhood":"Europoort","value":"NA","category":"cat_sh_welf2"},
   {"buurtcode":5991087,"neighborhood":"Feijenoord","value":0.07,"category":"cat_sh_welf2"},
   {"buurtcode":5991289,"neighborhood":"Groot IJsselmonde","value":0.06,"category":"cat_sh_welf2"},
   {"buurtcode":5991593,"neighborhood":"Heijplaat","value":0.06,"category":"cat_sh_welf2"},
   {"buurtcode":5991449,"neighborhood":"Het Lage Land","value":0.04,"category":"cat_sh_welf2"},
   {"buurtcode":5990662,"neighborhood":"Hillegersberg Noord","value":0.04,"category":"cat_sh_welf2"},
   {"buurtcode":5990661,"neighborhood":"Hillegersberg Zuid","value":0.04,"category":"cat_sh_welf2"},
   {"buurtcode":5991082,"neighborhood":"Hillesluis","value":0.08,"category":"cat_sh_welf2"},
   {"buurtcode":5991692,"neighborhood":"Hoogvliet Noord","value":0.07,"category":"cat_sh_welf2"},
   {"buurtcode":5991699,"neighborhood":"Hoogvliet Zuid","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5991085,"neighborhood":"Katendrecht","value":0.06,"category":"cat_sh_welf2"},
   {"buurtcode":5990451,"neighborhood":"Kleinpolder","value":0.06,"category":"cat_sh_welf2"},
   {"buurtcode":5991017,"neighborhood":"Kop van Zuid","value":0.04,"category":"cat_sh_welf2"},
   {"buurtcode":5991079,"neighborhood":"Kop van Zuid - Entrepot","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5990842,"neighborhood":"Kralingen Oost","value":0.03,"category":"cat_sh_welf2"},
   {"buurtcode":5990841,"neighborhood":"Kralingen West","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5990843,"neighborhood":"Kralingse Bos","value":"NA","category":"cat_sh_welf2"},
   {"buurtcode":5991446,"neighborhood":"Kralingseveer","value":0.06,"category":"cat_sh_welf2"},
   {"buurtcode":5990457,"neighborhood":"Landzicht","value":"NA","category":"cat_sh_welf2"},
   {"buurtcode":5990534,"neighborhood":"Liskwartier","value":0.04,"category":"cat_sh_welf2"},
   {"buurtcode":5991284,"neighborhood":"Lombardijen","value":0.06,"category":"cat_sh_welf2"},
   {"buurtcode":5990325,"neighborhood":"Middelland","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5990665,"neighborhood":"Molenlaankwartier","value":0.03,"category":"cat_sh_welf2"},
   {"buurtcode":5991468,"neighborhood":"Nesselande","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5990836,"neighborhood":"Nieuw Crooswijk","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5991926,"neighborhood":"Nieuw Mathenesse","value":"NA","category":"cat_sh_welf2"},
   {"buurtcode":5990118,"neighborhood":"Nieuwe Werk","value":0.02,"category":"cat_sh_welf2"},
   {"buurtcode":5990324,"neighborhood":"Nieuwe Westen","value":0.06,"category":"cat_sh_welf2"},
   {"buurtcode":5990452,"neighborhood":"Noord Kethel","value":"NA","category":"cat_sh_welf2"},
   {"buurtcode":5991088,"neighborhood":"Noordereiland","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5991463,"neighborhood":"Ommoord","value":0.04,"category":"cat_sh_welf2"},
   {"buurtcode":5991467,"neighborhood":"Oosterflank","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5991574,"neighborhood":"Oud Charlois","value":0.06,"category":"cat_sh_welf2"},
   {"buurtcode":5990837,"neighborhood":"Oud Crooswijk","value":0.06,"category":"cat_sh_welf2"},
   {"buurtcode":5991283,"neighborhood":"Oud IJsselmonde","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5990327,"neighborhood":"Oud Mathenesse","value":0.06,"category":"cat_sh_welf2"},
   {"buurtcode":5990535,"neighborhood":"Oude Noorden","value":0.06,"category":"cat_sh_welf2"},
   {"buurtcode":5990111,"neighborhood":"Oude Westen","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5990456,"neighborhood":"Overschie","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5991577,"neighborhood":"Pendrecht","value":0.06,"category":"cat_sh_welf2"},
   {"buurtcode":5991391,"neighborhood":"Pernis","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5991448,"neighborhood":"Prinsenland","value":0.04,"category":"cat_sh_welf2"},
   {"buurtcode":5990516,"neighborhood":"Provenierswijk","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5991703,"neighborhood":"Rijnpoort","value":"NA","category":"cat_sh_welf2"},
   {"buurtcode":5992704,"neighborhood":"Rozenburg","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5990814,"neighborhood":"Rubroek","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5990660,"neighborhood":"Schiebroek","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5990329,"neighborhood":"Schiemond","value":0.06,"category":"cat_sh_welf2"},
   {"buurtcode":5990454,"neighborhood":"Schieveen","value":"NA","category":"cat_sh_welf2"},
   {"buurtcode":5991853,"neighborhood":"Spaanse Polder","value":"NA","category":"cat_sh_welf2"},
   {"buurtcode":5990323,"neighborhood":"Spangen","value":0.08,"category":"cat_sh_welf2"},
   {"buurtcode":5990110,"neighborhood":"Stadsdriehoek","value":0.03,"category":"cat_sh_welf2"},
   {"buurtcode":5991701,"neighborhood":"Strand en Duin","value":0.03,"category":"cat_sh_welf2"},
   {"buurtcode":5990847,"neighborhood":"Struisenburg","value":0.03,"category":"cat_sh_welf2"},
   {"buurtcode":5991571,"neighborhood":"Tarwewijk","value":0.08,"category":"cat_sh_welf2"},
   {"buurtcode":5990664,"neighborhood":"Terbregge","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5990322,"neighborhood":"Tussendijken","value":0.07,"category":"cat_sh_welf2"},
   {"buurtcode":5991080,"neighborhood":"Vreewijk","value":0.06,"category":"cat_sh_welf2"},
   {"buurtcode":5992194,"neighborhood":"Waalhaven","value":"NA","category":"cat_sh_welf2"},
   {"buurtcode":5992198,"neighborhood":"Waalhaven Zuid","value":"NA","category":"cat_sh_welf2"},
   {"buurtcode":5991575,"neighborhood":"Wielewaal","value":0.07,"category":"cat_sh_welf2"},
   {"buurtcode":5990328,"neighborhood":"Witte Dorp","value":0.07,"category":"cat_sh_welf2"},
   {"buurtcode":5990455,"neighborhood":"Zestienhoven","value":0.05,"category":"cat_sh_welf2"},
   {"buurtcode":5991466,"neighborhood":"Zevenkamp","value":0.06,"category":"cat_sh_welf2"},
   {"buurtcode":5991578,"neighborhood":"Zuiderpark","value":0.03,"category":"cat_sh_welf2"},
   {"buurtcode":5991576,"neighborhood":"Zuidplein","value":0.04,"category":"cat_sh_welf2"},
   {"buurtcode":5991573,"neighborhood":"Zuidwijk","value":0.06,"category":"cat_sh_welf2"},

   {"buurtcode":5991444,"neighborhood":"s-Gravenland","value":0.03,"category":"cat_sh_welf3"},
   {"buurtcode":5991086,"neighborhood":"Afrikaanderwijk","value":0.12,"category":"cat_sh_welf3"},
   {"buurtcode":5990515,"neighborhood":"Agniesebuurt","value":0.07,"category":"cat_sh_welf3"},
   {"buurtcode":5992459,"neighborhood":"Bedrijvenpark Noord-West","value":"NA","category":"cat_sh_welf3"},
   {"buurtcode":5990531,"neighborhood":"Bergpolder","value":0.04,"category":"cat_sh_welf3"},
   {"buurtcode":5991290,"neighborhood":"Beverwaard","value":0.09,"category":"cat_sh_welf3"},
   {"buurtcode":5990532,"neighborhood":"Blijdorp","value":0.02,"category":"cat_sh_welf3"},
   {"buurtcode":5990558,"neighborhood":"Blijdorpsepolder","value":"NA","category":"cat_sh_welf3"},
   {"buurtcode":5991081,"neighborhood":"Bloemhof","value":0.09,"category":"cat_sh_welf3"},
   {"buurtcode":5990321,"neighborhood":"Bospolder","value":0.1,"category":"cat_sh_welf3"},
   {"buurtcode":5991572,"neighborhood":"Carnisse","value":0.06,"category":"cat_sh_welf3"},
   {"buurtcode":5991570,"neighborhood":"Charlois Zuidrand","value":"NA","category":"cat_sh_welf3"},
   {"buurtcode":5990112,"neighborhood":"Cool","value":0.03,"category":"cat_sh_welf3"},
   {"buurtcode":5990113,"neighborhood":"Cs Kwartier","value":"NA","category":"cat_sh_welf3"},
   {"buurtcode":5990845,"neighborhood":"De Esch","value":0.05,"category":"cat_sh_welf3"},
   {"buurtcode":5990320,"neighborhood":"Delfshaven","value":0.05,"category":"cat_sh_welf3"},
   {"buurtcode":5990119,"neighborhood":"Dijkzigt","value":0.03,"category":"cat_sh_welf3"},
   {"buurtcode":5991702,"neighborhood":"Dorp","value":0.04,"category":"cat_sh_welf3"},
   {"buurtcode":5992196,"neighborhood":"Eemhaven","value":"NA","category":"cat_sh_welf3"},
   {"buurtcode":5992307,"neighborhood":"Europoort","value":"NA","category":"cat_sh_welf3"},
   {"buurtcode":5991087,"neighborhood":"Feijenoord","value":0.11,"category":"cat_sh_welf3"},
   {"buurtcode":5991289,"neighborhood":"Groot IJsselmonde","value":0.06,"category":"cat_sh_welf3"},
   {"buurtcode":5991593,"neighborhood":"Heijplaat","value":0.07,"category":"cat_sh_welf3"},
   {"buurtcode":5991449,"neighborhood":"Het Lage Land","value":0.04,"category":"cat_sh_welf3"},
   {"buurtcode":5990662,"neighborhood":"Hillegersberg Noord","value":0.03,"category":"cat_sh_welf3"},
   {"buurtcode":5990661,"neighborhood":"Hillegersberg Zuid","value":0.02,"category":"cat_sh_welf3"},
   {"buurtcode":5991082,"neighborhood":"Hillesluis","value":0.09,"category":"cat_sh_welf3"},
   {"buurtcode":5991692,"neighborhood":"Hoogvliet Noord","value":0.06,"category":"cat_sh_welf3"},
   {"buurtcode":5991699,"neighborhood":"Hoogvliet Zuid","value":0.05,"category":"cat_sh_welf3"},
   {"buurtcode":5991085,"neighborhood":"Katendrecht","value":0.06,"category":"cat_sh_welf3"},
   {"buurtcode":5990451,"neighborhood":"Kleinpolder","value":0.06,"category":"cat_sh_welf3"},
   {"buurtcode":5991017,"neighborhood":"Kop van Zuid","value":0.01,"category":"cat_sh_welf3"},
   {"buurtcode":5991079,"neighborhood":"Kop van Zuid - Entrepot","value":0.07,"category":"cat_sh_welf3"},
   {"buurtcode":5990842,"neighborhood":"Kralingen Oost","value":0.01,"category":"cat_sh_welf3"},
   {"buurtcode":5990841,"neighborhood":"Kralingen West","value":0.05,"category":"cat_sh_welf3"},
   {"buurtcode":5990843,"neighborhood":"Kralingse Bos","value":"NA","category":"cat_sh_welf3"},
   {"buurtcode":5991446,"neighborhood":"Kralingseveer","value":0.05,"category":"cat_sh_welf3"},
   {"buurtcode":5990457,"neighborhood":"Landzicht","value":0.08,"category":"cat_sh_welf3"},
   {"buurtcode":5990534,"neighborhood":"Liskwartier","value":0.05,"category":"cat_sh_welf3"},
   {"buurtcode":5991284,"neighborhood":"Lombardijen","value":0.07,"category":"cat_sh_welf3"},
   {"buurtcode":5990325,"neighborhood":"Middelland","value":0.04,"category":"cat_sh_welf3"},
   {"buurtcode":5990665,"neighborhood":"Molenlaankwartier","value":0.02,"category":"cat_sh_welf3"},
   {"buurtcode":5991468,"neighborhood":"Nesselande","value":0.03,"category":"cat_sh_welf3"},
   {"buurtcode":5990836,"neighborhood":"Nieuw Crooswijk","value":0.06,"category":"cat_sh_welf3"},
   {"buurtcode":5991926,"neighborhood":"Nieuw Mathenesse","value":"NA","category":"cat_sh_welf3"},
   {"buurtcode":5990118,"neighborhood":"Nieuwe Werk","value":0.01,"category":"cat_sh_welf3"},
   {"buurtcode":5990324,"neighborhood":"Nieuwe Westen","value":0.06,"category":"cat_sh_welf3"},
   {"buurtcode":5990452,"neighborhood":"Noord Kethel","value":"NA","category":"cat_sh_welf3"},
   {"buurtcode":5991088,"neighborhood":"Noordereiland","value":0.05,"category":"cat_sh_welf3"},
   {"buurtcode":5991463,"neighborhood":"Ommoord","value":0.04,"category":"cat_sh_welf3"},
   {"buurtcode":5991467,"neighborhood":"Oosterflank","value":0.06,"category":"cat_sh_welf3"},
   {"buurtcode":5991574,"neighborhood":"Oud Charlois","value":0.07,"category":"cat_sh_welf3"},
   {"buurtcode":5990837,"neighborhood":"Oud Crooswijk","value":0.09,"category":"cat_sh_welf3"},
   {"buurtcode":5991283,"neighborhood":"Oud IJsselmonde","value":0.03,"category":"cat_sh_welf3"},
   {"buurtcode":5990327,"neighborhood":"Oud Mathenesse","value":0.05,"category":"cat_sh_welf3"},
   {"buurtcode":5990535,"neighborhood":"Oude Noorden","value":0.07,"category":"cat_sh_welf3"},
   {"buurtcode":5990111,"neighborhood":"Oude Westen","value":0.07,"category":"cat_sh_welf3"},
   {"buurtcode":5990456,"neighborhood":"Overschie","value":0.04,"category":"cat_sh_welf3"},
   {"buurtcode":5991577,"neighborhood":"Pendrecht","value":0.08,"category":"cat_sh_welf3"},
   {"buurtcode":5991391,"neighborhood":"Pernis","value":0.04,"category":"cat_sh_welf3"},
   {"buurtcode":5991448,"neighborhood":"Prinsenland","value":0.04,"category":"cat_sh_welf3"},
   {"buurtcode":5990516,"neighborhood":"Provenierswijk","value":0.05,"category":"cat_sh_welf3"},
   {"buurtcode":5991703,"neighborhood":"Rijnpoort","value":"NA","category":"cat_sh_welf3"},
   {"buurtcode":5992704,"neighborhood":"Rozenburg","value":0.05,"category":"cat_sh_welf3"},
   {"buurtcode":5990814,"neighborhood":"Rubroek","value":0.06,"category":"cat_sh_welf3"},
   {"buurtcode":5990660,"neighborhood":"Schiebroek","value":0.06,"category":"cat_sh_welf3"},
   {"buurtcode":5990329,"neighborhood":"Schiemond","value":0.07,"category":"cat_sh_welf3"},
   {"buurtcode":5990454,"neighborhood":"Schieveen","value":"NA","category":"cat_sh_welf3"},
   {"buurtcode":5991853,"neighborhood":"Spaanse Polder","value":"NA","category":"cat_sh_welf3"},
   {"buurtcode":5990323,"neighborhood":"Spangen","value":0.08,"category":"cat_sh_welf3"},
   {"buurtcode":5990110,"neighborhood":"Stadsdriehoek","value":0.02,"category":"cat_sh_welf3"},
   {"buurtcode":5991701,"neighborhood":"Strand en Duin","value":0.02,"category":"cat_sh_welf3"},
   {"buurtcode":5990847,"neighborhood":"Struisenburg","value":0.02,"category":"cat_sh_welf3"},
   {"buurtcode":5991571,"neighborhood":"Tarwewijk","value":0.07,"category":"cat_sh_welf3"},
   {"buurtcode":5990664,"neighborhood":"Terbregge","value":0.03,"category":"cat_sh_welf3"},
   {"buurtcode":5990322,"neighborhood":"Tussendijken","value":0.09,"category":"cat_sh_welf3"},
   {"buurtcode":5991080,"neighborhood":"Vreewijk","value":0.09,"category":"cat_sh_welf3"},
   {"buurtcode":5992194,"neighborhood":"Waalhaven","value":"NA","category":"cat_sh_welf3"},
   {"buurtcode":5992198,"neighborhood":"Waalhaven Zuid","value":"NA","category":"cat_sh_welf3"},
   {"buurtcode":5991575,"neighborhood":"Wielewaal","value":0.05,"category":"cat_sh_welf3"},
   {"buurtcode":5990328,"neighborhood":"Witte Dorp","value":0.08,"category":"cat_sh_welf3"},
   {"buurtcode":5990455,"neighborhood":"Zestienhoven","value":0.02,"category":"cat_sh_welf3"},
   {"buurtcode":5991466,"neighborhood":"Zevenkamp","value":0.06,"category":"cat_sh_welf3"},
   {"buurtcode":5991578,"neighborhood":"Zuiderpark","value":0.05,"category":"cat_sh_welf3"},
   {"buurtcode":5991576,"neighborhood":"Zuidplein","value":0.03,"category":"cat_sh_welf3"},
   {"buurtcode":5991573,"neighborhood":"Zuidwijk","value":0.07,"category":"cat_sh_welf3"},

   {"buurtcode":5991444,"neighborhood":"s-Gravenland","value":0.02,"category":"cat_sh_welf4"},
   {"buurtcode":5991086,"neighborhood":"Afrikaanderwijk","value":0.06,"category":"cat_sh_welf4"},
   {"buurtcode":5990515,"neighborhood":"Agniesebuurt","value":0.04,"category":"cat_sh_welf4"},
   {"buurtcode":5992459,"neighborhood":"Bedrijvenpark Noord-West","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5990531,"neighborhood":"Bergpolder","value":0.02,"category":"cat_sh_welf4"},
   {"buurtcode":5991290,"neighborhood":"Beverwaard","value":0.05,"category":"cat_sh_welf4"},
   {"buurtcode":5990532,"neighborhood":"Blijdorp","value":0.01,"category":"cat_sh_welf4"},
   {"buurtcode":5990558,"neighborhood":"Blijdorpsepolder","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5991081,"neighborhood":"Bloemhof","value":0.06,"category":"cat_sh_welf4"},
   {"buurtcode":5990321,"neighborhood":"Bospolder","value":0.07,"category":"cat_sh_welf4"},
   {"buurtcode":5991572,"neighborhood":"Carnisse","value":0.04,"category":"cat_sh_welf4"},
   {"buurtcode":5991570,"neighborhood":"Charlois Zuidrand","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5990112,"neighborhood":"Cool","value":0.02,"category":"cat_sh_welf4"},
   {"buurtcode":5990113,"neighborhood":"Cs Kwartier","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5990845,"neighborhood":"De Esch","value":0.03,"category":"cat_sh_welf4"},
   {"buurtcode":5990320,"neighborhood":"Delfshaven","value":0.04,"category":"cat_sh_welf4"},
   {"buurtcode":5990119,"neighborhood":"Dijkzigt","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5991702,"neighborhood":"Dorp","value":0.03,"category":"cat_sh_welf4"},
   {"buurtcode":5992196,"neighborhood":"Eemhaven","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5992307,"neighborhood":"Europoort","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5991087,"neighborhood":"Feijenoord","value":0.08,"category":"cat_sh_welf4"},
   {"buurtcode":5991289,"neighborhood":"Groot IJsselmonde","value":0.04,"category":"cat_sh_welf4"},
   {"buurtcode":5991593,"neighborhood":"Heijplaat","value":0.04,"category":"cat_sh_welf4"},
   {"buurtcode":5991449,"neighborhood":"Het Lage Land","value":0.03,"category":"cat_sh_welf4"},
   {"buurtcode":5990662,"neighborhood":"Hillegersberg Noord","value":0.03,"category":"cat_sh_welf4"},
   {"buurtcode":5990661,"neighborhood":"Hillegersberg Zuid","value":0.01,"category":"cat_sh_welf4"},
   {"buurtcode":5991082,"neighborhood":"Hillesluis","value":0.05,"category":"cat_sh_welf4"},
   {"buurtcode":5991692,"neighborhood":"Hoogvliet Noord","value":0.03,"category":"cat_sh_welf4"},
   {"buurtcode":5991699,"neighborhood":"Hoogvliet Zuid","value":0.03,"category":"cat_sh_welf4"},
   {"buurtcode":5991085,"neighborhood":"Katendrecht","value":0.04,"category":"cat_sh_welf4"},
   {"buurtcode":5990451,"neighborhood":"Kleinpolder","value":0.05,"category":"cat_sh_welf4"},
   {"buurtcode":5991017,"neighborhood":"Kop van Zuid","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5991079,"neighborhood":"Kop van Zuid - Entrepot","value":0.05,"category":"cat_sh_welf4"},
   {"buurtcode":5990842,"neighborhood":"Kralingen Oost","value":0.01,"category":"cat_sh_welf4"},
   {"buurtcode":5990841,"neighborhood":"Kralingen West","value":0.04,"category":"cat_sh_welf4"},
   {"buurtcode":5990843,"neighborhood":"Kralingse Bos","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5991446,"neighborhood":"Kralingseveer","value":0.02,"category":"cat_sh_welf4"},
   {"buurtcode":5990457,"neighborhood":"Landzicht","value":0.07,"category":"cat_sh_welf4"},
   {"buurtcode":5990534,"neighborhood":"Liskwartier","value":0.03,"category":"cat_sh_welf4"},
   {"buurtcode":5991284,"neighborhood":"Lombardijen","value":0.05,"category":"cat_sh_welf4"},
   {"buurtcode":5990325,"neighborhood":"Middelland","value":0.03,"category":"cat_sh_welf4"},
   {"buurtcode":5990665,"neighborhood":"Molenlaankwartier","value":0.01,"category":"cat_sh_welf4"},
   {"buurtcode":5991468,"neighborhood":"Nesselande","value":0.01,"category":"cat_sh_welf4"},
   {"buurtcode":5990836,"neighborhood":"Nieuw Crooswijk","value":0.05,"category":"cat_sh_welf4"},
   {"buurtcode":5991926,"neighborhood":"Nieuw Mathenesse","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5990118,"neighborhood":"Nieuwe Werk","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5990324,"neighborhood":"Nieuwe Westen","value":0.05,"category":"cat_sh_welf4"},
   {"buurtcode":5990452,"neighborhood":"Noord Kethel","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5991088,"neighborhood":"Noordereiland","value":0.04,"category":"cat_sh_welf4"},
   {"buurtcode":5991463,"neighborhood":"Ommoord","value":0.03,"category":"cat_sh_welf4"},
   {"buurtcode":5991467,"neighborhood":"Oosterflank","value":0.05,"category":"cat_sh_welf4"},
   {"buurtcode":5991574,"neighborhood":"Oud Charlois","value":0.04,"category":"cat_sh_welf4"},
   {"buurtcode":5990837,"neighborhood":"Oud Crooswijk","value":0.07,"category":"cat_sh_welf4"},
   {"buurtcode":5991283,"neighborhood":"Oud IJsselmonde","value":0.02,"category":"cat_sh_welf4"},
   {"buurtcode":5990327,"neighborhood":"Oud Mathenesse","value":0.04,"category":"cat_sh_welf4"},
   {"buurtcode":5990535,"neighborhood":"Oude Noorden","value":0.05,"category":"cat_sh_welf4"},
   {"buurtcode":5990111,"neighborhood":"Oude Westen","value":0.05,"category":"cat_sh_welf4"},
   {"buurtcode":5990456,"neighborhood":"Overschie","value":0.02,"category":"cat_sh_welf4"},
   {"buurtcode":5991577,"neighborhood":"Pendrecht","value":0.06,"category":"cat_sh_welf4"},
   {"buurtcode":5991391,"neighborhood":"Pernis","value":0.02,"category":"cat_sh_welf4"},
   {"buurtcode":5991448,"neighborhood":"Prinsenland","value":0.03,"category":"cat_sh_welf4"},
   {"buurtcode":5990516,"neighborhood":"Provenierswijk","value":0.03,"category":"cat_sh_welf4"},
   {"buurtcode":5991703,"neighborhood":"Rijnpoort","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5992704,"neighborhood":"Rozenburg","value":0.02,"category":"cat_sh_welf4"},
   {"buurtcode":5990814,"neighborhood":"Rubroek","value":0.05,"category":"cat_sh_welf4"},
   {"buurtcode":5990660,"neighborhood":"Schiebroek","value":0.05,"category":"cat_sh_welf4"},
   {"buurtcode":5990329,"neighborhood":"Schiemond","value":0.04,"category":"cat_sh_welf4"},
   {"buurtcode":5990454,"neighborhood":"Schieveen","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5991853,"neighborhood":"Spaanse Polder","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5990323,"neighborhood":"Spangen","value":0.06,"category":"cat_sh_welf4"},
   {"buurtcode":5990110,"neighborhood":"Stadsdriehoek","value":0.02,"category":"cat_sh_welf4"},
   {"buurtcode":5991701,"neighborhood":"Strand en Duin","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5990847,"neighborhood":"Struisenburg","value":0.01,"category":"cat_sh_welf4"},
   {"buurtcode":5991571,"neighborhood":"Tarwewijk","value":0.05,"category":"cat_sh_welf4"},
   {"buurtcode":5990664,"neighborhood":"Terbregge","value":0.01,"category":"cat_sh_welf4"},
   {"buurtcode":5990322,"neighborhood":"Tussendijken","value":0.06,"category":"cat_sh_welf4"},
   {"buurtcode":5991080,"neighborhood":"Vreewijk","value":0.06,"category":"cat_sh_welf4"},
   {"buurtcode":5992194,"neighborhood":"Waalhaven","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5992198,"neighborhood":"Waalhaven Zuid","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5991575,"neighborhood":"Wielewaal","value":0.05,"category":"cat_sh_welf4"},
   {"buurtcode":5990328,"neighborhood":"Witte Dorp","value":0.07,"category":"cat_sh_welf4"},
   {"buurtcode":5990455,"neighborhood":"Zestienhoven","value":"NA","category":"cat_sh_welf4"},
   {"buurtcode":5991466,"neighborhood":"Zevenkamp","value":0.04,"category":"cat_sh_welf4"},
   {"buurtcode":5991578,"neighborhood":"Zuiderpark","value":0.04,"category":"cat_sh_welf4"},
   {"buurtcode":5991576,"neighborhood":"Zuidplein","value":0.02,"category":"cat_sh_welf4"},
   {"buurtcode":5991573,"neighborhood":"Zuidwijk","value":0.06,"category":"cat_sh_welf4"},

   {"buurtcode":5991444,"neighborhood":"s-Gravenland","value":0.02,"category":"cat_sh_welf5"},
   {"buurtcode":5991086,"neighborhood":"Afrikaanderwijk","value":0.13,"category":"cat_sh_welf5"},
   {"buurtcode":5990515,"neighborhood":"Agniesebuurt","value":0.11,"category":"cat_sh_welf5"},
   {"buurtcode":5992459,"neighborhood":"Bedrijvenpark Noord-West","value":"NA","category":"cat_sh_welf5"},
   {"buurtcode":5990531,"neighborhood":"Bergpolder","value":0.06,"category":"cat_sh_welf5"},
   {"buurtcode":5991290,"neighborhood":"Beverwaard","value":0.08,"category":"cat_sh_welf5"},
   {"buurtcode":5990532,"neighborhood":"Blijdorp","value":0.03,"category":"cat_sh_welf5"},
   {"buurtcode":5990558,"neighborhood":"Blijdorpsepolder","value":0.27,"category":"cat_sh_welf5"},
   {"buurtcode":5991081,"neighborhood":"Bloemhof","value":0.12,"category":"cat_sh_welf5"},
   {"buurtcode":5990321,"neighborhood":"Bospolder","value":0.13,"category":"cat_sh_welf5"},
   {"buurtcode":5991572,"neighborhood":"Carnisse","value":0.07,"category":"cat_sh_welf5"},
   {"buurtcode":5991570,"neighborhood":"Charlois Zuidrand","value":"NA","category":"cat_sh_welf5"},
   {"buurtcode":5990112,"neighborhood":"Cool","value":0.04,"category":"cat_sh_welf5"},
   {"buurtcode":5990113,"neighborhood":"Cs Kwartier","value":0.02,"category":"cat_sh_welf5"},
   {"buurtcode":5990845,"neighborhood":"De Esch","value":0.08,"category":"cat_sh_welf5"},
   {"buurtcode":5990320,"neighborhood":"Delfshaven","value":0.1,"category":"cat_sh_welf5"},
   {"buurtcode":5990119,"neighborhood":"Dijkzigt","value":"NA","category":"cat_sh_welf5"},
   {"buurtcode":5991702,"neighborhood":"Dorp","value":0.05,"category":"cat_sh_welf5"},
   {"buurtcode":5992196,"neighborhood":"Eemhaven","value":"NA","category":"cat_sh_welf5"},
   {"buurtcode":5992307,"neighborhood":"Europoort","value":"NA","category":"cat_sh_welf5"},
   {"buurtcode":5991087,"neighborhood":"Feijenoord","value":0.16,"category":"cat_sh_welf5"},
   {"buurtcode":5991289,"neighborhood":"Groot IJsselmonde","value":0.08,"category":"cat_sh_welf5"},
   {"buurtcode":5991593,"neighborhood":"Heijplaat","value":0.07,"category":"cat_sh_welf5"},
   {"buurtcode":5991449,"neighborhood":"Het Lage Land","value":0.06,"category":"cat_sh_welf5"},
   {"buurtcode":5990662,"neighborhood":"Hillegersberg Noord","value":0.07,"category":"cat_sh_welf5"},
   {"buurtcode":5990661,"neighborhood":"Hillegersberg Zuid","value":0.03,"category":"cat_sh_welf5"},
   {"buurtcode":5991082,"neighborhood":"Hillesluis","value":0.11,"category":"cat_sh_welf5"},
   {"buurtcode":5991692,"neighborhood":"Hoogvliet Noord","value":0.06,"category":"cat_sh_welf5"},
   {"buurtcode":5991699,"neighborhood":"Hoogvliet Zuid","value":0.06,"category":"cat_sh_welf5"},
   {"buurtcode":5991085,"neighborhood":"Katendrecht","value":0.08,"category":"cat_sh_welf5"},
   {"buurtcode":5990451,"neighborhood":"Kleinpolder","value":0.1,"category":"cat_sh_welf5"},
   {"buurtcode":5991017,"neighborhood":"Kop van Zuid","value":0.01,"category":"cat_sh_welf5"},
   {"buurtcode":5991079,"neighborhood":"Kop van Zuid - Entrepot","value":0.07,"category":"cat_sh_welf5"},
   {"buurtcode":5990842,"neighborhood":"Kralingen Oost","value":0.02,"category":"cat_sh_welf5"},
   {"buurtcode":5990841,"neighborhood":"Kralingen West","value":0.08,"category":"cat_sh_welf5"},
   {"buurtcode":5990843,"neighborhood":"Kralingse Bos","value":"NA","category":"cat_sh_welf5"},
   {"buurtcode":5991446,"neighborhood":"Kralingseveer","value":0.04,"category":"cat_sh_welf5"},
   {"buurtcode":5990457,"neighborhood":"Landzicht","value":0.15,"category":"cat_sh_welf5"},
   {"buurtcode":5990534,"neighborhood":"Liskwartier","value":0.07,"category":"cat_sh_welf5"},
   {"buurtcode":5991284,"neighborhood":"Lombardijen","value":0.09,"category":"cat_sh_welf5"},
   {"buurtcode":5990325,"neighborhood":"Middelland","value":0.07,"category":"cat_sh_welf5"},
   {"buurtcode":5990665,"neighborhood":"Molenlaankwartier","value":0.02,"category":"cat_sh_welf5"},
   {"buurtcode":5991468,"neighborhood":"Nesselande","value":0.02,"category":"cat_sh_welf5"},
   {"buurtcode":5990836,"neighborhood":"Nieuw Crooswijk","value":0.08,"category":"cat_sh_welf5"},
   {"buurtcode":5991926,"neighborhood":"Nieuw Mathenesse","value":"NA","category":"cat_sh_welf5"},
   {"buurtcode":5990118,"neighborhood":"Nieuwe Werk","value":0.02,"category":"cat_sh_welf5"},
   {"buurtcode":5990324,"neighborhood":"Nieuwe Westen","value":0.09,"category":"cat_sh_welf5"},
   {"buurtcode":5990452,"neighborhood":"Noord Kethel","value":"NA","category":"cat_sh_welf5"},
   {"buurtcode":5991088,"neighborhood":"Noordereiland","value":0.08,"category":"cat_sh_welf5"},
   {"buurtcode":5991463,"neighborhood":"Ommoord","value":0.07,"category":"cat_sh_welf5"},
   {"buurtcode":5991467,"neighborhood":"Oosterflank","value":0.11,"category":"cat_sh_welf5"},
   {"buurtcode":5991574,"neighborhood":"Oud Charlois","value":0.09,"category":"cat_sh_welf5"},
   {"buurtcode":5990837,"neighborhood":"Oud Crooswijk","value":0.15,"category":"cat_sh_welf5"},
   {"buurtcode":5991283,"neighborhood":"Oud IJsselmonde","value":0.03,"category":"cat_sh_welf5"},
   {"buurtcode":5990327,"neighborhood":"Oud Mathenesse","value":0.08,"category":"cat_sh_welf5"},
   {"buurtcode":5990535,"neighborhood":"Oude Noorden","value":0.11,"category":"cat_sh_welf5"},
   {"buurtcode":5990111,"neighborhood":"Oude Westen","value":0.11,"category":"cat_sh_welf5"},
   {"buurtcode":5990456,"neighborhood":"Overschie","value":0.05,"category":"cat_sh_welf5"},
   {"buurtcode":5991577,"neighborhood":"Pendrecht","value":0.11,"category":"cat_sh_welf5"},
   {"buurtcode":5991391,"neighborhood":"Pernis","value":0.03,"category":"cat_sh_welf5"},
   {"buurtcode":5991448,"neighborhood":"Prinsenland","value":0.09,"category":"cat_sh_welf5"},
   {"buurtcode":5990516,"neighborhood":"Provenierswijk","value":0.07,"category":"cat_sh_welf5"},
   {"buurtcode":5991703,"neighborhood":"Rijnpoort","value":"NA","category":"cat_sh_welf5"},
   {"buurtcode":5992704,"neighborhood":"Rozenburg","value":0.04,"category":"cat_sh_welf5"},
   {"buurtcode":5990814,"neighborhood":"Rubroek","value":0.09,"category":"cat_sh_welf5"},
   {"buurtcode":5990660,"neighborhood":"Schiebroek","value":0.09,"category":"cat_sh_welf5"},
   {"buurtcode":5990329,"neighborhood":"Schiemond","value":0.07,"category":"cat_sh_welf5"},
   {"buurtcode":5990454,"neighborhood":"Schieveen","value":"NA","category":"cat_sh_welf5"},
   {"buurtcode":5991853,"neighborhood":"Spaanse Polder","value":"NA","category":"cat_sh_welf5"},
   {"buurtcode":5990323,"neighborhood":"Spangen","value":0.09,"category":"cat_sh_welf5"},
   {"buurtcode":5990110,"neighborhood":"Stadsdriehoek","value":0.05,"category":"cat_sh_welf5"},
   {"buurtcode":5991701,"neighborhood":"Strand en Duin","value":"NA","category":"cat_sh_welf5"},
   {"buurtcode":5990847,"neighborhood":"Struisenburg","value":0.03,"category":"cat_sh_welf5"},
   {"buurtcode":5991571,"neighborhood":"Tarwewijk","value":0.1,"category":"cat_sh_welf5"},
   {"buurtcode":5990664,"neighborhood":"Terbregge","value":0.03,"category":"cat_sh_welf5"},
   {"buurtcode":5990322,"neighborhood":"Tussendijken","value":0.13,"category":"cat_sh_welf5"},
   {"buurtcode":5991080,"neighborhood":"Vreewijk","value":0.12,"category":"cat_sh_welf5"},
   {"buurtcode":5992194,"neighborhood":"Waalhaven","value":"NA","category":"cat_sh_welf5"},
   {"buurtcode":5992198,"neighborhood":"Waalhaven Zuid","value":"NA","category":"cat_sh_welf5"},
   {"buurtcode":5991575,"neighborhood":"Wielewaal","value":0.17,"category":"cat_sh_welf5"},
   {"buurtcode":5990328,"neighborhood":"Witte Dorp","value":0.12,"category":"cat_sh_welf5"},
   {"buurtcode":5990455,"neighborhood":"Zestienhoven","value":0.02,"category":"cat_sh_welf5"},
   {"buurtcode":5991466,"neighborhood":"Zevenkamp","value":0.08,"category":"cat_sh_welf5"},
   {"buurtcode":5991578,"neighborhood":"Zuiderpark","value":0.14,"category":"cat_sh_welf5"},
   {"buurtcode":5991576,"neighborhood":"Zuidplein","value":0.05,"category":"cat_sh_welf5"},
   {"buurtcode":5991573,"neighborhood":"Zuidwijk","value":0.13,"category":"cat_sh_welf5"},

   {"buurtcode":5991444,"neighborhood":"s-Gravenland","value":0.1,"category":"cat_est_disc"},
   {"buurtcode":5991086,"neighborhood":"Afrikaanderwijk","value":0.23,"category":"cat_est_disc"},
   {"buurtcode":5990515,"neighborhood":"Agniesebuurt","value":0.18,"category":"cat_est_disc"},
   {"buurtcode":5992459,"neighborhood":"Bedrijvenpark Noord-West","value":"NA","category":"cat_est_disc"},
   {"buurtcode":5990531,"neighborhood":"Bergpolder","value":0.14,"category":"cat_est_disc"},
   {"buurtcode":5991290,"neighborhood":"Beverwaard","value":0.2,"category":"cat_est_disc"},
   {"buurtcode":5990532,"neighborhood":"Blijdorp","value":0.11,"category":"cat_est_disc"},
   {"buurtcode":5990558,"neighborhood":"Blijdorpsepolder","value":"NA","category":"cat_est_disc"},
   {"buurtcode":5991081,"neighborhood":"Bloemhof","value":0.23,"category":"cat_est_disc"},
   {"buurtcode":5990321,"neighborhood":"Bospolder","value":0.22,"category":"cat_est_disc"},
   {"buurtcode":5991572,"neighborhood":"Carnisse","value":0.24,"category":"cat_est_disc"},
   {"buurtcode":5991570,"neighborhood":"Charlois Zuidrand","value":0.13,"category":"cat_est_disc"},
   {"buurtcode":5990112,"neighborhood":"Cool","value":0.18,"category":"cat_est_disc"},
   {"buurtcode":5990113,"neighborhood":"Cs Kwartier","value":0.21,"category":"cat_est_disc"},
   {"buurtcode":5990845,"neighborhood":"De Esch","value":0.16,"category":"cat_est_disc"},
   {"buurtcode":5990320,"neighborhood":"Delfshaven","value":0.17,"category":"cat_est_disc"},
   {"buurtcode":5990119,"neighborhood":"Dijkzigt","value":0.14,"category":"cat_est_disc"},
   {"buurtcode":5991702,"neighborhood":"Dorp","value":0.06,"category":"cat_est_disc"},
   {"buurtcode":5992196,"neighborhood":"Eemhaven","value":"NA","category":"cat_est_disc"},
   {"buurtcode":5992307,"neighborhood":"Europoort","value":"NA","category":"cat_est_disc"},
   {"buurtcode":5991087,"neighborhood":"Feijenoord","value":0.23,"category":"cat_est_disc"},
   {"buurtcode":5991289,"neighborhood":"Groot IJsselmonde","value":0.15,"category":"cat_est_disc"},
   {"buurtcode":5991593,"neighborhood":"Heijplaat","value":0.14,"category":"cat_est_disc"},
   {"buurtcode":5991449,"neighborhood":"Het Lage Land","value":0.15,"category":"cat_est_disc"},
   {"buurtcode":5990662,"neighborhood":"Hillegersberg Noord","value":0.09,"category":"cat_est_disc"},
   {"buurtcode":5990661,"neighborhood":"Hillegersberg Zuid","value":0.09,"category":"cat_est_disc"},
   {"buurtcode":5991082,"neighborhood":"Hillesluis","value":0.23,"category":"cat_est_disc"},
   {"buurtcode":5991692,"neighborhood":"Hoogvliet Noord","value":0.16,"category":"cat_est_disc"},
   {"buurtcode":5991699,"neighborhood":"Hoogvliet Zuid","value":0.12,"category":"cat_est_disc"},
   {"buurtcode":5991085,"neighborhood":"Katendrecht","value":0.16,"category":"cat_est_disc"},
   {"buurtcode":5990451,"neighborhood":"Kleinpolder","value":0.16,"category":"cat_est_disc"},
   {"buurtcode":5991017,"neighborhood":"Kop van Zuid","value":0.12,"category":"cat_est_disc"},
   {"buurtcode":5991079,"neighborhood":"Kop van Zuid - Entrepot","value":0.17,"category":"cat_est_disc"},
   {"buurtcode":5990842,"neighborhood":"Kralingen Oost","value":0.08,"category":"cat_est_disc"},
   {"buurtcode":5990841,"neighborhood":"Kralingen West","value":0.15,"category":"cat_est_disc"},
   {"buurtcode":5990843,"neighborhood":"Kralingse Bos","value":"NA","category":"cat_est_disc"},
   {"buurtcode":5991446,"neighborhood":"Kralingseveer","value":0.09,"category":"cat_est_disc"},
   {"buurtcode":5990457,"neighborhood":"Landzicht","value":"NA","category":"cat_est_disc"},
   {"buurtcode":5990534,"neighborhood":"Liskwartier","value":0.14,"category":"cat_est_disc"},
   {"buurtcode":5991284,"neighborhood":"Lombardijen","value":0.18,"category":"cat_est_disc"},
   {"buurtcode":5990325,"neighborhood":"Middelland","value":0.17,"category":"cat_est_disc"},
   {"buurtcode":5990665,"neighborhood":"Molenlaankwartier","value":0.08,"category":"cat_est_disc"},
   {"buurtcode":5991468,"neighborhood":"Nesselande","value":0.09,"category":"cat_est_disc"},
   {"buurtcode":5990836,"neighborhood":"Nieuw Crooswijk","value":0.14,"category":"cat_est_disc"},
   {"buurtcode":5991926,"neighborhood":"Nieuw Mathenesse","value":"NA","category":"cat_est_disc"},
   {"buurtcode":5990118,"neighborhood":"Nieuwe Werk","value":0.1,"category":"cat_est_disc"},
   {"buurtcode":5990324,"neighborhood":"Nieuwe Westen","value":0.18,"category":"cat_est_disc"},
   {"buurtcode":5990452,"neighborhood":"Noord Kethel","value":"NA","category":"cat_est_disc"},
   {"buurtcode":5991088,"neighborhood":"Noordereiland","value":0.15,"category":"cat_est_disc"},
   {"buurtcode":5991463,"neighborhood":"Ommoord","value":0.11,"category":"cat_est_disc"},
   {"buurtcode":5991467,"neighborhood":"Oosterflank","value":0.16,"category":"cat_est_disc"},
   {"buurtcode":5991574,"neighborhood":"Oud Charlois","value":0.2,"category":"cat_est_disc"},
   {"buurtcode":5990837,"neighborhood":"Oud Crooswijk","value":0.19,"category":"cat_est_disc"},
   {"buurtcode":5991283,"neighborhood":"Oud IJsselmonde","value":0.13,"category":"cat_est_disc"},
   {"buurtcode":5990327,"neighborhood":"Oud Mathenesse","value":0.25,"category":"cat_est_disc"},
   {"buurtcode":5990535,"neighborhood":"Oude Noorden","value":0.18,"category":"cat_est_disc"},
   {"buurtcode":5990111,"neighborhood":"Oude Westen","value":0.19,"category":"cat_est_disc"},
   {"buurtcode":5990456,"neighborhood":"Overschie","value":0.1,"category":"cat_est_disc"},
   {"buurtcode":5991577,"neighborhood":"Pendrecht","value":0.22,"category":"cat_est_disc"},
   {"buurtcode":5991391,"neighborhood":"Pernis","value":0.07,"category":"cat_est_disc"},
   {"buurtcode":5991448,"neighborhood":"Prinsenland","value":0.12,"category":"cat_est_disc"},
   {"buurtcode":5990516,"neighborhood":"Provenierswijk","value":0.16,"category":"cat_est_disc"},
   {"buurtcode":5991703,"neighborhood":"Rijnpoort","value":"NA","category":"cat_est_disc"},
   {"buurtcode":5992704,"neighborhood":"Rozenburg","value":0.08,"category":"cat_est_disc"},
   {"buurtcode":5990814,"neighborhood":"Rubroek","value":0.18,"category":"cat_est_disc"},
   {"buurtcode":5990660,"neighborhood":"Schiebroek","value":0.12,"category":"cat_est_disc"},
   {"buurtcode":5990329,"neighborhood":"Schiemond","value":0.17,"category":"cat_est_disc"},
   {"buurtcode":5990454,"neighborhood":"Schieveen","value":"NA","category":"cat_est_disc"},
   {"buurtcode":5991853,"neighborhood":"Spaanse Polder","value":"NA","category":"cat_est_disc"},
   {"buurtcode":5990323,"neighborhood":"Spangen","value":0.21,"category":"cat_est_disc"},
   {"buurtcode":5990110,"neighborhood":"Stadsdriehoek","value":0.15,"category":"cat_est_disc"},
   {"buurtcode":5991701,"neighborhood":"Strand en Duin","value":0.05,"category":"cat_est_disc"},
   {"buurtcode":5990847,"neighborhood":"Struisenburg","value":0.16,"category":"cat_est_disc"},
   {"buurtcode":5991571,"neighborhood":"Tarwewijk","value":0.24,"category":"cat_est_disc"},
   {"buurtcode":5990664,"neighborhood":"Terbregge","value":0.09,"category":"cat_est_disc"},
   {"buurtcode":5990322,"neighborhood":"Tussendijken","value":0.23,"category":"cat_est_disc"},
   {"buurtcode":5991080,"neighborhood":"Vreewijk","value":0.16,"category":"cat_est_disc"},
   {"buurtcode":5992194,"neighborhood":"Waalhaven","value":"NA","category":"cat_est_disc"},
   {"buurtcode":5992198,"neighborhood":"Waalhaven Zuid","value":"NA","category":"cat_est_disc"},
   {"buurtcode":5991575,"neighborhood":"Wielewaal","value":0.17,"category":"cat_est_disc"},
   {"buurtcode":5990328,"neighborhood":"Witte Dorp","value":0.24,"category":"cat_est_disc"},
   {"buurtcode":5990455,"neighborhood":"Zestienhoven","value":0.07,"category":"cat_est_disc"},
   {"buurtcode":5991466,"neighborhood":"Zevenkamp","value":0.14,"category":"cat_est_disc"},
   {"buurtcode":5991578,"neighborhood":"Zuiderpark","value":0.13,"category":"cat_est_disc"},
   {"buurtcode":5991576,"neighborhood":"Zuidplein","value":0.2,"category":"cat_est_disc"},
   {"buurtcode":5991573,"neighborhood":"Zuidwijk","value":0.19,"category":"cat_est_disc"},

   {"buurtcode":5991444,"neighborhood":"s-Gravenland","value":40,"category":"cat_disc_index"},
   {"buurtcode":5991086,"neighborhood":"Afrikaanderwijk","value":160,"category":"cat_disc_index"},
   {"buurtcode":5990515,"neighborhood":"Agniesebuurt","value":140,"category":"cat_disc_index"},
   {"buurtcode":5992459,"neighborhood":"Bedrijvenpark Noord-West","value":"NA","category":"cat_disc_index"},
   {"buurtcode":5990531,"neighborhood":"Bergpolder","value":80,"category":"cat_disc_index"},
   {"buurtcode":5991290,"neighborhood":"Beverwaard","value":140,"category":"cat_disc_index"},
   {"buurtcode":5990532,"neighborhood":"Blijdorp","value":60,"category":"cat_disc_index"},
   {"buurtcode":5990558,"neighborhood":"Blijdorpsepolder","value":"NA","category":"cat_disc_index"},
   {"buurtcode":5991081,"neighborhood":"Bloemhof","value":160,"category":"cat_disc_index"},
   {"buurtcode":5990321,"neighborhood":"Bospolder","value":160,"category":"cat_disc_index"},
   {"buurtcode":5991572,"neighborhood":"Carnisse","value":180,"category":"cat_disc_index"},
   {"buurtcode":5991570,"neighborhood":"Charlois Zuidrand","value":60,"category":"cat_disc_index"},
   {"buurtcode":5990112,"neighborhood":"Cool","value":120,"category":"cat_disc_index"},
   {"buurtcode":5990113,"neighborhood":"Cs Kwartier","value":160,"category":"cat_disc_index"},
   {"buurtcode":5990845,"neighborhood":"De Esch","value":120,"category":"cat_disc_index"},
   {"buurtcode":5990320,"neighborhood":"Delfshaven","value":120,"category":"cat_disc_index"},
   {"buurtcode":5990119,"neighborhood":"Dijkzigt","value":80,"category":"cat_disc_index"},
   {"buurtcode":5991702,"neighborhood":"Dorp","value":20,"category":"cat_disc_index"},
   {"buurtcode":5992196,"neighborhood":"Eemhaven","value":"NA","category":"cat_disc_index"},
   {"buurtcode":5992307,"neighborhood":"Europoort","value":"NA","category":"cat_disc_index"},
   {"buurtcode":5991087,"neighborhood":"Feijenoord","value":160,"category":"cat_disc_index"},
   {"buurtcode":5991289,"neighborhood":"Groot IJsselmonde","value":80,"category":"cat_disc_index"},
   {"buurtcode":5991593,"neighborhood":"Heijplaat","value":80,"category":"cat_disc_index"},
   {"buurtcode":5991449,"neighborhood":"Het Lage Land","value":80,"category":"cat_disc_index"},
   {"buurtcode":5990662,"neighborhood":"Hillegersberg Noord","value":40,"category":"cat_disc_index"},
   {"buurtcode":5990661,"neighborhood":"Hillegersberg Zuid","value":40,"category":"cat_disc_index"},
   {"buurtcode":5991082,"neighborhood":"Hillesluis","value":160,"category":"cat_disc_index"},
   {"buurtcode":5991692,"neighborhood":"Hoogvliet Noord","value":120,"category":"cat_disc_index"},
   {"buurtcode":5991699,"neighborhood":"Hoogvliet Zuid","value":60,"category":"cat_disc_index"},
   {"buurtcode":5991085,"neighborhood":"Katendrecht","value":120,"category":"cat_disc_index"},
   {"buurtcode":5990451,"neighborhood":"Kleinpolder","value":120,"category":"cat_disc_index"},
   {"buurtcode":5991017,"neighborhood":"Kop van Zuid","value":60,"category":"cat_disc_index"},
   {"buurtcode":5991079,"neighborhood":"Kop van Zuid - Entrepot","value":120,"category":"cat_disc_index"},
   {"buurtcode":5990842,"neighborhood":"Kralingen Oost","value":40,"category":"cat_disc_index"},
   {"buurtcode":5990841,"neighborhood":"Kralingen West","value":100,"category":"cat_disc_index"},
   {"buurtcode":5990843,"neighborhood":"Kralingse Bos","value":"NA","category":"cat_disc_index"},
   {"buurtcode":5991446,"neighborhood":"Kralingseveer","value":40,"category":"cat_disc_index"},
   {"buurtcode":5990457,"neighborhood":"Landzicht","value":"NA","category":"cat_disc_index"},
   {"buurtcode":5990534,"neighborhood":"Liskwartier","value":80,"category":"cat_disc_index"},
   {"buurtcode":5991284,"neighborhood":"Lombardijen","value":140,"category":"cat_disc_index"},
   {"buurtcode":5990325,"neighborhood":"Middelland","value":120,"category":"cat_disc_index"},
   {"buurtcode":5990665,"neighborhood":"Molenlaankwartier","value":40,"category":"cat_disc_index"},
   {"buurtcode":5991468,"neighborhood":"Nesselande","value":40,"category":"cat_disc_index"},
   {"buurtcode":5990836,"neighborhood":"Nieuw Crooswijk","value":80,"category":"cat_disc_index"},
   {"buurtcode":5991926,"neighborhood":"Nieuw Mathenesse","value":"NA","category":"cat_disc_index"},
   {"buurtcode":5990118,"neighborhood":"Nieuwe Werk","value":40,"category":"cat_disc_index"},
   {"buurtcode":5990324,"neighborhood":"Nieuwe Westen","value":120,"category":"cat_disc_index"},
   {"buurtcode":5990452,"neighborhood":"Noord Kethel","value":"NA","category":"cat_disc_index"},
   {"buurtcode":5991088,"neighborhood":"Noordereiland","value":80,"category":"cat_disc_index"},
   {"buurtcode":5991463,"neighborhood":"Ommoord","value":60,"category":"cat_disc_index"},
   {"buurtcode":5991467,"neighborhood":"Oosterflank","value":120,"category":"cat_disc_index"},
   {"buurtcode":5991574,"neighborhood":"Oud Charlois","value":140,"category":"cat_disc_index"},
   {"buurtcode":5990837,"neighborhood":"Oud Crooswijk","value":140,"category":"cat_disc_index"},
   {"buurtcode":5991283,"neighborhood":"Oud IJsselmonde","value":80,"category":"cat_disc_index"},
   {"buurtcode":5990327,"neighborhood":"Oud Mathenesse","value":180,"category":"cat_disc_index"},
   {"buurtcode":5990535,"neighborhood":"Oude Noorden","value":140,"category":"cat_disc_index"},
   {"buurtcode":5990111,"neighborhood":"Oude Westen","value":140,"category":"cat_disc_index"},
   {"buurtcode":5990456,"neighborhood":"Overschie","value":40,"category":"cat_disc_index"},
   {"buurtcode":5991577,"neighborhood":"Pendrecht","value":160,"category":"cat_disc_index"},
   {"buurtcode":5991391,"neighborhood":"Pernis","value":20,"category":"cat_disc_index"},
   {"buurtcode":5991448,"neighborhood":"Prinsenland","value":60,"category":"cat_disc_index"},
   {"buurtcode":5990516,"neighborhood":"Provenierswijk","value":120,"category":"cat_disc_index"},
   {"buurtcode":5991703,"neighborhood":"Rijnpoort","value":"NA","category":"cat_disc_index"},
   {"buurtcode":5992704,"neighborhood":"Rozenburg","value":20,"category":"cat_disc_index"},
   {"buurtcode":5990814,"neighborhood":"Rubroek","value":120,"category":"cat_disc_index"},
   {"buurtcode":5990660,"neighborhood":"Schiebroek","value":60,"category":"cat_disc_index"},
   {"buurtcode":5990329,"neighborhood":"Schiemond","value":120,"category":"cat_disc_index"},
   {"buurtcode":5990454,"neighborhood":"Schieveen","value":"NA","category":"cat_disc_index"},
   {"buurtcode":5991853,"neighborhood":"Spaanse Polder","value":"NA","category":"cat_disc_index"},
   {"buurtcode":5990323,"neighborhood":"Spangen","value":160,"category":"cat_disc_index"},
   {"buurtcode":5990110,"neighborhood":"Stadsdriehoek","value":80,"category":"cat_disc_index"},
   {"buurtcode":5991701,"neighborhood":"Strand en Duin","value":0,"category":"cat_disc_index"},
   {"buurtcode":5990847,"neighborhood":"Struisenburg","value":120,"category":"cat_disc_index"},
   {"buurtcode":5991571,"neighborhood":"Tarwewijk","value":180,"category":"cat_disc_index"},
   {"buurtcode":5990664,"neighborhood":"Terbregge","value":40,"category":"cat_disc_index"},
   {"buurtcode":5990322,"neighborhood":"Tussendijken","value":180,"category":"cat_disc_index"},
   {"buurtcode":5991080,"neighborhood":"Vreewijk","value":120,"category":"cat_disc_index"},
   {"buurtcode":5992194,"neighborhood":"Waalhaven","value":"NA","category":"cat_disc_index"},
   {"buurtcode":5992198,"neighborhood":"Waalhaven Zuid","value":"NA","category":"cat_disc_index"},
   {"buurtcode":5991575,"neighborhood":"Wielewaal","value":120,"category":"cat_disc_index"},
   {"buurtcode":5990328,"neighborhood":"Witte Dorp","value":180,"category":"cat_disc_index"},
   {"buurtcode":5990455,"neighborhood":"Zestienhoven","value":20,"category":"cat_disc_index"},
   {"buurtcode":5991466,"neighborhood":"Zevenkamp","value":80,"category":"cat_disc_index"},
   {"buurtcode":5991578,"neighborhood":"Zuiderpark","value":60,"category":"cat_disc_index"},
   {"buurtcode":5991576,"neighborhood":"Zuidplein","value":140,"category":"cat_disc_index"},
   {"buurtcode":5991573,"neighborhood":"Zuidwijk","value":140,"category":"cat_disc_index"},


];