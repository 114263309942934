import React, { useContext } from "react";
import { URLStateContext } from "../URLStateContext";
import { further_reading_translations } from "./translations";

export const LearnMore = () => {
  const { language } = useContext(URLStateContext);

  return (
    <>

      <div className="full-banner slim" style={{ backgroundImage: "url(/banner_5_1.jpg)" }}>
        <div className="title">{further_reading_translations.banner_title[language]}</div>
      </div>

      <div className="introSection sections">
        <div className="introBlurb">
          {language === 'english' &&
            <>
              <p className="mb-10">Would you like to dive deeper into the themes of inequality, discrimination, and racism? On this page we help you on your way with some watching, listening, and reading recommendations.</p>
              <p className="mt-0">When compiling a recommended list to learn more about this subject, one important note should always be made beforehand, namely: a list like this is and will always be incomplete. We hope to help anyone who wants to learn more with the chosen suggestions and will continue adding to this list.</p>
            </>
          }
          {language === 'dutch' &&
            <>
              <p className="mb-10">Wil jij je graag meer verdiepen in ongelijkheid, discriminatie en racisme? Dan helpen we jou hier verder op weg met onze kijk, luister en leestips.</p>
              <p className="mt-0">Bij het samenstellen van een aanbevolen lijst om meer te leren over dit onderwerp moet altijd een belangrijke opmerking vooraf gemaakt worden, namelijk: een lijst als deze is en zal altijd onvolledig zijn. Wij hopen iedereen die zich verder wil verdiepen op weg te helpen met de gekozen suggesties en zullen deze lijst blijven aanvullen.</p>
            </>
          }
        </div>
      </div>

      <div className="introSection sections section-3">
        <div className="subtitle">
          {language === 'english' ? 'On inequality, discrimination, and racism in Rotterdam' : 'Over ongelijkheid, discriminatie en racisme in Rotterdam'}
        </div>
        <div className="introBlurb">
          {language === 'english' &&
            <>
              <p>You’re on the website of the Rotterdam Inclusivity Project. Let’s therefore start with some recommendations (in Dutch) to gain more insight into these themes as they apply in the city of Rotterdam.</p>
              <ul>
                <li>Rotterdam als superdiverse stad, stadswandelingen podcast met Malique Mohamud & Paul van de Laar: <a target="_blank" rel="noreferrer" href="https://www.eur.nl/over-de-eur/strategie-2024/van-strategie-naar-praktijk/erasmus-verbindt/stadswandelingen/s1e4-superdiversiteit">https://www.eur.nl/over-de-eur/strategie-2024/van-strategie-naar-praktijk/erasmus-verbindt/stadswandelingen/s1e4-superdiversiteit</a></li>
                <li>Studenten van de Hogeschool Rotterdam gaan maandelijks in gesprek met elkaar over inclusie, racisme en discriminatie in de podcastserie “Wat zeg jij nou”: <a target="_blank" rel="noreferrer" href="https://www.hogeschoolrotterdam.nl/go/platform-included/lezen/studentenverhalen/podcast-watzegjijnou/">https://www.hogeschoolrotterdam.nl/go/platform-included/lezen/studentenverhalen/podcast-watzegjijnou/</a></li>
                <li>Ook OpenRotterdam biedt een tal van relevante Podcasts aan, hier te vinden: <a target="_blank" rel="noreferrer" href="https://openrotterdam.nl/tag/discriminatie/">https://openrotterdam.nl/tag/discriminatie/</a></li>
                <li>Nooit Bewust Opgeslagen is een vierdelige podcastserie over het slavernijverleden van de stad Rotterdam: <a target="_blank" rel="noreferrer" href="https://www.erasmusmagazine.nl/specials/nooit-bewust-opgeslagen-de-podcast-over-het-rotterdamse-slavernijverleden/">https://www.erasmusmagazine.nl/specials/nooit-bewust-opgeslagen-de-podcast-over-het-rotterdamse-slavernijverleden/</a></li>
              </ul>
            </>
          }
          {language === 'dutch' &&
            <>
              <p>Je bevind je op de website van inclusief010. Laten we dan ook beginnen met tips die meer inzicht bieden in dit thema binnen de stad Rotterdam.</p>
              <ul>
                <li>Rotterdam als superdiverse stad, stadswandelingen podcast met Malique Mohamud & Paul van de Laar: <a target="_blank" rel="noreferrer" href="https://www.eur.nl/over-de-eur/strategie-2024/van-strategie-naar-praktijk/erasmus-verbindt/stadswandelingen/s1e4-superdiversiteit">https://www.eur.nl/over-de-eur/strategie-2024/van-strategie-naar-praktijk/erasmus-verbindt/stadswandelingen/s1e4-superdiversiteit</a></li>
                <li>Studenten van de Hogeschool Rotterdam gaan maandelijks in gesprek met elkaar over inclusie, racisme en discriminatie in de podcastserie “Wat zeg jij nou”: <a target="_blank" rel="noreferrer" href="https://www.hogeschoolrotterdam.nl/go/platform-included/lezen/studentenverhalen/podcast-watzegjijnou/">https://www.hogeschoolrotterdam.nl/go/platform-included/lezen/studentenverhalen/podcast-watzegjijnou/</a></li>
                <li>Ook OpenRotterdam biedt een tal van relevante Podcasts aan, hier te vinden: <a target="_blank" rel="noreferrer" href="https://openrotterdam.nl/tag/discriminatie/">https://openrotterdam.nl/tag/discriminatie/</a></li>
                <li>Nooit Bewust Opgeslagen is een vierdelige podcastserie over het slavernijverleden van de stad Rotterdam: <a target="_blank" rel="noreferrer" href="https://www.erasmusmagazine.nl/specials/nooit-bewust-opgeslagen-de-podcast-over-het-rotterdamse-slavernijverleden/">https://www.erasmusmagazine.nl/specials/nooit-bewust-opgeslagen-de-podcast-over-het-rotterdamse-slavernijverleden/</a></li>
              </ul>
            </>
          }
        </div>
      </div>

      {language === 'english' &&
        <>
          <div className="introSection sections section-3">
            <div className="title">To watch</div>
            <div className="introBlurb">
              <p>Prefer to watch something about these themes? See our tips below:</p>
              <div className="subtitle">On YouTube</div>
              <ul>
                <li>Killing Race, Ending Racism by bell hooks <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=TOl_cSLX1Mw">https://www.youtube.com/watch?v=TOl_cSLX1Mw</a></li>
                <li>There is No Hierarchy of Oppressions, by Audre Lorde <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=i1pNsLsHsfs">https://www.youtube.com/watch?v=i1pNsLsHsfs</a> </li>
                <li>On Colorblindness, Angela Davis <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=M-SfOJGd1wo">https://www.youtube.com/watch?v=M-SfOJGd1wo</a> </li>
                <li>Still I rise, Maya Angelou <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=qviM_GnJbOM">https://www.youtube.com/watch?v=qviM_GnJbOM</a></li>
                <li>The importance of Intersectionality, Kimberle Crenshaw (TedTalk) <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=akOe5-UsQ2o">https://www.youtube.com/watch?v=akOe5-UsQ2o</a></li>
                <li>I am not Your Negro, James Baldwin (zie de complete film op Netflix) <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=3y6xwH88kpg">https://www.youtube.com/watch?v=3y6xwH88kpg</a></li>
              </ul>
              <div className="subtitle">Films</div>
              <ul>
                <li>Fruitvale Station <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt2334649/">https://www.imdb.com/title/tt2334649/</a></li>
                <li>Malcom X <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=sx4sEvhYeVE">https://www.youtube.com/watch?v=sx4sEvhYeVE</a></li>
                <li>I am not your Negro <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=Fg1r3L9WZrQ">https://www.youtube.com/watch?v=Fg1r3L9WZrQ</a></li>
                <li>American Son, There’s Been An Incident <a target="_blank" rel="noreferrer" href="https://www.imdb.com/video/vi872136473/?playlistId=tt9664078&ref_=tt_ov_vi">https://www.imdb.com/video/vi872136473/?playlistId=tt9664078&ref_=tt_ov_vi</a></li>
                <li>Belle <a target="_blank" rel="noreferrer" href="https://www.imdb.com/video/vi2062854169/?playlistId=tt2404181&ref_=tt_ov_vi">https://www.imdb.com/video/vi2062854169/?playlistId=tt2404181&ref_=tt_ov_vi</a></li>
                <li>Dark Girls <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt1966396/?ref_=fn_al_tt_1">https://www.imdb.com/title/tt1966396/?ref_=fn_al_tt_1</a></li>
                <li>Good Bad Girl (NPO, Nederlandse film) <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=FF2Wl_MenuU">https://www.youtube.com/watch?v=FF2Wl_MenuU</a></li>
                <li>Get Out <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt5052448/">https://www.imdb.com/title/tt5052448/</a></li>
                <li>Just Mercy <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt4916630/">https://www.imdb.com/title/tt4916630/</a></li>
                <li>The Hate U Give <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=3MM8OkVT0hw">https://www.youtube.com/watch?v=3MM8OkVT0hw</a></li>
                <li>Hoe duur was de suiker? <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=IEhbgSiT-Fo">https://www.youtube.com/watch?v=IEhbgSiT-Fo</a></li>
                <li>Aan allen die zich hier bevinden, van Dean Bowen (in premiere tijdens Keti Koti, 2020) <a target="_blank" rel="noreferrer" href="https://afromagazine.nl/nieuws/dean-bowen-maakt-kritische-film-over-nederlands-koloniaal-verleden-aan-allen-die-zich-hier">https://afromagazine.nl/nieuws/dean-bowen-maakt-kritische-film-over-nederlands-koloniaal-verleden-aan-allen-die-zich-hier</a></li>
              </ul>
              <p>For more films (in Dutch), check out: <a target="_blank" rel="noreferrer" href="https://www.npostart.nl/speellijst/films-over-racisme-discriminatie-en-uitsluiting">https://www.npostart.nl/speellijst/films-over-racisme-discriminatie-en-uitsluiting</a></p>
              <div className="subtitle">Series</div>
              <ul>
                <li>Dear White People (Netflix) <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt5707802/">https://www.imdb.com/title/tt5707802/</a></li>
                <li>When They See Us (Netflix) <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt7137906/">https://www.imdb.com/title/tt7137906/</a></li>
                <li>Time: The Kalief Browder Story (Netflix) <a target="_blank" rel="noreferrer" href="https://www.netflix.com/nl/title/80187052">https://www.netflix.com/nl/title/80187052</a></li>
                <li>Murder to Mercy: The Cyntoia Brown Story <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=eb2Ce6mj-iI">https://www.youtube.com/watch?v=eb2Ce6mj-iI</a></li>
              </ul>
              <div className="subtitle">Documentaries</div>
              <ul>
                <li>13th: From Slave To Criminal With One Amendment (Netflix) <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=krfcq5pF8u8&t=3s">https://www.youtube.com/watch?v=krfcq5pF8u8&t=3s</a></li>
                <li>Dark Girls <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt1966396/?ref_=fn_al_tt_1">https://www.imdb.com/title/tt1966396/?ref_=fn_al_tt_1</a></li>
                <li>Explained Racial Wealth Gap (Netflix) <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=Mqrhn8khGLM">https://www.youtube.com/watch?v=Mqrhn8khGLM</a></li>
                <li>De blauwe familie (NPO) <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=IQV7CdIkh5Y">https://www.youtube.com/watch?v=IQV7CdIkh5Y</a></li>
                <li>Hello privilege, it’s me, Chelsea (Netflix docu/caberet) <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=tPsLcrVlwt4">https://www.youtube.com/watch?v=tPsLcrVlwt4</a></li>
                <li>The death and life of Marsha P Johnson (Netflix) <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt5233558/?ref_=fn_al_tt_1">https://www.imdb.com/title/tt5233558/?ref_=fn_al_tt_1</a></li>
                <li>Hanky Panky Goodbye (NPO) <a target="_blank" rel="noreferrer" href="https://www.npo3.nl/hanky-panky-goodbye/02-11-2022/KN_1730256">https://www.npo3.nl/hanky-panky-goodbye/02-11-2022/KN_1730256</a></li>
                <li>Wij slaven van Suriname, Anton de Kom (NPO) <a target="_blank" rel="noreferrer" href="https://www.ntr.nl/NTR-Documentaires/137/detail/Wij-slaven-van-Suriname-Anton-de-Kom/VPWON_1322318">https://www.ntr.nl/NTR-Documentaires/137/detail/Wij-slaven-van-Suriname-Anton-de-Kom/VPWON_1322318</a></li>
              </ul>
              <p>For more (in Dutch) documentaries, check out: <a target="_blank" rel="noreferrer" href="https://www.npostart.nl/speellijst/documentaires-racisme-discriminatie-en-uitsluiting">https://www.npostart.nl/speellijst/documentaires-racisme-discriminatie-en-uitsluiting</a></p>
            </div>
          </div>
          <div className="introSection sections section-3">
            <div className="title">To listen</div>
            <div className="introBlurb">
              <p>Prefer listening? Here are some of our tips:</p>
              <div className="subtitle">Music</div>
              <p>Music (and dance) are forms in which experiences can be shared and stories told. Did you know that slaves (especially in the Caribbean) often passed on stories through generations about their own countries, families, working conditions, pain, dreams, and hope through song and dance? Talking was often too dangerous. To this day, music offers and alternative for people who are not given a place in (public) debates, or who cannot express themselves otherwise. In addition, music can be an outlet for feelings such as pain, frustration, fear, and despair about sitautions. It can express hope, offer support and can be a form of protest. It is therefore educational and a means of gaining more insight. Since the death of George Floyd—regarded as the start of the most recent antiracism movement—various tracks have returned to the top of charts worldwide, a selection of which can be found below:</p>
              <p>&nbsp;</p>
              <ul>
                <li>Lil Baby: The bigger picture <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=_VDGysJGNoI">https://www.youtube.com/watch?v=_VDGysJGNoI</a></li>
                <li>H.E.R.: I Can’t Breathe <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=E-1Bf_XWaPE">https://www.youtube.com/watch?v=E-1Bf_XWaPE</a></li>
                <li>KRS One: Sound Of Da Police <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=9ZrAYxWPN6c">https://www.youtube.com/watch?v=9ZrAYxWPN6c</a></li>
                <li>Beyonce:  Black Parade <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=EJT1m1ele00">https://www.youtube.com/watch?v=EJT1m1ele00</a></li>
                <li>Meek Mill: Otherside of America <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=JUnc3kl0DcA">https://www.youtube.com/watch?v=JUnc3kl0DcA</a></li>
                <li>Labi Siffre: Something Inside So Strong <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=7B-4Lsrx8IA">https://www.youtube.com/watch?v=7B-4Lsrx8IA</a></li>
                <li>Rage against the Machine: Maria <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=-6qDCaeAlfM">https://www.youtube.com/watch?v=-6qDCaeAlfM</a></li>
                <li>Micheal Kiwanuka: Black Man in A White World <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=-TYlcVNI2AM">https://www.youtube.com/watch?v=-TYlcVNI2AM</a></li>
                <li>Trey Songz: 2020 Riots: How Many Times <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=DWZkjXCU-ds">https://www.youtube.com/watch?v=DWZkjXCU-ds</a></li>
                <li>Usher: I Cry <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=ulqyG0DbAGA">https://www.youtube.com/watch?v=ulqyG0DbAGA</a></li>
                <li>Bizzey & Akwasi: Geen Wedstrijd <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=oM8sArXxKEY">https://www.youtube.com/watch?v=oM8sArXxKEY</a></li>
                <li>Fresku: Donkere gedachtes <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=L6BR0QW4WXs">https://www.youtube.com/watch?v=L6BR0QW4WXs</a></li>
                <li>Lauryn Hill: Forgive them Father <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=RTQL42onK08">https://www.youtube.com/watch?v=RTQL42onK08</a></li>
                <li>Kendrick Lamar: Alright <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=Z-48u_uWMHY">https://www.youtube.com/watch?v=Z-48u_uWMHY</a></li>
                <li>John Legend & The Roots: Wake Up <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=iJgxJ6JrPkc">https://www.youtube.com/watch?v=iJgxJ6JrPkc</a></li>
                <li>Marvin Gaye: What’s Going On <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=o5TmORitlKk">https://www.youtube.com/watch?v=o5TmORitlKk</a></li>
                <li>James Brown: Say It Loud (I’m Black And I’m Proud <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=4hj1iWqoYEc">https://www.youtube.com/watch?v=4hj1iWqoYEc</a></li>
                <li>Nina Simone: To Be Young, Gifted and Black <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=_hdVFiANBTk">https://www.youtube.com/watch?v=_hdVFiANBTk</a></li>
                <li>The Red Hot Chili Peppers: The Power Of Equality <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=V2bh1feOjZk">https://www.youtube.com/watch?v=V2bh1feOjZk</a></li>
                <li>Koffee: Pressure <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=JLcdjysXPgs">https://www.youtube.com/watch?v=JLcdjysXPgs</a></li>
                <li>Ray Fuego: Zwart Lives Matter <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=A-_bqh-13RE">https://www.youtube.com/watch?v=A-_bqh-13RE</a></li>
                <li>Zwart licht: Schoon Schip <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=YyrJ6Ju3jqo & Zwarte Hollanders https://www.youtube.com/watch?v=9MfzJR9ClE4">https://www.youtube.com/watch?v=YyrJ6Ju3jqo & Zwarte Hollanders https://www.youtube.com/watch?v=9MfzJR9ClE4</a></li>
                <li>Bob Dylan: The Ballad of Emmet Till <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=4V6ffUUEvaM">https://www.youtube.com/watch?v=4V6ffUUEvaM</a></li>
                <li>Bob Marley & The Wailers: Buffalo Soldier <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=uMUQMSXLlHM">https://www.youtube.com/watch?v=uMUQMSXLlHM</a></li>
                <li>Stevie Wonder: Love’s in need of Love Toaday <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=014ayxah1Qo">https://www.youtube.com/watch?v=014ayxah1Qo</a></li>
              </ul>
              <div className="subtitle">Podcast</div>
              <ul>
                <li>Rotterdam als superdiverse stad, stadswandelingen podcast met Malique Mohamud & Paul van de Laar: <a target="_blank" rel="noreferrer" href="https://www.eur.nl/over-de-eur/strategie-2024/van-strategie-naar-praktijk/erasmus-verbindt/stadswandelingen/s1e4-superdiversiteit">https://www.eur.nl/over-de-eur/strategie-2024/van-strategie-naar-praktijk/erasmus-verbindt/stadswandelingen/s1e4-superdiversiteit</a></li>
                <li>Studenten van de Hogeschool Rotterdam gaan maandelijks in gesprek met elkaar over inclusie, racisme en discriminatie in de podcastserie “Wat zeg jij nou”: <a target="_blank" rel="noreferrer" href="https://www.hogeschoolrotterdam.nl/go/platform-included/lezen/studentenverhalen/podcast-watzegjijnou/">https://www.hogeschoolrotterdam.nl/go/platform-included/lezen/studentenverhalen/podcast-watzegjijnou/</a></li>
                <li>Ook OpenRotterdam biedt een tal van relevante Podcasts aan, hier te vinden: <a target="_blank" rel="noreferrer" href="https://openrotterdam.nl/tag/discriminatie/">https://openrotterdam.nl/tag/discriminatie/</a></li>
                <li>Nooit Bewust Opgeslagen is een vierdelige podcastserie over het slavernijverleden van de stad Rotterdam: <a target="_blank" rel="noreferrer" href="https://www.erasmusmagazine.nl/specials/nooit-bewust-opgeslagen-de-podcast-over-het-rotterdamse-slavernijverleden/">https://www.erasmusmagazine.nl/specials/nooit-bewust-opgeslagen-de-podcast-over-het-rotterdamse-slavernijverleden/</a></li>
                <li>Prodemos Podcast <a target="_blank" rel="noreferrer" href="https://prodemos.nl/leren/podcast-aflevering-7-seyda-buurman-kutsal-over-racisme-en-discriminatie/">https://prodemos.nl/leren/podcast-aflevering-7-seyda-buurman-kutsal-over-racisme-en-discriminatie/</a></li>
                <li>Onbewust Racisme <a target="_blank" rel="noreferrer" href="https://www.npo3fm.nl/podcasts/wat-je-niet-leert/39865/special-onbewust-racisme">https://www.npo3fm.nl/podcasts/wat-je-niet-leert/39865/special-onbewust-racisme</a></li>
                <li>Boos #1-Racisme, hoe gaan we het tegen? <a target="_blank" rel="noreferrer" href="https://open.spotify.com/episode/7CRdMMAPDb8KkUcx0ounyp">https://open.spotify.com/episode/7CRdMMAPDb8KkUcx0ounyp</a></li>
                <li>Racisme en religie staan centraal in de podcast genaamd “De Ongelooflijke Podcast”, waarin David Boogerd in gesprek gaat over deze relatie: <a target="_blank" rel="noreferrer" href="https://podcasts.apple.com/kw/podcast/racisme-en-religie-met-talitha-muusse-don-ceder-en/id1458702219?i=1000479695996">https://podcasts.apple.com/kw/podcast/racisme-en-religie-met-talitha-muusse-don-ceder-en/id1458702219?i=1000479695996</a></li>
                <li>Taal en racisme <a target="_blank" rel="noreferrer" href="https://soundcloud.com/de-correspondent/lex-bohlmeijer-in-gesprek-met-simone-zeefuik">https://soundcloud.com/de-correspondent/lex-bohlmeijer-in-gesprek-met-simone-zeefuik</a></li>
                <li>De plantage van onze voorouders <a target="_blank" rel="noreferrer" href="https://www.vpro.nl/speel~WO_VPRO_16109898~trailer-de-plantage-van-onze-voorouders~.html">https://www.vpro.nl/speel~WO_VPRO_16109898~trailer-de-plantage-van-onze-voorouders~.html</a></li>
                <li>Fufu en dadels  <a target="_blank" rel="noreferrer" href="https://podcastluisteren.nl/pod/Fufu-and-Dadels#/">https://podcastluisteren.nl/pod/Fufu-and-Dadels#/</a></li>
                <li>Rachida Lamrabet <a target="_blank" rel="noreferrer" href="https://demos.be/blog/podcast-rachida-lamrabet-over-machtsmisbruik-genderverschillen-en-racisme">https://demos.be/blog/podcast-rachida-lamrabet-over-machtsmisbruik-genderverschillen-en-racisme</a></li>
              </ul>
              <p>The podcasts listed below are available in Dutch. For English-language podcasts, check out this list: <a target="_blank" rel="noreferrer" href="https://projects.iq.harvard.edu/antiracismresources/allies/podcasts">https://projects.iq.harvard.edu/antiracismresources/allies/podcasts</a></p>
            </div>
          </div>
        </>
      }

      {language === 'dutch' &&
        <>
          <div className="introSection sections section-3">
            <div className="title">Kijken</div>
            <div className="introBlurb">
              <p>Liever kijken, zie onderstaande tips:</p>
              <div className="subtitle">Wat te kijken op YouTube</div>
              <ul>
                <li>Killing Race, Ending Racism by bell hooks <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=TOl_cSLX1Mw">https://www.youtube.com/watch?v=TOl_cSLX1Mw</a></li>
                <li>There is No Hierarchy of Oppressions, by Audre Lorde <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=i1pNsLsHsfs">https://www.youtube.com/watch?v=i1pNsLsHsfs</a> </li>
                <li>On Colorblindness, Angela Davis <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=M-SfOJGd1wo">https://www.youtube.com/watch?v=M-SfOJGd1wo</a> </li>
                <li>Still I rise, Maya Angelou <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=qviM_GnJbOM">https://www.youtube.com/watch?v=qviM_GnJbOM</a></li>
                <li>The importance of Intersectionality, Kimberle Crenshaw (TedTalk) <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=akOe5-UsQ2o">https://www.youtube.com/watch?v=akOe5-UsQ2o</a></li>
                <li>I am not Your Negro, James Baldwin (zie de complete film op Netflix) <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=3y6xwH88kpg">https://www.youtube.com/watch?v=3y6xwH88kpg</a></li>
              </ul>
              <div className="subtitle">Films</div>
              <ul>
                <li>Fruitvale Station <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt2334649/">https://www.imdb.com/title/tt2334649/</a></li>
                <li>Malcom X <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=sx4sEvhYeVE">https://www.youtube.com/watch?v=sx4sEvhYeVE</a></li>
                <li>I am not your Negro <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=Fg1r3L9WZrQ">https://www.youtube.com/watch?v=Fg1r3L9WZrQ</a></li>
                <li>American Son, There’s Been An Incident <a target="_blank" rel="noreferrer" href="https://www.imdb.com/video/vi872136473/?playlistId=tt9664078&ref_=tt_ov_vi">https://www.imdb.com/video/vi872136473/?playlistId=tt9664078&ref_=tt_ov_vi</a></li>
                <li>Belle <a target="_blank" rel="noreferrer" href="https://www.imdb.com/video/vi2062854169/?playlistId=tt2404181&ref_=tt_ov_vi">https://www.imdb.com/video/vi2062854169/?playlistId=tt2404181&ref_=tt_ov_vi</a></li>
                <li>Dark Girls <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt1966396/?ref_=fn_al_tt_1">https://www.imdb.com/title/tt1966396/?ref_=fn_al_tt_1</a></li>
                <li>Good Bad Girl (NPO, Nederlandse film) <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=FF2Wl_MenuU">https://www.youtube.com/watch?v=FF2Wl_MenuU</a></li>
                <li>Get Out <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt5052448/">https://www.imdb.com/title/tt5052448/</a></li>
                <li>Just Mercy <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt4916630/">https://www.imdb.com/title/tt4916630/</a></li>
                <li>The Hate U Give <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=3MM8OkVT0hw">https://www.youtube.com/watch?v=3MM8OkVT0hw</a></li>
                <li>Hoe duur was de suiker? <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=IEhbgSiT-Fo">https://www.youtube.com/watch?v=IEhbgSiT-Fo</a></li>
                <li>Aan allen die zich hier bevinden, van Dean Bowen (in premiere tijdens Keti Koti, 2020) <a target="_blank" rel="noreferrer" href="https://afromagazine.nl/nieuws/dean-bowen-maakt-kritische-film-over-nederlands-koloniaal-verleden-aan-allen-die-zich-hier">https://afromagazine.nl/nieuws/dean-bowen-maakt-kritische-film-over-nederlands-koloniaal-verleden-aan-allen-die-zich-hier</a></li>
              </ul>
              <p>Voor meer (Nederlandse) films raadpleeg: <a target="_blank" rel="noreferrer" href="https://www.npostart.nl/speellijst/films-over-racisme-discriminatie-en-uitsluiting">https://www.npostart.nl/speellijst/films-over-racisme-discriminatie-en-uitsluiting</a></p>
              <div className="subtitle">Series</div>
              <ul>
                <li>Dear White People (Netflix) <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt5707802/">https://www.imdb.com/title/tt5707802/</a></li>
                <li>When They See Us (Netflix) <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt7137906/">https://www.imdb.com/title/tt7137906/</a></li>
                <li>Time: The Kalief Browder Story (Netflix) <a target="_blank" rel="noreferrer" href="https://www.netflix.com/nl/title/80187052">https://www.netflix.com/nl/title/80187052</a></li>
                <li>Murder to Mercy: The Cyntoia Brown Story <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=eb2Ce6mj-iI">https://www.youtube.com/watch?v=eb2Ce6mj-iI</a></li>
              </ul>
              <div className="subtitle">Documentaries</div>
              <ul>
                <li>13th: From Slave To Criminal With One Amendment (Netflix) <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=krfcq5pF8u8&t=3s">https://www.youtube.com/watch?v=krfcq5pF8u8&t=3s</a></li>
                <li>Dark Girls <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt1966396/?ref_=fn_al_tt_1">https://www.imdb.com/title/tt1966396/?ref_=fn_al_tt_1</a></li>
                <li>Explained Racial Wealth Gap (Netflix) <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=Mqrhn8khGLM">https://www.youtube.com/watch?v=Mqrhn8khGLM</a></li>
                <li>De blauwe familie (NPO) <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=IQV7CdIkh5Y">https://www.youtube.com/watch?v=IQV7CdIkh5Y</a></li>
                <li>Hello privilege, it’s me, Chelsea (Netflix docu/caberet) <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=tPsLcrVlwt4">https://www.youtube.com/watch?v=tPsLcrVlwt4</a></li>
                <li>The death and life of Marsha P Johnson (Netflix) <a target="_blank" rel="noreferrer" href="https://www.imdb.com/title/tt5233558/?ref_=fn_al_tt_1">https://www.imdb.com/title/tt5233558/?ref_=fn_al_tt_1</a></li>
                <li>Hanky Panky Goodbye (NPO) <a target="_blank" rel="noreferrer" href="https://www.npo3.nl/hanky-panky-goodbye/02-11-2022/KN_1730256">https://www.npo3.nl/hanky-panky-goodbye/02-11-2022/KN_1730256</a></li>
                <li>Wij slaven van Suriname, Anton de Kom (NPO) <a target="_blank" rel="noreferrer" href="https://www.ntr.nl/NTR-Documentaires/137/detail/Wij-slaven-van-Suriname-Anton-de-Kom/VPWON_1322318">https://www.ntr.nl/NTR-Documentaires/137/detail/Wij-slaven-van-Suriname-Anton-de-Kom/VPWON_1322318</a></li>
              </ul>
              <p>Voor meer (Nederlandse) documentaires bezoek <a target="_blank" rel="noreferrer" href="https://www.npostart.nl/speellijst/documentaires-racisme-discriminatie-en-uitsluiting">https://www.npostart.nl/speellijst/documentaires-racisme-discriminatie-en-uitsluiting</a></p>
            </div>
          </div>

          <div className="introSection sections section-3">
            <div className="title">Luisteren</div>
            <div className="introBlurb">
              <p>Liever luisteren? Zie onderstaande tips:</p>
              <div className="subtitle">Muziek</div>
              <p>Ook muziek (en dans) is een vorm waarin ervaringen gedeeld kunnen worden, geschiedenissen verteld worden. Wist je bijvoorbeeld dat slaven (vooral in het Caribische gebied) vaak middels zang en dans verhalen van eigen land, familie, werkomstandigheden, pijn, dromen en hoop doorgaven aan hun omgeving, en dan met name aan hun kinderen en de volgende generaties? Praten was vaak te gevaarlijk. Tot op de dag van vandaag biedt muziek een alternatief voor mensen die in het (publieke) debat geen plaats krijgen, of zich via muziek kunnen uitten. Daarnaast kan muziek een uitlaatklep zijn voor gevoelens als pijn, frustratie, angst en wanhoop over situaties of juist het uitten van hoop en het bieden van steun, een vorm van protest. Leerzaam dus en mogelijk jouw manier om tot meer inzicht te komen. Sinds de dood van George Floyd, aangemerkt als begin van de meest recente antiracisme beweging, zijn dan ook diverse tracks wereldwijd weer bovenaan de hitlijsten te vinden.</p>
              <p>&nbsp;</p>
              <p>Een greep van wat er nu voornamelijk wordt geluisterd:</p>
              <ul>
                <li>Lil Baby: The bigger picture <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=_VDGysJGNoI">https://www.youtube.com/watch?v=_VDGysJGNoI</a></li>
                <li>H.E.R.: I Can’t Breathe <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=E-1Bf_XWaPE">https://www.youtube.com/watch?v=E-1Bf_XWaPE</a></li>
                <li>KRS One: Sound Of Da Police <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=9ZrAYxWPN6c">https://www.youtube.com/watch?v=9ZrAYxWPN6c</a></li>
                <li>Beyonce:  Black Parade <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=EJT1m1ele00">https://www.youtube.com/watch?v=EJT1m1ele00</a></li>
                <li>Meek Mill: Otherside of America <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=JUnc3kl0DcA">https://www.youtube.com/watch?v=JUnc3kl0DcA</a></li>
                <li>Labi Siffre: Something Inside So Strong <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=7B-4Lsrx8IA">https://www.youtube.com/watch?v=7B-4Lsrx8IA</a></li>
                <li>Rage against the Machine: Maria <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=-6qDCaeAlfM">https://www.youtube.com/watch?v=-6qDCaeAlfM</a></li>
                <li>Micheal Kiwanuka: Black Man in A White World <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=-TYlcVNI2AM">https://www.youtube.com/watch?v=-TYlcVNI2AM</a></li>
                <li>Trey Songz: 2020 Riots: How Many Times <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=DWZkjXCU-ds">https://www.youtube.com/watch?v=DWZkjXCU-ds</a></li>
                <li>Usher: I Cry <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=ulqyG0DbAGA">https://www.youtube.com/watch?v=ulqyG0DbAGA</a></li>
                <li>Bizzey & Akwasi: Geen Wedstrijd <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=oM8sArXxKEY">https://www.youtube.com/watch?v=oM8sArXxKEY</a></li>
                <li>Fresku: Donkere gedachtes <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=L6BR0QW4WXs">https://www.youtube.com/watch?v=L6BR0QW4WXs</a></li>
                <li>Lauryn Hill: Forgive them Father <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=RTQL42onK08">https://www.youtube.com/watch?v=RTQL42onK08</a></li>
                <li>Kendrick Lamar: Alright <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=Z-48u_uWMHY">https://www.youtube.com/watch?v=Z-48u_uWMHY</a></li>
                <li>John Legend & The Roots: Wake Up <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=iJgxJ6JrPkc">https://www.youtube.com/watch?v=iJgxJ6JrPkc</a></li>
                <li>Marvin Gaye: What’s Going On <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=o5TmORitlKk">https://www.youtube.com/watch?v=o5TmORitlKk</a></li>
                <li>James Brown: Say It Loud (I’m Black And I’m Proud <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=4hj1iWqoYEc">https://www.youtube.com/watch?v=4hj1iWqoYEc</a></li>
                <li>Nina Simone: To Be Young, Gifted and Black <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=_hdVFiANBTk">https://www.youtube.com/watch?v=_hdVFiANBTk</a></li>
                <li>The Red Hot Chili Peppers: The Power Of Equality <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=V2bh1feOjZk">https://www.youtube.com/watch?v=V2bh1feOjZk</a></li>
                <li>Koffee: Pressure <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=JLcdjysXPgs">https://www.youtube.com/watch?v=JLcdjysXPgs</a></li>
                <li>Ray Fuego: Zwart Lives Matter <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=A-_bqh-13RE">https://www.youtube.com/watch?v=A-_bqh-13RE</a></li>
                <li>Zwart licht: Schoon Schip <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=YyrJ6Ju3jqo & Zwarte Hollanders https://www.youtube.com/watch?v=9MfzJR9ClE4">https://www.youtube.com/watch?v=YyrJ6Ju3jqo & Zwarte Hollanders https://www.youtube.com/watch?v=9MfzJR9ClE4</a></li>
                <li>Bob Dylan: The Ballad of Emmet Till <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=4V6ffUUEvaM">https://www.youtube.com/watch?v=4V6ffUUEvaM</a></li>
                <li>Bob Marley & The Wailers: Buffalo Soldier <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=uMUQMSXLlHM">https://www.youtube.com/watch?v=uMUQMSXLlHM</a></li>
                <li>Stevie Wonder: Love’s in need of Love Toaday <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=014ayxah1Qo">https://www.youtube.com/watch?v=014ayxah1Qo</a></li>
              </ul>
              <div className="subtitle">Podcast</div>
              <ul>
                <li>Rotterdam als superdiverse stad, stadswandelingen podcast met Malique Mohamud & Paul van de Laar: <a target="_blank" rel="noreferrer" href="https://www.eur.nl/over-de-eur/strategie-2024/van-strategie-naar-praktijk/erasmus-verbindt/stadswandelingen/s1e4-superdiversiteit">https://www.eur.nl/over-de-eur/strategie-2024/van-strategie-naar-praktijk/erasmus-verbindt/stadswandelingen/s1e4-superdiversiteit</a></li>
                <li>Studenten van de Hogeschool Rotterdam gaan maandelijks in gesprek met elkaar over inclusie, racisme en discriminatie in de podcastserie “Wat zeg jij nou”: <a target="_blank" rel="noreferrer" href="https://www.hogeschoolrotterdam.nl/go/platform-included/lezen/studentenverhalen/podcast-watzegjijnou/">https://www.hogeschoolrotterdam.nl/go/platform-included/lezen/studentenverhalen/podcast-watzegjijnou/</a></li>
                <li>Ook OpenRotterdam biedt een tal van relevante Podcasts aan, hier te vinden: <a target="_blank" rel="noreferrer" href="https://openrotterdam.nl/tag/discriminatie/">https://openrotterdam.nl/tag/discriminatie/</a></li>
                <li>Nooit Bewust Opgeslagen is een vierdelige podcastserie over het slavernijverleden van de stad Rotterdam: <a target="_blank" rel="noreferrer" href="https://www.erasmusmagazine.nl/specials/nooit-bewust-opgeslagen-de-podcast-over-het-rotterdamse-slavernijverleden/">https://www.erasmusmagazine.nl/specials/nooit-bewust-opgeslagen-de-podcast-over-het-rotterdamse-slavernijverleden/</a></li>
                <li>Prodemos Podcast <a target="_blank" rel="noreferrer" href="https://prodemos.nl/leren/podcast-aflevering-7-seyda-buurman-kutsal-over-racisme-en-discriminatie/">https://prodemos.nl/leren/podcast-aflevering-7-seyda-buurman-kutsal-over-racisme-en-discriminatie/</a></li>
                <li>Onbewust Racisme <a target="_blank" rel="noreferrer" href="https://www.npo3fm.nl/podcasts/wat-je-niet-leert/39865/special-onbewust-racisme">https://www.npo3fm.nl/podcasts/wat-je-niet-leert/39865/special-onbewust-racisme</a></li>
                <li>Boos #1-Racisme, hoe gaan we het tegen? <a target="_blank" rel="noreferrer" href="https://open.spotify.com/episode/7CRdMMAPDb8KkUcx0ounyp">https://open.spotify.com/episode/7CRdMMAPDb8KkUcx0ounyp</a></li>
                <li>Racisme en religie staan centraal in de podcast genaamd “De Ongelooflijke Podcast”, waarin David Boogerd in gesprek gaat over deze relatie: <a target="_blank" rel="noreferrer" href="https://podcasts.apple.com/kw/podcast/racisme-en-religie-met-talitha-muusse-don-ceder-en/id1458702219?i=1000479695996">https://podcasts.apple.com/kw/podcast/racisme-en-religie-met-talitha-muusse-don-ceder-en/id1458702219?i=1000479695996</a></li>
                <li>Taal en racisme <a target="_blank" rel="noreferrer" href="https://soundcloud.com/de-correspondent/lex-bohlmeijer-in-gesprek-met-simone-zeefuik">https://soundcloud.com/de-correspondent/lex-bohlmeijer-in-gesprek-met-simone-zeefuik</a></li>
                <li>De plantage van onze voorouders <a target="_blank" rel="noreferrer" href="https://www.vpro.nl/speel~WO_VPRO_16109898~trailer-de-plantage-van-onze-voorouders~.html">https://www.vpro.nl/speel~WO_VPRO_16109898~trailer-de-plantage-van-onze-voorouders~.html</a></li>
                <li>Fufu en dadels  <a target="_blank" rel="noreferrer" href="https://podcastluisteren.nl/pod/Fufu-and-Dadels#/">https://podcastluisteren.nl/pod/Fufu-and-Dadels#/</a></li>
                <li>Rachida Lamrabet <a target="_blank" rel="noreferrer" href="https://demos.be/blog/podcast-rachida-lamrabet-over-machtsmisbruik-genderverschillen-en-racisme">https://demos.be/blog/podcast-rachida-lamrabet-over-machtsmisbruik-genderverschillen-en-racisme</a></li>
              </ul>
              <p>Voor meer Engelstalige podcast raadpleeg deze lijst: <a target="_blank" rel="noreferrer" href="https://projects.iq.harvard.edu/antiracismresources/allies/podcasts">https://projects.iq.harvard.edu/antiracismresources/allies/podcasts</a></p>
            </div>
          </div>
        </>
      }

      <div className="introSection sections section-3">
        <div className="title">{further_reading_translations.page_title[language]}</div>
        <div className="subtitle">
          {further_reading_translations.page_subtitles[0][language]}
        </div>
        <div className="introBlurb">
          <ul>
            <li>Autor, D. H. (2003). The Economics of Discrimination — Theory. MIT. Charles & Fuentes-Rohwer, L. G. (2021). Pathological Racism, Chronic Racism & Targeted Universalism. Maurer Faculty. <a target="_blank" rel="noreferrer" href="https://www.repository.law.indiana.edu/cgi/viewcontent.cgi?article=3997&context=facpub">https://www.repository.law.indiana.edu/cgi/viewcontent.cgi?article=3997&context=facpub</a></li>
            <li>Davis, T. M., Anderson, M. L., & Collins, P. H. (2001). Race, Class and Gender: An Anthology. Teaching Sociology, 29(3), 374. <a target="_blank" rel="noreferrer" href="https://doi.org/10.2307/1319199">https://doi.org/10.2307/1319199</a></li>
            <li>Doyle, O. (2007). Direct Discrimination, Indirect Discrimination and Autonomy. Oxford Journal of Legal Studies, 27(3), 537–553. <a target="_blank" rel="noreferrer" href="https://doi.org/10.1093/ojls/gqm008">https://doi.org/10.1093/ojls/gqm008</a></li>
            <li>Fairchild, H. H. (1991). Scientific Racism: The Cloak of Objectivity. Journal of Social Issues, 47(3), 101–115. <a target="_blank" rel="noreferrer" href="https://doi.org/10.1111/j.1540-4560.1991.tb01825.x">https://doi.org/10.1111/j.1540-4560.1991.tb01825.x</a></li>
            <li>Felten, Asante, Donker, Andriessen & Noor, H. A. R. I. S. (2021). Wat werkt in de aanpak van institutioneel racisme? KIS. <a target="_blank" rel="noreferrer" href="https://www.kis.nl/sites/default/files/wat_werkt_in_de_aanpak_van_institutioneel_racisme-def.pdf">https://www.kis.nl/sites/default/files/wat_werkt_in_de_aanpak_van_institutioneel_racisme-def.pdf</a></li>
            <li>Felten, Taouanza, Broekroelofs, Vijlbrief & Cankor, H. I. R. A. E. (2020). Wat werkt bij het verminderen van discriminatie? <a target="_blank" rel="noreferrer" href="https://www.kis.nl/sites/default/files/wat-werkt-bij-het-verminderen-van-discriminatie-def_0.pdf">https://www.kis.nl/sites/default/files/wat-werkt-bij-het-verminderen-van-discriminatie-def_0.pdf</a></li>
            <li>Gemeente Rotterdam. (2021). Rotterdam tegen Racisme 2020–2022. <a target="_blank" rel="noreferrer" href="https://www.rotterdam.nl/wonen-leven/tegen-racisme-en-discriminatie/Rotterdam-tegen-Racisme-2020-2022.pdf">https://www.rotterdam.nl/wonen-leven/tegen-racisme-en-discriminatie/Rotterdam-tegen-Racisme-2020-2022.pdf</a></li>
            <li>Havinga, T. (2002). The effects and limits of anti-discrimination law in The Netherlands. International Journal of the Sociology of Law, 30(1), 75–90. <a target="_blank" rel="noreferrer" href="https://doi.org/10.1016/s0194-6595(02)00022-9">https://doi.org/10.1016/s0194-6595(02)00022-9</a></li>
            <li>Holroyd, J. (2017). The social psychology of discrimination. White Rose Research Online. <a target="_blank" rel="noreferrer" href="https://eprints.whiterose.ac.uk/130466/3/36_Chapter32.Holroyd.pdf">https://eprints.whiterose.ac.uk/130466/3/36_Chapter32.Holroyd.pdf</a></li>
            <li>Hooks, B. (1996). Killing rage: ending racism. Choice Reviews Online, 33(07), 33–4229. <a target="_blank" rel="noreferrer" href="https://doi.org/10.5860/choice.33-4229">https://doi.org/10.5860/choice.33-4229</a></li>
            <li>IDEM. (2019, juni). Stadsbeeld discriminatie in Rotterdam. <a target="_blank" rel="noreferrer" href="https://idemrotterdam.nl/wp-content/uploads/2019/07/Stadsbeeld-discriminatie..juni2019.pdf">https://idemrotterdam.nl/wp-content/uploads/2019/07/Stadsbeeld-discriminatie..juni2019.pdf</a></li>
            <li>Lang, K., & Spitzer, A. K. L. (2020). Race Discrimination: An Economic Perspective. Journal of Economic Perspectives, 34(2), 68–89. <a target="_blank" rel="noreferrer" href="https://doi.org/10.1257/jep.34.2.68">https://doi.org/10.1257/jep.34.2.68</a></li>
            <li>Lentin, A. (2004). Racism And Anti-Racism In Europe. Amsterdam University Press.</li>
            <li>Ministerie van Binnenlandse Zaken en Koninkrijksrelaties. (2018, april). Handreiking Antidiscriminatiebeleid. <a target="_blank" rel="noreferrer" href="https://vng.nl/sites/default/files/handreiking_antidiscriminatiebeleid.pdf">https://vng.nl/sites/default/files/handreiking_antidiscriminatiebeleid.pdf</a></li>
            <li>Reynato, S. (2017). Equal Dignity & Respect: The Substance of Equal Protection and Social Justice. Hein & Company.</li>
            <li>Rothstein, D. S., & Hoffman, E. (1993). Essays on the Economics of Discrimination. Industrial and Labor Relations Review, 46(3), 584. <a target="_blank" rel="noreferrer" href="https://doi.org/10.2307/2524560">https://doi.org/10.2307/2524560</a></li>
            <li>Virdee, S. (2014). Racism, Class and the Racialized Outsider (1ste editie). Bloomsbury Academic. Wat werkt in de aanpak van institutioneel racisme. (2021, 14 april). Kennisplatform Inclusief Samenleven. Geraadpleegd op 2 februari 2022, van <a target="_blank" rel="noreferrer" href="https://www.kis.nl/publicatie/wat-werkt-de-aanpak-van-institutioneel-racisme">https://www.kis.nl/publicatie/wat-werkt-de-aanpak-van-institutioneel-racisme</a></li>
            <li>Wekker, G. (2016). White Innocence. Amsterdam University Press.</li>
          </ul>
        </div>
      </div>

    </>
  );
};