import React, { useContext }  from 'react';
import { URLStateContext } from "../URLStateContext";
import { about_translations } from "./translations";

export const About = () => {
  const { language } = useContext(URLStateContext);

  const length_erasmus = about_translations.team_erasmus.length;
  const numbers_erasmus = Array.from({ length: length_erasmus }, (v, k) => k);

  const length_radar = about_translations.team_radar.length;
  const numbers_radar = Array.from({ length: length_radar }, (v, k) => k);

  return (
    <>

      <div className="full-banner slim" style={{ backgroundImage: "url(/banner_2.jpg)" }}>
        <div className="title">{about_translations.banner_title[language]}</div>
      </div>

      <div className="introSection sections section-1 outerT">
        <div className="title">{about_translations.page_title[language]}</div>

        <div className="subtitle">{about_translations.page_subtitles[0][language]}</div>
        <div className="introBlurb">
          <a className="logooo" href="https://www.rotterdam.nl/" target="_blank" rel="noreferrer">
            <img src="logo3.svg" height="50" alt="{about_translations.page_subtitles[0][language]}" />
          </a>
          <p dangerouslySetInnerHTML={{ __html: about_translations.p1[language] }}></p>
        </div>

        <div className="subtitle">{about_translations.page_subtitles[1][language]}</div>
        <div className="introBlurb mb-0">
          <a className="logooo" href="https://www.eur.nl/" target="_blank" rel="noreferrer">
            <img src="logo1.png" height="50" alt="{about_translations.page_subtitles[1][language]}" />
          </a>
          <p>{about_translations.p2[language]}</p>
        </div>

        <div className="subtitle">{about_translations.page_team_erasmus[language]}</div>
        <div className="introBlurb section-2">
          {numbers_erasmus.map(function (d, i) {
            return (
              <p className="text-center" dangerouslySetInnerHTML={{ __html: about_translations.team_erasmus[i][language] }}></p>
            );
          })}
        </div>

        <div className="subtitle">{about_translations.page_subtitles[2][language]}</div>
        <div className="introBlurb mb-0">
          <div className="logooo">
            <img src="logo2.png" height="50" alt="{about_translations.page_subtitles[2][language]}" />
          </div>
          <p>{about_translations.p3[language]}</p>
        </div>

        <div className="subtitle">{about_translations.page_team_radar[language]}</div>
        <div className="introBlurb section-2">
          {numbers_radar.map(function (d, i) {
            return (
              <p className="text-center" dangerouslySetInnerHTML={{ __html: about_translations.team_radar[i][language] }}></p>
            );
          })}
        </div>

      </div>

    </>
  );
};
