import React, { useContext }  from 'react';
import { Link } from 'react-router-dom';
import { URLStateContext } from "../URLStateContext";
import { home_translations } from "./translations";

export const Home = () => {
  const { language } = useContext(URLStateContext);

  return (
    <>

      <div className="full-banner" style={{ backgroundImage: "url(/banner.jpg)" }}>
        <div className="title">{home_translations.banner_title[language]}</div>
        <div className="description" dangerouslySetInnerHTML={{ __html: home_translations.banner_summary[language] }}></div>
        <Link className="button" to={'facts?language=' + language}>
            {home_translations.banner_button[language]}
        </Link>
      </div>

      <div className="introSection sections">
        {/* <div className="title">{home_translations.page_title[language]}</div> */}
        <div className="introBlurb">
          <p dangerouslySetInnerHTML={{ __html: home_translations.p1[language] }}></p>
        </div>
      </div>

      <div className="pdfSection sections">
        <div className="row introBlurb">
            <div className="col-sm-12 col-md-4">
                <a className="image" href="/rotterdam_een_eerste_reactie_op_het_actieplan_samenleven.pdf" target="_blank" rel="noreferrer">
                    <img src="rotterdam_een_eerste_reactie_op_het_actieplan_samenleven.png" className="img-responsive" alt="" />
                </a>
            </div>
            <div className="col-sm-12 col-md-8 text">
                <p className="p1" dangerouslySetInnerHTML={{ __html: home_translations.pdf_title[language] }}></p>
                <p className="p2" dangerouslySetInnerHTML={{ __html: home_translations.pdf_desc[language] }}></p>
                <p className="p3">
                    <a className="button" href="/rotterdam_een_eerste_reactie_op_het_actieplan_samenleven.pdf" target="_blank" rel="noreferrer">
                        {home_translations.pdf_link[language]}
                    </a>
                </p>
            </div>
        </div>
      </div>

    </>
  );
};
