// import { Link } from "react-router-dom";
import React, { useContext } from 'react';
import { URLStateContext } from "../URLStateContext";
import { Navbar } from "./Navbar";
import { NavItem } from "./NavItem";

export const Layout = ({ children }) => {
  const { language } = useContext(URLStateContext);
  return (
    <div className="App">
      <div className="bannerSection">
        <div className="banner">
          <Navbar>
            <NavItem
              language={language}
              withQuery={true}
              url="/"
              pageName="home"
              subpageName=""
            ></NavItem>
            <NavItem
              language={language}
              withQuery={true}
              url="/facts"
              pageName="facts"
              subpageName=""
            ></NavItem>
            <NavItem
              language={language}
              withQuery={true}
              url="/reports"
              pageName="reports"
              subpageName=""
            ></NavItem>
            <NavItem
              language={language}
              withQuery={true}
              url="/learn-more"
              pageName="learnMore"
              subpageName=""
            ></NavItem>
            <NavItem
              language={language}
              withQuery={true}
              url="/about"
              pageName="about"
              subpageName=""
            ></NavItem>

            {/* <NavItem
              language={language}
              withQuery={true}
              url="/organizations"
              pageName="organizations"
              subpageName=""
            ></NavItem> */}
            {/* <NavItem
              language={language}
              withQuery={true}
              url="/studentsAgainstRacism"
              pageName="students"
              subpageName=""
            ></NavItem> */}
            {/* <NavItem
              language={language}
              withQuery={true}
              url="/policy"
              pageName="policy"
              subpageName="governmentAndPolicy"
            ></NavItem> */}
            {/* <NavItem
              language={language}
              withQuery={true}
              url="/history"
              pageName="history"
              subpageName="historyOfRotterdam"
            ></NavItem> */}
            {/* <NavItem
              language={language}
              withQuery={true}
              url="/experience"
              pageName="experience"
              subpageName="experienceOfRotterdammers"
            ></NavItem> */}
            {/* <NavItem
              language={language}
              withQuery={true}
              url="/literature"
              pageName="literature"
              subpageName="literatureList"
            ></NavItem> */}
          </Navbar>
        </div>
      </div>
      {children}
      <div className="logos">
          <a href="https://www.eur.nl/" target="_blank" rel="noreferrer"><img src="logo1.png" height="50" alt="Erasmus University Rotterdam" /></a>
          <img src="logo2.png" height="50" alt="Radar" />
          <a href="https://www.rotterdam.nl/" target="_blank" rel="noreferrer"><img src="logo3.svg" height="50" alt="Gemeente Rotterdam" /></a>
      </div>
      <div className="footer">
        <p>&copy; 2022 - Rotterdam Inclusivity Project</p>
      </div>
    </div>
  );
};
