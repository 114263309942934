
  export const traditionalDropdownOptions = [
    { label: 'Discrimination index', value: 'disc_index', category: 'Discrimination' },
    // { label: 'Estimated percentage of discriminated', value: 'est_disc', category: 'Discrimination' },
    { label: 'Percentage Discrimination Experience', value: 'pDiscr', category: 'Discrimination' },
    { label: 'Number of discrimination reports', value: 'abs_disrep', category: 'Discrimination' },
    { label: 'Number of households', value: 'totalhh', category: 'Context' },
    { label: 'Total Residents', value: 'totalpop', category: 'Context' },
    { label: 'Houseowners %', value: 'sh_housow1', category: 'Housing' },
    { label: 'Subsidised rental %', value: 'sh_housow3', category: 'Housing' },
    { label: 'Rental house %', value: 'sh_housow2', category: 'Housing' },
    { label: 'Institutional housing %', value: 'sh_housow8', category: 'Housing' },
    { label: 'Income inequality Index', value: 'gini_inc_st', category: 'Economic status' },
    { label: 'Average income background', value: 'avg_inc', category: 'Economic status' },
    { label: 'Most common income', value: 'median_inc', category: 'Economic status' },
    { label: 'Average of welfare dependency', value: 'avg_welfare', category: 'Economic status' },
    { label: 'Independent from welfare %', value: 'sh_welf1', category: 'Economic status' },
    { label: 'Minor welfare dependency %', value: 'sh_welf2', category: 'Economic status' },
    { label: 'Partial welfare dependency %', value: 'sh_welf3', category: 'Economic status' },
    { label: 'Mainly dependent on welfare %', value: 'sh_welf4', category: 'Economic status' },
    { label: 'Complete welfare dependency %', value: 'sh_welf5', category: 'Economic status' },
    { label: 'Educational inequality index', value: 'simp_educ', category: 'Education' },
    { label: 'Basis practical education %', value: 'sh_educ1', category: 'Education' },
    { label: 'Middle level education %', value: 'sh_educ3', category: 'Education' },
    { label: 'Middle high education %', value: 'sh_educ4', category: 'Education' },
    { label: 'High education %', value: 'sh_educ5', category: 'Education' },
    { label: 'Very high education %', value: 'sh_educ6', category: 'Education' },
    { label: 'Diversity of migration motive', value: 'simp_motive', category: 'Migration diversity' },
    { label: 'Diversity index country of birth', value: 'simp_conbirth', category: 'Migration diversity' },
    { label: 'Recent migrants %', value: 'sh_recent', category: 'Migration diversity' },
    { label: 'Family migrants %', value: 'sh_stat1', category: 'Migration diversity' },
    { label: 'Work migrants %', value: 'sh_stat2', category: 'Migration diversity' },
    { label: 'Study migrants %', value: 'sh_stat3', category: 'Migration diversity' },
    { label: 'Asylum migrants %', value: 'sh_stat4', category: 'Migration diversity' },
    { label: 'Other migrants %', value: 'sh_stat5', category: 'Migration diversity' },
    { label: 'Number of various countries of births', value: 'N_conbirth', category: 'Migration diversity' },
    { label: 'Share of foreign-born %', value: 'forborn_sh', category: 'Migration diversity' },
    { label: 'Number of Dutch-born', value: 'NLbornbur', category: 'Migration diversity' },
  ];
  // .sort(function(a, b){
  //   if(a.category < b.category) { return -1; }
  //   if(a.category > b.category) { return 1; }
  //   return 0;
  // })

  export const superdiversityDropdownOptions = [
    { label: 'Discrimination index', value: 'cat_disc_index', category: 'Discrimination' },
    { label: 'Percentage Discrimination Experience', value: 'pDiscr_norm', category: 'Discrimination' },
    { label: 'Number of discrimination reports', value: 'cat_abs_disrep', category: 'Discrimination' },
    { label: 'Income inequality Index', value: 'gini_inc_st_norm', category: 'Economic status' },
    { label: 'Complete welfare dependency', value: 'cat_sh_welf5', category: 'Economic status' },
    { label: 'Mainly dependent on welfare', value: 'cat_sh_welf4', category: 'Economic status' },
    { label: 'Partial welfare dependency', value: 'cat_sh_welf3', category: 'Economic status' },
    { label: 'Independent from welfare', value: 'cat_sh_welf1', category: 'Economic status' },
    { label: 'Average of welfare dependency', value: 'cat_avg_welfare', category: 'Economic status' },
    { label: 'Minor welfare dependency', value: 'cat_sh_welf2', category: 'Economic status' },
    { label: 'Average income', value: 'avg_inc_norm', category: 'Housing' },
    { label: 'Houseowners', value: 'sh_housow1_norm', category: 'Housing' },
    { label: 'Subsidised rental', value: 'sh_housow3_norm', category: 'Education' },
    { label: 'Educational inequality index', value: 'simp_educ_norm', category: 'Education' },
    { label: 'Very high education', value: 'cat_sh_educ6', category: 'Education' },
    { label: 'Basis practical education', value: 'cat_sh_educ1', category: 'Education' },
    { label: 'Diversity of migration motive', value: 'simp_motive_norm', category: 'Migration diversity' },
    { label: 'Diversity index country of birth', value: 'simp_conbirth_norm', category: 'Migration diversity' },

    // { label: 'Houseowners.', value: 'cat_sh_housow1', category: 'Housing' },
    // { label: 'Estimated percentage of discriminated', value: 'cat_est_disc', category: 'Discrimination' },
  ];

  export const yearDropdownOptions = ['2013','2015','2017','2019','2021'];

  export const treemapDropdownOptions = ['2018','2019','2020'];

  export const spiderPlotsDropdownOptions = [
    { value: 3, label: 'Afrikaanderwijk' },
    { value: 4, label: 'Agniesebuurt' },
    { value: 5, label: 'Bergpolder' },
    { value: 6, label: 'Beverwaard' },
    { value: 7, label: 'Blijdorp' },
    { value: 8, label: 'Bloemhof' },
    { value: 9, label: 'Bospolder' },
    { value: 10, label: 'Carnisse' },
    { value: 11, label: 'Charlois Zuidrand' },
    { value: 12, label: 'Cool' },
    { value: 13, label: 'Cs Kwartier' },
    { value: 14, label: 'De Esch' },
    { value: 15, label: 'Delfshaven' },
    { value: 16, label: 'Dijkzigt' },
    { value: 17, label: 'Dorp' },
    { value: 18, label: 'Feijenoord' },
    { value: 19, label: 'Groot Ijsselmonde' },
    { value: 20, label: 'Heijplaat' },
    { value: 21, label: 'Het Lage Land' },
    { value: 22, label: 'Hillegersberg Noor' },
    { value: 23, label: 'Hillegersberg Zuid' },
    { value: 24, label: 'Hillesluis' },
    { value: 25, label: 'Hoogvliet Noord' },
    { value: 26, label: 'Hoogvliet Zuid' },
    { value: 27, label: 'Katendrecht' },
    { value: 28, label: 'Kleinpolder' },
    { value: 29, label: 'Kop van Zuid' },
    { value: 30, label: 'Kop van Zuid - Entrepo' },
    { value: 31, label: 'Kralingen Oost' },
    { value: 32, label: 'Kralingen West' },
    { value: 33, label: 'Kralingseveer' },
    { value: 34, label: 'Liskwartier' },
    { value: 35, label: 'Lombardijen' },
    { value: 36, label: 'Middelland' },
    { value: 37, label: 'Molenlaankwartier' },
    { value: 38, label: 'Nesselande' },
    { value: 39, label: 'Nieuw Crooswijk' },
    { value: 40, label: 'Nieuwe Werk' },
    { value: 41, label: 'Nieuwe Westen' },
    { value: 42, label: 'Noordereiland' },
    { value: 43, label: 'Ommoord' },
    { value: 44, label: 'Oosterflank' },
    { value: 45, label: 'Oud Charlois' },
    { value: 46, label: 'Oud Crooswijk' },
    { value: 47, label: 'Oud IJsselmonde' },
    { value: 48, label: 'Oud Mathenesse' },
    { value: 49, label: 'Oude Noorden' },
    { value: 50, label: 'Oude Westen' },
    { value: 51, label: 'Overschie' },
    { value: 52, label: 'Pendrecht' },
    { value: 53, label: 'Pernis' },
    { value: 54, label: 'Prinsenland' },
    { value: 55, label: 'Provenierswijk' },
    { value: 56, label: 'Rozenburg' },
    { value: 57, label: 'Rubroek' },
    { value: 58, label: 's-Gravenland' },
    { value: 59, label: 'Schiebroek' },
    { value: 60, label: 'Schiemond' },
    { value: 61, label: 'Spangen' },
    { value: 62, label: 'Stadsdriehoek' },
    { value: 63, label: 'Strand en Duin' },
    { value: 64, label: 'Struisenburg' },
    { value: 65, label: 'Tarwewijk' },
    { value: 66, label: 'Terbregge' },
    { value: 67, label: 'Tussendijken' },
    { value: 68, label: 'Vreewijk' },
    { value: 69, label: 'Wielewaal' },
    { value: 70, label: 'Witte Dorp' },
    { value: 71, label: 'Zestienhoven' },
    { value: 72, label: 'Zevenkamp' },
    { value: 73, label: 'Zuiderpark' },
    { value: 74, label: 'Zuidplein' },
    { value: 75, label: 'ZuidwijkB73' }
  ];