export const about_translations = {

    banner_title: {
      english: "About",
      dutch: "Wie zijn wij?"
    },

    page_title: {
      english: "Organizations",
      dutch: "Organisaties"
    },

    page_subtitles: [
      {
        english: "The municipality of Rotterdam",
        dutch: "De Gemeente Rotterdam"
      },
      {
        english: "Erasmus University Rotterdam",
        dutch: "Erasmus Universiteit Rotterdam"
      },
      {
        english: "RADAR",
        dutch: "RADAR"
      }
    ],

    p1: {
      english: "The policy document “Rotterdam Against Racism” sets out the municipality of Rotterdam’s vision for a more equal Rotterdam community. This plan is partly the result of the Black Lives Matter protests of summer 2020, and an addition to the earlier action programme “Relax. This is Rotterdam”. Rotterdam consists of a superdiverse society, where groups are treated unequally based on different personal characteristics. To deal with this, the municipality is working towards sustainable changes. This includes a scientific search for a new discrimination index, in which trends of discrimination can be better monitored in the longer term.<br />More information: <a target=\"_blank\" href=\"https://www.rotterdam.nl/wonen-leven/samen-leven/\">https://www.rotterdam.nl/wonen-leven/samen-leven/</a>",
      dutch: "In het beleidsdocument 'Rotterdam tegen Racisme' laat de gemeente Rotterdam zien meer in te willen zetten op een gelijkwaardige Rotterdamse gemeenschap. Dit beleidsplan is mede het gevolg van Black Lives Matter protesten in de zomer van 2020 en aanvulling op het eerder opgestelde actieprogramma “Relax. Dit is Rotterdam.’’. Rotterdam kent een superdiverse samenleving met structurele discriminatieproblemen waar groepen ongelijk worden behandeld op grond van verschillende persoonlijke kenmerken. Om dit aan te pakken wil de gemeente Rotterdam tot duurzame veranderingen komen. Deze aanpak behelst onder meer een zoektocht binnen de wetenschap naar een nieuwe discriminatie index, waarin discriminatieontwikkelingen op langere termijn beter kunnen worden gevolgd.<br />Meer informatie: <a target=\"_blank\" href=\"https://www.rotterdam.nl/wonen-leven/samen-leven/\">https://www.rotterdam.nl/wonen-leven/samen-leven/</a>"
    },

    p2: {
      english: "Erasmus University Rotterdam is an international research university located in the dynamic and diverse city of Rotterdam. EUR is not only characterised by its scientific position in society, but also by providing added value to social issues. Just like the municipality, EUR has the ambition of realising an inclusive society. This is why the research team Governance of Migration and Diversity at EUR’s Department of Public Administration and Sociology collaborates with the Erasmus Initiative Vital Cities and Citizens towards a scientific anti-discrimination policy at the municipality. To this end, EUR researchers are developing an accessible inclusivity dashboard that systematically collects and displays data on various aspects of inclusion and discrimination at the neighbourhood level. The inclusivity index will contribute to evidence-based policymaking in these fields by systematically providing information and promoting policy adjustments.",
      dutch: "De Erasmus Universiteit Rotterdam is een internationale onderzoeksuniversiteit, gelegen in de dynamische en diverse stad Rotterdam. De Erasmus Universiteit Rotterdam (EUR) kenmerkt zich niet alleen door haar wetenschappelijk positie in de maatschappij, maar ook vanwege haar toevoegde waarde die zij levert aan sociaal maatschappelijke vraagstukken. Evenals de gemeente Rotterdam heeft de EUR de ambitie om een inclusieve samenleving te verwezenlijken. Daarom tracht de het onderzoeksteam van de EUR (afdeling Bestuurskunde en Sociologie van de EUR - onderzoeksgroep Governance of Migration and Diversity in samenwerking met Erasmus Initiative Vital Cities and Citizens) het antidiscriminatiebeleid van de gemeente Rotterdam te verwetenschappelijken. Hiervoor ontwikkelen de onderzoekers van de EUR het inclusiviteitsdashboard dat op systematische en breed toegankelijke wijze data verzamelt en weergeeft op wijkniveau over diverse aspecten van inclusiviteit en discriminatie. De inclusiviteitsindex zal bijdragen aan ‘evidence based policymaking’ op het terrein van inclusiviteit en discriminatie door systematisch kennis en informatie te verschaffen en beleidsaanpassingen te bevorderen. Onderzoeksteam: Peter Scholten, Angelique van Dam, Petra de Jong, Asya Pisarevskaya, Maria Schiller, Raychell Enny (intern)"
    },

    p3: {
      english: "RADAR promotes equal treatment and strives for a society without exclusion that offers everyone equal opportunities. It is committed to these goals in various ways: advising and supporting those reporting cases and feelings of discrimination; registering and reporting on discrimination incidents;  research equal treatment; and advising on policy. In collaboration with Erasmus University Rotterdam and the municipality of Rotterdam, RADAR combats discrimination by sharing knowledge with these parties.",
      dutch: "RADAR bevordert gelijke behandeling en streeft naar een samenleving zonder uitsluiting die iedereen gelijke kansen biedt. Zij zet zich op verschillende manieren in: adviseren en ondersteunen van melders bij gevallen en gevoelens van discriminatie; registeren van en rapporteren over discriminatie-incidenten en onderzoek naar gelijke behandeling en adviseren over beleid. In samenwerking met De Erasmus Universiteit Rotterdam en de gemeente Rotterdam bestrijdt RADAR discriminatie door kennis met deze partijen te delen."
    },

    page_team_erasmus: {
      english: "Team",
      dutch: "Team"
    },

    team_erasmus: [
      {
        english: '<a href="https://migrationresearch.com/experts/asya-pisarevskaya/496" target="_blank" rel="noreferrer">Dr. Asya Pisarevskaya</a>',
        dutch: '<a href="https://migrationresearch.com/experts/asya-pisarevskaya/496" target="_blank" rel="noreferrer">Dr. Asya Pisarevskaya</a>'
      },
      {
        english: '<a href="https://migrationresearch.com/experts/peter-scholten/473" target="_blank" rel="noreferrer">Prof. Peter Scholten</a>',
        dutch: '<a href="https://migrationresearch.com/experts/peter-scholten/473" target="_blank" rel="noreferrer">Prof. Peter Scholten</a>'
      },
      {
        english: "Angelique van Dam",
        dutch: "Angelique van Dam"
      },
      {
        english: '<a href="https://migrationresearch.com/experts/maria-schiller/1241" target="_blank" rel="noreferrer">Dr. Maria Schiller</a>',
        dutch: '<a href="https://migrationresearch.com/experts/maria-schiller/1241" target="_blank" rel="noreferrer">Dr. Maria Schiller</a>'
      },
      {
        english: "Maurice Korf",
        dutch: "Maurice Korf"
      },
      {
        english: "Dr. Petra de Jong",
        dutch: "Dr. Petra de Jong"
      },
      {
        english: "Raychell Enny",
        dutch: "Raychell Enny"
      }
    ],

    page_team_radar: {
      english: "Team",
      dutch: "Team"
    },

    team_radar: [
      {
        english: "Tikho Ong",
        dutch: "Tikho Ong"
      },
      {
        english: "Teuntje Vosters",
        dutch: "Teuntje Vosters"
      },
      {
        english: "Bauke Fiere",
        dutch: "Bauke Fiere"
      },
      {
        english: "Eddie Niewenhuizen",
        dutch: "Eddie Niewenhuizen"
      }
    ],

  };