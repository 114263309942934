
import { Layout } from '../Shared/Layout';
import { LearnMore } from '../LearnMore';
import React from 'react';

export const LearnMorePage = () => {

  return (
    <Layout>
      <LearnMore/>
    </Layout>
  );
};
