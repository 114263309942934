import React, { useContext } from 'react';
import { URLStateContext } from "../URLStateContext";
import { reports_translations } from "./translations";
import { Profile } from "./Profile";

export const Reports = () => {
  const { language } = useContext(URLStateContext);
  const length = reports_translations.policy_briefs.length;
  const numbers = Array.from({ length: length }, (v, k) => k);

  return (
    <>
      <div className="full-banner slim" style={{ backgroundImage: "url(/banner_4_1.jpg)" }}>
        <div className="title">{reports_translations.banner_title[language]}</div>
      </div>
      <div className="introSection sections section-1">
        <div className="subtitle">
          {reports_translations.page_subtitles_1[0][language]}
        </div>
        <div className="introBlurb">
          <p className="text-center" dangerouslySetInnerHTML={{ __html: reports_translations.page_comming_soon[0][language] }}></p>
          <p className="text-center" dangerouslySetInnerHTML={{ __html: reports_translations.page_comming_soon[1][language] }}></p>
        </div>
        <div className="subtitle">
          {reports_translations.page_subtitles_2[0][language]}
        </div>
        <div className="introBlurb">
          {numbers.map(function (d, i) {
            return (
              <p>
                <a href={reports_translations.links[i].link}>
                  {reports_translations.policy_briefs[i][language]}
                </a>
              </p>
            );
          })}
        </div>
        <div className="subtitle">
          {reports_translations.page_subtitles_3[0][language]}
        </div>
        <div className="introBlurb">
          <div className="">
            <div className="introBlurb">
              <p>
                {reports_translations.page_summary_3[0][language]}
              </p>
            </div>
            <div className="profileContainer">
              <Profile></Profile>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
