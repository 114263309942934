
export const reports_translations = {

    banner_title: {
      english: "Reports",
      dutch: "Rapportages",
    },

    page_title: {
      english: "Government and Policy",
      dutch: "Overheid en beleid",
    },

    page_subtitles_1: [
      {
        english: "Research reports",
        dutch: "Onderzoeksrapportages",
      },
    ],

    page_subtitles_2: [
      {
        english: "Policy briefs",
        dutch: "Beleidsnotas",
      },
    ],

    page_subtitles_3: [
      {
        english: "Ongoing in-depth studies",
        dutch: "Lopende onderzoeksprojecten",
      },
    ],

    page_comming_soon: [
      {
        english: '<strong>Report:</strong> Naar een verwetenschappelijking van de Rotterdamse benadering van migratie en inclusiviteit: <a target="_blank" href="/rapportage-jaar.pdf">Rapportage jaar 1</a> (Dutch version)',
        dutch: '<strong>Report:</strong> Naar een verwetenschappelijking van de Rotterdamse benadering van migratie en inclusiviteit: <a target="_blank" href="/rapportage-jaar.pdf">Rapportage jaar 1</a> (Dutch version)',
      },
      {
        english: '<strong>Authors:</strong> Peter Scholten, Asya Pisarevskaya, Maurice Korf, Angelique van Dam, Maria Schiller, Petra de Jong - <a target="_blank" href="/rapportage-jaar.pdf">Download</a> (Dutch version)',
        dutch: '<strong>Authors:</strong> Peter Scholten, Asya Pisarevskaya, Maurice Korf, Angelique van Dam, Maria Schiller, Petra de Jong - <a target="_blank" href="/rapportage-jaar.pdf">Download</a> (Dutch version)',
      },
    ],

    page_summary_3: [
      {
        english: "Every year, master students from the Public Administration track of the master programme Governance of Migration and Diversity at Erasmus University Rotterdam take a closer look at different policies related to inclusion and diversity. Descriptions of the projects that students are currently working on can be viewed below:",
        dutch: "Elk jaar nemen verscheidene Master studenten van bestuurs kunde track van master programma Governance of Migration and Diveristy bij het Erasmus Universiteit Rotterdam verschillend beleid onder de loep, gerelateerd aan inclusitiviteit en diversiteit. Hieronder vind je beschrijvinginen van de projecten waar de studenten op dit moment aan de slag zijn.",
      },
    ],

    policy_briefs: [
      {
        english: "Chantre, M. (2021). Het pad naar antiracisme; neemt Rotterdam de vertrouwde route. Mastertheses Faculteit ESSB, Erasmus Universiteit Rotterdam.",
        dutch:"Chantre, M. (2021). Het pad naar antiracisme; neemt Rotterdam de vertrouwde route. Mastertheses Faculteit ESSB, Erasmus Universiteit Rotterdam.",
      },
      {
        english: "Blankesteijn, I. (2021). De olifant in de behandelkamer: Hoe de Rotterdamse zorgverlener (niet) denkt over en omgaat met de gezondheidsgevolgen van racisme. Mastertheses, Faculteit ESSB, Erasmus Universiteit Rotterdam.",
        dutch: "Blankesteijn, I. (2021). De olifant in de behandelkamer: Hoe de Rotterdamse zorgverlener (niet) denkt over en omgaat met de gezondheidsgevolgen van racisme. Mastertheses, Faculteit ESSB, Erasmus Universiteit Rotterdam.",
      },
      {
        english: "Kan, K. (2021). Een inclusieve samenleving begint bij onszelf. Een aanbeveling voor cluster handhaving van de gemeente Rotterdam. Mastertheses, Faculteit ESSB, Erasmus Universiteit Rotterdam.",
        dutch: "Kan, K. (2021). Een inclusieve samenleving begint bij onszelf. Een aanbeveling voor cluster handhaving van de gemeente Rotterdam. Mastertheses, Faculteit ESSB, Erasmus Universiteit Rotterdam.",
      },
      {
        english: "Hilhorst, I. (2021). You'll never walk alone. Hoe wijkcooperaties en wijkbedrijven ons dichter bij sociale inclusie kunnen brengen. Mastertheses, Faculteit ESSB, Erasmus Universiteit Rotterdam.",
        dutch: "Hilhorst, I. (2021). You'll never walk alone. Hoe wijkcooperaties en wijkbedrijven ons dichter bij sociale inclusie kunnen brengen. Mastertheses, Faculteit ESSB, Erasmus Universiteit Rotterdam.",
      },
      {
        english: "Overzicht beleidspraktijken t.a.v. van discriminatie en inclusiviteit in Rotterdam 2022.",
        dutch: "Overzicht beleidspraktijken t.a.v. van discriminatie en inclusiviteit in Rotterdam 2022.",
      },
    ],

    links: [
      {
        link: "policy_briefs/Antiracisme policy brief Marlyn.pdf",
      },
      {
        link: "policy_briefs/De olifant in de behandelkamer_policy brief Ingrid.pdf",
      },
      {
        link: "policy_briefs/Kristel - policy brief.pdf",
      },
      {
        link: "policy_briefs/You'll never walk alone- hoe wijkcoöperaties en wijkbedrijven ons dichter bij sociale inclusie kunnen brengen Iana policybrief.pdf",
      },
      {
        link: "policy_briefs/Beleidspraktijken.Inclusiviteit.Discriminatie.Rotterdam.EersteGepubliceerdeVersie.docx",
      },
    ],
};

export const student_translations = {
   students:{
     english:[
      {
          name:"Bo van Ek",
          description:"Borne out of an interest in making a difference together and the shift from speaking about people to speaking with people in antidiscrimination projects, this research focuses on interactive governance. This form of policymaking focuses on involving various actors, such as social movements or individual citizens, in the development of policy. Diversity and inclusion are (implicitly) the basis of anti-discrimination and hence anti-discrimination policy. Practicing interactive governance can achieve diversity and inclusion. This research therefore focuses on participants in social movements and their experiences of involvement in the anti-discrimination policymaking process, and how this influences their participation in these social movements."
      },
      {
          name:"Marcela Renderos",
          description:"In line with the main insights from the policy “Rotterdam against Racism”, this research focuses on education. The aim of this research is to understand how discrimination takes place in public institutions, focusing on the public primary school. Discrimination can occur unintentionally in day-to-day conduct and decision-making without any awareness of it. This research looks at how the implementation of this policy addresses discrimination. It also examines whether discrimination hinders access to the different levels of education for vulnerable groups in society, such as refugees who are in the process of integrating."
      },
      {
          name:"Kayleigh van Teeffelen",
          description:"In 2014, the Municipality of Rotterdam introduced the 'Possible Neighbourhoods' program, intended to make nine of Rotterdam's most promising neighbourhoods more attractive to higher educated households with children. My research will analyse this policy programme and examine to what extent this it fits within the vision of Rotterdam as an inclusive city. I will especially focus on the relationship between gentrification policies, segregation, and discrimination. This research will be supported by interviews with policy officers and residents of Oude Noorden and Nieuw-Crooswijk. This will provide insight into the underlying assumptions of the policy programme and its effects."
      },
      {
          name:"Francesca Calenta",
          description:"My project looks at the dilemmas frontline workers in Rotterdam face in their work with refugees in the integration process. The new Civic Integration Act gives greater responsibility to frontline workers, so it is important to understand how they view the new policy—whether they see it as an improvement in areas where it was previously insufficient, and how they see their work changing. Given the important role of frontline workers in the implementation of this policy, this research aims to investigate whether these workers have received the necessary training to respond to the personal experiences of refugees and whether they have unconscious biases that may hinder the refugees’ integration."
      },
      {
          name:"Jeremy de Leur",
          description:"Inspired by the events surrounding George Floyd in the US, but also Mitch Hernández in the Netherlands, and the discomfort about the figure 'black Piet', thousands of people crossed the Erasmus Bridge on June 3, 2020 with a clear message against (institutional) racism: Black Lives Matter. This research takes a critical look at these and other symbols through a Multimodal Critical Discourse Analysis, with a special focus on the context in which these symbols are used and the power and force they have in communicating a story. In the aftermath of the protest, the policy 'Rotterdam Against Racism' was established. The role the symbols played in shaping this policy, and in the story the protest sought to communicate, is central to this research."
      },
      {
          name:"Sophie Weenink",
          description:"Mees van Diepen: Following secret investigations in Rotterdam mosques, carried out on behalf of the municipality of Rotterdam, this study takes a look at the Islamic umbrella organization SPIOR’s trust towards and willingness to cooperate with the municipality. With this, the research falls within a broader securitization discourse that deals with the adverse consequences of reducing Islam to a security issue. The literature suggests that this securitization in general contributes to further discrimination and marginalization of Muslims and creates reluctance and mistrust of government agencies within the Islamic community. However, it is still unclear how this effect works through Islamic umbrella organizations such as SPIOR. This is important because, in their intermediary role, these organizations form the basis for contact between the Islamic community and the government and can therefore provide an anchor for cooperation in a deteriorating relationship between the two."
      }
     ],
     dutch: [
      {
          name:"Bo van Ek",
          description:"Vanuit interesse omtrent de benadrukking van verschil maak je samen en de verschuiving van spreken over mensen naar spreken met mensen binnen anti-discriminatie projecten, focust dit onderzoek zich op interactive governance. Deze vorm van beleidsvoering richt zich op het betrekken van verschillende actoren, zoals maatschappelijke bewegingen of individuele burgers, in de ontwikkeling van beleid. Diversiteit en inclusie liggen (impliciet) ten grondslag aan anti-discriminatie en dus anti-discriminatie beleid. Het praktiseren van interactive governance kan diversiteit en inclusie verwezenlijken, daarom focust dit onderzoek zich op deelnemers in maatschappelijke bewegingen en hun ervaringen met betrokkenheid bij het beleidsvorming proces wat betreft anti-discriminatie en hoe dit hun deelname in deze maatschappelijke bewegingen beïnvloedt."
      },
      {
          name:"Marcela Renderos",
          description:"In lijn met de belangrijkste inzichten uit  het beleid “Rotterdam tegen Racisme”, richt dit onderzoek zich op educatie. Het doel van het onderzoek is inzicht te krijgen in hoe discriminatie plaatsvindt in openbare instituties, waar dit onderzoek zich richt op de openbare lagere school. Discriminatie kan onbedoeld plaatsvinden in de dagelijkse manier van handelen en in de besluitvorming zonder dat hiervan enige bewustzijn is. Er wordt gekeken hoe de implementatie van dit beleid discriminatie aanpakt. Ook wordt onderzocht of discriminatie de toegang tot de verschillende onderwijsniveaus belemmerd voor kwetsbare groepen in de samenleving, zoals vluchtelingen die zich in het integratieproces bevinden."
      },
      {
          name:"Kayleigh van Teeffelen",
          description:"In 2014 introduceerde de Gemeente van Rotterdam het ‘Kansrijke Wijken’ programma, bedoeld om negen van Rotterdam’s meest kansrijke wijken aantrekkelijker te maken voor hoger opgeleide huishoudens met kinderen. Mijn onderzoek zal dit beleidsprogramma analyseren en bekijken in hoeverre dit beleidsprogramma past binnen de visie van Rotterdam als inclusieve stad. Er zal hierbij een speciale focus worden gelegd op de relatie tussen gentrificatiebeleid, segregatie en discriminatie. Dit onderzoek zal ondersteund worden door middel van interviews met beleidsmedewerkers én bewoners van Oude Noorden en Nieuw-Crooswijk, om meer inzicht te krijgen op de onderliggende assumpties van het beleidsprogramma en de effecten ervan.  "
      },
      {
          name:"Francesca Calenta",
          description:"Mijn project kijkt naar de dilemma's waar eerstelijnswerkers in Rotterdam mee te maken hebben in hun werk met vluchtelingen in het inburgeringsproces. De nieuwe Wet Inburgering geeft een grotere verantwoordelijkheid aan frontlijnwerkers, het is daarom belangrijk om te begrijpen hoe zij tegen het nieuwe beleid aankijken, of zij het zien als een verbetering op de gebieden waar het voorheen aan schortte en hoe zij hun werk zien veranderen. Gezien de belangrijke rol van eerstelijnswerkers bij de uitvoering van dit beleid, wil dit onderzoek nagaan of deze werknemers de nodige opleiding hebben gekregen om in te spelen op de persoonlijke ervaringen van vluchtelingen en of ze onbewuste vooroordelen hebben die de integratie van vluchtelingen kunnen belemmeren. "
      },
      {
          name:"Jeremy de Leur",
          description:"Geïnspireerd door de gebeurtenissen omtrent George Floyd in de VS, maar ook Mitch Hernández in Nederland, en het ongemak over de figuur ‘zwarte Piet’, trokken op 3 juni 2020, duizenden mensende Erasmusbrug over met een duidelijke anti (institutioneel) racisme boodschap: Black Lives Matter. Dit onderzoek neemt deze, en andere, symbolen onder een kritische loep aan de hand van een‘Multimodal Critical Discourse Analysis’, waarbij er speciale aandacht is voor de context waarin deze symbolen worden gebruikt en de macht en kracht die ze hebben in het communiceren van een verhaal. In de nasleep van het protest is het beleid ‘Rotterdam Tegen Racisme’ tot stand gekomen. De rol die de symbolen hebben gespeeld in de vormingvan dit beleid, en in het verhaal dat het protest heeft willen communiceren, staat centraal in dit onderzoek. "
      },
      {
          name:"Sophie Weenink",
          description:"Mees van Diepen: Naar aanleiding van geheime onderzoeken in Rotterdamse moskeeën, uitgevoerd in opdracht van gemeente Rotterdam, werpt deze studie een blik op vertrouwen en wil tot samenwerking binnen Islamitische koepelorganisatie SPIOR richting de gemeente. Hiermee valt het onderzoek binnen een breder securitisatie discours dat zich bezighoudt met de nadelige gevolgen van het reduceren van Islam tot een veiligheidsvraagstuk. De literatuur suggereert dat deze securitisatie in het algemeen bijdraagt aan verdere discriminatie en marginalisatie van Moslims en zorgt voor terughoudendheid en wantrouwen jegens overheidsinstanties binnen de Islamitische gemeenschap. Echter, het is tot nog toe onduidelijk hoe dit effect doorwerkt binnen Islamitische koepelorganisaties zoals SPIOR. Dit is belangrijk omdat deze organisaties in hun intermediaire rol de basis vormen voor contact tussen de Islamitische gemeenschap en overheid en daarmee een anker kunnen bieden voor samenwerking in een verslechterende verstandhouding tussen die twee. "
      }
     ]
   },
};
