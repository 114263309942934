import React from 'react';
import { Layout } from '../Shared/Layout';
import { DiscriminationIndex } from '../DiscriminationIndex';

export const DiscriminationIndexPage = () => {

  return (
    <Layout>
      <DiscriminationIndex/>
    </Layout>
  );

};
