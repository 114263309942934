export const pages = {
  home: {
    pageName: {
      english: "Home",
      dutch: "Home",
    },
    subpageName: {},
  },
  facts: {
    pageName: {
      english: "Facts & numbers",
      dutch: "Feiten en cijfers",
    },
    subpageName: {},
  },
  reports: {
    pageName: {
      english: "Reports",
      dutch: "Rapportages",
    },
    subpageName: {},
  },
  learnMore: {
    pageName: {
      english: "Learn more",
      dutch: "Zie verder",
    },
    subpageName: {},
  },
  about: {
    pageName: {
      english: "About",
      dutch: "Wie zijn wij?",
    },
    subpageName: {},
  },




  organizations: {
    pageName: {
      english: "Organisations",
      dutch: "Over deze website",
    },
    subpageName: {},
  },
  students: {
    pageName: {
      english: "Researchers",
      dutch: "Onderzoekers",
    },
    subpageName: {},
  },
  policy: {
    pageName: {
      english: "Policy advice",
      dutch: "Beleidsadviezen",
    },
    subpageName: {},
  },
  history: {
    pageName: {
      english: "History & context",
      dutch: "Geschiedenis en context",
    },
    subpageName: {
      english: "History of Rotterdam",
      dutch: "Geschiedenis van Rotterdam",
    },
  },
  experience: {
    pageName: {
      english: "Experience",
      dutch: "Ervaring",
    },
    subpageName: {},
  },
  literature: {
    pageName: {
      english: "Watch, list & read",
      dutch: "Kijk, luister en leestips",
    },
    subpageName: {},
  },
};