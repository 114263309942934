
export const home_translations = {
  banner_title: {
    english: "Rotterdam Inclusivity Project",
    dutch: "Rotterdam Inclusivity Project",
  },
  banner_summary: {
    english: "Explore patterns of discrimination and inequality in Rotterdam.<br />Check out how neighborhoods differ from each other in levels of discrimination, migration diversity, economic wellbeing and education.",
    dutch: "Ontdek patronen van discriminatie en ongelijkheid in Rotterdam en<br />kijk hoe buurten verschillen op basis van discriminatie, migratie diversiteit, economisch welzijn en opleidingsniveau.",
  },
  banner_button: {
    english: "Explore the data",
    dutch: "Bekijk de data",
  },
  page_title: {
    english: "Rotterdam Inclusivity Project",
    dutch: "Rotterdam Inclusivity Project"
  },
  p1: {
    english: "This project is part of the plan <a href='https://rotterdam.notubiz.nl/document/9344928/2'>‘Rotterdam Against Racism’</a> undertaken by the Municipality of Rotterdam. The analysis and the web-site are developed by Erasmus University Rotterdam in collaboration with anti-discrimination agency RADAR. Read more about this project <a href='/about?language=english'>here</a>.",
    dutch: "Deze inzichten en website zijn onderdeel van het plan <a href='https://rotterdam.notubiz.nl/document/9344928/2'>Rotterdam Tegen Racisme</a> wat is ondernomen door gemeente Rotterdam en is gerealiseerd in samenwerking met de Erasmus Universiteit Rotterdam en het anti-discriminatie agentschap RADAR. Voor meer informatie, lees meer <a href='/about'>hier</a>."
  },
  pdf_title: {
    english: "Van “integratie” naar samenleven als institutioneel vraagstuk in Rotterdam",
    dutch: "Van “integratie” naar samenleven als institutioneel vraagstuk in Rotterdam"
  },
  pdf_desc: {
    english: "Een eerste reactie op het Actieplan Samenleven in Eén Stad",
    dutch: "Een eerste reactie op het Actieplan Samenleven in Eén Stad"
  },
  pdf_link: {
    english: "READ MORE",
    dutch: "LEES VERDER"
  },
}
