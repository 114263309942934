import React, { useContext } from "react";
import { URLStateContext } from "../URLStateContext";
import { di_translations } from "./translations";

export const DiscriminationIndex = () => {
  const { language } = useContext(URLStateContext);

  return (
    <>

      <div className="introSection sections section-3 outerT">
        <div className="title">{di_translations.page_title[language]}</div>
        <div className="subtitle">{di_translations.page_subtitle[language]}</div>
        <div className="introBlurb">

          {language === 'english' &&
            <>
              <p>The researchers investigated which combination of factors is most relevant to understand differences in perceptions of discrimination between neighborhoods. The result is a statistical model that best explains differences between neighborhoods, while only including the factors that appear to be most strongly associated with perceived discrimination.</p>
              <p>Based on our data, these appear to be 3 factors:</p>
              <ul>
                <li>the Simpson Diversity Index, which reflects the degree of diversity in the neighborhood;</li>
                <li>the Gini income index, which reflects the degree of income inequality in the neighborhood;</li>
                <li>an interaction term between the above indices.</li>
              </ul>
              <p>The interaction term was added to the model because the relationship between diversity and perceived discrimination appears to vary with the degree of income inequality in a neighborhood. Namely, in neighborhoods with smaller income differences, perceived discrimination is more strongly related to the degree of diversity than in neighborhoods with larger income differences. The model explains more than 75% of the perception of discrimination at neighborhood level.</p>
              <p>Based on the model including these 3 factors, we then calculated a predictive value of perceived discrimination per neighborhood. To this value we added data on discrimination reports from RADAR, weighted according to the size of the population of the neighborhood in question. This calculation resulted in a discrimination index: a ranking of neighborhoods that provides insight into differences in discrimination. To facilitate the interpretation of the findings, we standardized the outcomes on a scale of 0 to 200. The higher the scores, the higher the predicted discrimination in the neighborhood. The index shows the results for the 73 neighborhoods in Rotterdam for which we have complete data.</p>
            </>
          }

          {language === 'dutch' &&
            <>
              <p>De onderzoekers hebben bekeken welke combinatie van factoren het meest relevant is om verschillen in discriminatiebeleving tussen buurten te begrijpen. Het resultaat is een statistisch model dat verschillen tussen buurten zo goed mogelijk verklaart, met daarin alleen de factoren die het sterkst samen blijken te hangen met ervaren discriminatie.</p>
              <p>Op basis van onze data blijken dit 3 factoren te zijn:</p>
              <ul>
                <li>de Simpson-diversiteitsindex, die de mate van diversiteit in de buurt weergeeft;</li>
                <li>de Gini-inkomensindex, die de mate van inkomensongelijkheid in de buurt weergeeft;</li>
                <li>een interactie term tussen bovenstaande indexen.</li>
              </ul>
              <p>De interactie-term is aan het model toegevoegd omdat de relatie tussen diversiteit en ervaren discriminatie blijkt te variëren met de mate van inkomensongelijkheid in een buurt. Namelijk, in buurten met kleinere inkomensverschillen hangt de ervaren discriminatie sterker samen met de mate van diversiteit dan in buurten met grotere inkomensverschillen. Het model verklaart ruim 75% van de discriminatiebeleving op buurtniveau.</p>
              <p>Op basis van het model met deze 3 factoren berekenden we vervolgens een per buurt een voorspellingswaarde van discriminatiebeleving. Aan deze waarde voegden we data toe over discriminatiemeldingen van RADAR, gewogen naar de omvang van de populatie van de betreffende buurt. Deze berekening levert een discriminatie-index op: een ranking van buurten die inzicht geeft in verschillen in discriminatie. Om de interpretatie van de bevindingen te vergemakkelijken standaardiseerden we de uitkomsten naar een schaal van 0 tot 200. Hoe hoger de scores, des te hoger is de voorspelde discriminatie in de buurt. De index toont de uitkomsten voor de 73 buurten in Rotterdam waarvoor we volledige data hebben.</p>
            </>
          }

        </div>
      </div>

    </>
  );
};
