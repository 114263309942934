export const di_translations = {

    page_title: {
      english: "Discrimination index",
      dutch: "Discriminatie-index"
    },

    page_subtitle: {
      english: "Discrimination index",
      dutch: "Discriminatie-index"
    }

  };